import jwtDecode from "jwt-decode";
import ApiLoan from "../service/ApiLoanService";
const apiService = new ApiLoan();

export const markCurrentUser = (users) => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedJwt = jwtDecode(token);
      users.forEach(user => {
        if (user.id === decodedJwt.user_claims.user_id && !user.username.includes("(Tú)")) {
          user.username = user.username + " (Tú)";
        }
      });
    }
    return users;
}

export const getUsers = () => {
  return apiService.getResources({
      url: '/conversations/users',
  }).then(response => {
      let response_data = response.data.objects;
      let all_users_duplicated = [...response_data];
      let all_users = all_users_duplicated.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i);
      return all_users;
  });
};

export const assignConversation = (data, client_id) => {
  const params = {...data}
  return apiService
  .postResource({
      ...params,
      url: '/conversations/' + client_id + '/assign',
  })
}

export const unmarkRecentlyTransfered = (conversation_id) => {
  return apiService
    .postResource({
      url: '/conversations/' + conversation_id + '/toggleTransferred'
    })
}