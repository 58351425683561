import React from 'react';

export const CompanyContext = React.createContext({
    privateInbox: false,
    privateGroup: false,
    socket: null,
    setSocket: () => {},
    chatChannel: null,
    chatbotEnabled: false,
    providerTimezone: null,
    internalChatEnabled: false,
    aiAssistantEnabled: false,
    prefixTimezone: 'ar',
    setProviderTimezone: () => {},
    setPrefixTimezone: () => {},
    setChatChannel: () => {},
    setPrivateInbox: () => {},
    setPrivateGroup: () => {},
    updateChatBotEnabled: (data) => {},
    updateAiAssistantEnabled: () => {},
    updateInternalChatEnabled: () => {},
    isTopBarVisible: true,
    setIsTopBarVisible: () => {}
});
