import React, { Component, Fragment } from 'react';
import {Chart} from "primereact/chart";
import {formatMoney} from '../utils/utils';
import ApiLoan from "../service/ApiLoanService";
var moment = require('moment');

class PaymentsByMonths extends Component {

	state = {
		labels: null,
		data: null,
		loading: true
	}

	componentDidMount(){
        let apiService = new ApiLoan();
        apiService.getResources({
            'url': '/statistics/get_payments_by_months'
        }).then(response => {
            const labels = response.data.map(data => moment().month(data.month-1).format("MMMM"));
            const data = response.data.map(data => data.amount.toFixed(2));
            this.setState({
                labels: labels,
                data: data,
                loading: false
            });
        }).catch(error => {
            this.setState({loading: false});
            console.log('Se produjo un error. Intente nuevamente en unos minutos');
        });
	}

    render(){

        const options = {
            tooltips: {
                callbacks:{
                    label: (tooltipItem, data) => {
                        return formatMoney(tooltipItem.yLabel, 2, ',', '.','$');
                    }
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        callback: (value, index, values) => {
                            return formatMoney(value, 0, ',', '.','$');
                        }
                    }
                }]
            }
        }

    	const data = {
    		labels: this.state.labels,
    		datasets: [{
    			label: 'Pagos por mes.',
    			data: this.state.data,
    			borderColor: '#42A5F5'
    		}]
    	};

        return (
            <Fragment>
	            <div className="col-12 md-8 p-lg-8 md-offset-2 p-lg-offset-2">
                    <Chart type="line" data={data} options={options} />
	        	</div>
            </Fragment>
        );

    }
}

export default PaymentsByMonths;