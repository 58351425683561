import { listToObject } from '../../../utils/utils';

export const formatMessage = (message) => {
  return {
    photo: 'assets/layout/images/profile.png',
    name: message.client.firstname + ' ' + message.client.lastname,
    text: message.emoji,
    seen: message.seen,
    mimetype: message.mime_type,
    read: message.read,
    sent: message.sent,
    created: message.created,
    delivered: message.delivered,
    type: message.type,
    client: {
      id: message.client_id,
      name: message.client.firstname + ' ' + message.client.lastname,
      reallocated: message.client.reallocated,
      counter_unread_messages: message.client.counter_unread_messages,
      pinup: message.client.pinup,
      unread_messages: message.client.unread_messages,
      archived: message.client.archived,
      contact: {
        value: message.type === 'received' ? message.sender : message.recipient,
      },
      conversation: message.client.conversation,
      client_custom_tags: message.client.client_custom_tags,
      contacts: message.client.contacts,
      chatbot_subscription: message.client.chatbot_subscription
    },
    client_id: message.client_id,
    id: message.id,
    message_type_id: message.message_type_id,
    message_type: message.message_type
  }
}

export function createNewMessageFromClient(client) {
  let clientName = client?.name
  if (!clientName) {
    clientName = client.firstname + ' ' + client.lastname;
  }
  return {
    photo: 'assets/layout/images/profile.png',
    name: clientName,
    text: '',
    seen: true,
    client: {
      id: client.id,
      name: clientName,
      client_custom_tags: client.client_custom_tags,
      contacts: client.contacts,
      chatbot_subscription: client.chatbot_subscription,
      conversation: client.conversation
    },
    client_id: client.id,
    id: null
  };
}

export const updateShowedClients = (clientToUpdate, showedClientsRef, searchValue) => {
  let updatedShowedClients = [...showedClientsRef]
  let showedClientsIndexedById = listToObject(updatedShowedClients, 'client_id');
  if (showedClientsIndexedById[`${clientToUpdate.id}`]) {
    updatedShowedClients = updatedShowedClients.map(c => c.client.id === clientToUpdate.id ? { ...c, client: clientToUpdate } : c);
    return updatedShowedClients
  } else if(!searchValue){
    let newMessage = createNewMessageFromClient(clientToUpdate)
    updatedShowedClients = [...updatedShowedClients, newMessage]
    return updatedShowedClients
  }else{
    return updatedShowedClients
  }
}