import React from 'react';

export const MessageNoteContext = React.createContext({
    messageNotes: [],
    selectedMessageNote: null,
    selectedClient: null,
    globalClients: false,
    expirationNotes: 0,
    errorNotes: null,
    loadingNote: false,
    loadingConversation: false,
    foundMessage: false,
    selectedContact: null,
    activeMessageNoteModal: false,
    clientFullName: null,
    loadingNote: false,
    loadingScheduled: false,
    hasMoreNote: true,
    hasMoreScheduled: true,
    selectedTemplate: null,
    selectedDate: null,
    countNote: null,
    getPagesSheduled: (data) => {},
    getPageNote: (data) => {},
    updateSelectedClient: (data) => {},
    updateSelectedMessageNote: (data) => {},
    updateGlobalClients: (data) => {},
    deleteReminder: (data) => {},
    updateMessageNotes: (data) => {},
    updateExpirationNotes: (data) => {},
    seenNoteReminder: (data) => {},
    updateErrorNotes: (data) => {},
    updateLoadingConversation: (data) => {},
    setFoundMessage: (data) => {},
    setActiveMessageNoteModal: (data) => {},
    getResetNote: (data) => {},
    setSelectedTemplate: (data) => {},
    setSeletedDate: (data) => {},
});

