import React, {Component, Fragment} from 'react'

import CheckboxForm from '../../../UI/CheckboxForm';
import ShortUrlView from "../ShortUrl/ShortUrlView";

class ClientAttributes extends Component {
    state = {
        fields: [
            { name:'lastname', traduction:'Apellido', value: this.props.client.lastname},
            { name:'firstname', traduction:'Nombre', value: this.props.client.firstname},
            { name:'document_number',traduction:'Identificador', value: this.props.client.document_number},
            { name:'cuil', traduction:'CUIL', value: this.props.client.cuil},
            { name: 'notification_subscription', traduction: 'Estado de notificaciones', value: this.props.client.notification_subscription}
        ]
    };


    render(){
            const clientAttributesList = this.state.fields.map((field, index) => {
                let fieldValue = (<span className={'value-list'}>{field.value}</span>);
                if(field.name === 'notification_subscription'){
                    fieldValue = (<CheckboxForm url={'/debtors'} resource_id={this.props.clientId} checked={field.value}/>);
                }
                if(field.name === 'document_number' || field.name === 'cuil'){
                    fieldValue = (<CheckboxForm url={'/debtors'} resource_id={this.props.clientId} checked={field.value}/>);
                    fieldValue = (<a href={'https://www.google.com/search?q=%22' + field.value + '%22'} target="_blank" rel="noopener noreferrer">{field.value}</a>)
                } 
                return (<Fragment key={index}>
                            <div className="flex p-jc-between mt-3">
                                <div>
                                    <span>{field.traduction + ':'}</span>
                                </div>
                                <div>
                                    {fieldValue}
                                </div>
                            </div>
                        </Fragment>);
            });




        return (
            <div className="grid">
                <div className="col-12">
                    {clientAttributesList}

                </div>
                <ShortUrlView client_id={this.props.client.client_id}/>
            </div>
        );
    }

}

export default ClientAttributes;