import React, {Fragment, useState, useEffect, useRef, useContext} from 'react';

import ApiLoan from "../../../service/ApiLoanService";

import { MessageContext } from "../../../context/messageContext";

import CustomSpinner from "../../../UI/CustomSpinner";

import ConversationList from '../ConversationList';
import MessageList from '../MessageList';
import { CustomModal, CustomModalHeader, CustomModalBody } from "../../../UI/CustomModal";
import MessageNoteContextProvider from "../../../context/MessageNoteContextProvider";

import './Messenger.css';
import {switchRole} from "../../../shared/roles";
import {CompanyContext} from "../../../context/companyContext";
import {AudioPlayerProvider} from "../../../context/AudioPlayerContext";

export default function Messenger(props) {
  const [conversation, setConversation] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const scrolleableDiv = useRef(null);
  const [buttonsActivated, setButtonsActivated] = useState(false)

  const [loading, setLoading] = useState(true)
  const [closeModal, setCloseModal] = useState(false)
  const [messageContext, setMessageContext] = useState(null);
  const [messageReaction, setMessageReaction] = useState(null);
  const [height, setHeight] = useState(window.innerWidth);

  const [selectedMessageContext, setSelectedMessageContext] = useState(null);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [foundMessageContext, setFoundMessageContext] = useState(false);

  const [forward, setForward] = useState(false);
  const [selectedForwardMessage, setSelectedForwardMessage] = useState([]);
  const [selectedForwardContact, setSelectedForwardContact] = useState(null);
  const [forwardContactModal, setForwardContactModal] = useState(false)

  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedContactsModal, setSelectedContactsModal] = useState(false);

  const isMobile = () => {
    return window.innerWidth <= 750;
  };

  const company_context = useContext(CompanyContext);
  const socket = company_context.socket;

  useEffect(() => {
    if (isMobile() && conversation !== null) {
      window.history.pushState(null, document.title, window.location.href);

      window.history.replaceState(null, document.title, window.location.href);

      window.addEventListener('popstate', function() {
        setConversation(null);
      });
    }
  },[isMobile(), conversation]);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newHeight = window.innerWidth;
      setHeight(newHeight);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);

  }, []);

  useEffect(() => {
    setLoading(false);
    return () => {
      setLoading(true)
    }
  },[]);


  useEffect(() => {
    socket.on('internal-client-removal-' + localStorage.getItem('provider_id'), checkCurrentClient);
    return () => {
      socket.off('internal-client-removal-' + localStorage.getItem('provider_id'), checkCurrentClient);
    }
  }, [conversation]);

  const checkCurrentClient = ( message ) => {
    if (conversation && message.conversation_id === conversation.conversation_id) {
      setConversation(null);
      setShowModal(true);
    }
  }

  const updateCloseModal = (data) => {
    setCloseModal(data)
  }

  if (loading){
    return <CustomSpinner/>
  }

  const messageList = conversation ? (<MessageList onSelectClientHandler={setConversation} client={conversation} buttonsActivated={buttonsActivated}/>) : '';
  const warningModal = (
    <CustomModal show={true}>
      {( onClose ) => {
        return (
          <Fragment>
            <CustomModalHeader title={'Cliente Reasignado'} onClose={() => {
              setShowModal(false);
              onClose();
            }}/>
            <CustomModalBody>
              <div><p>El cliente ha sido reasignado</p></div>
            </CustomModalBody>
          </Fragment>
        );
      }}
    </CustomModal>
  );

  const Logo = (
    (!isMobile()) && (
      <div className="sidebar messenger-message-list-container"
        style={{ width: isMobile() ? height - 30 : 'auto' }}>
        <div className='image-logo messenger-logo-container'>
          <img alt="logo" src={`${process.env.REACT_APP_PAGE}/images/logo.png`} />
          <h4>Bienvenido a tu bandeja de entrada</h4>
          <span>Para comenzar selecciona una conversación</span>
        </div>
      </div>
    )
  );

  return (
    <div className="messenger">
      <MessageContext.Provider
          value={{
            closeModal: closeModal,
            messageContext: messageContext,
            messageReaction: messageReaction,
            selectedMessageContext: selectedMessageContext,
            loadingMessages: loadingMessages,
            foundMessageContext: foundMessageContext,
            forward: forward,
            selectedForwardMessages: selectedForwardMessage,
            selectedForwardContact: selectedForwardContact,
            forwardContactModal: forwardContactModal,
            contacts: contacts,
            selectedContacts: selectedContacts,
            selectedContactsModal: selectedContactsModal,
            updateCloseModal: (e) => updateCloseModal(e),
            updateMessageContext: (e) => setMessageContext(e),
            updateMessageReaction: (e) => setMessageReaction(e),
            updateSelectedMessageContext: (e) => setSelectedMessageContext(e),
            updateLoadingMessages: (e) => setLoadingMessages(e),
            updateFoundMessageContext: (e) => setFoundMessageContext(e),
            updateForward: (e) => setForward(e),
            updateSelectedForwardMessages: (e) => setSelectedForwardMessage(e),
            updateSelectedForwardContact: (e) => setSelectedForwardContact(e),
            updateForwardContactModal: (e) => setForwardContactModal(e),
            updateContacts: (e) => setContacts(e),
            updateSelectedContacts: (e) => setSelectedContacts(e),
            updateSelectedContactsModal: (e) => setSelectedContactsModal(e),

          }}>
          <MessageNoteContextProvider>
            <AudioPlayerProvider >
              {isMobile() && (
                conversation === null && (
                  <div className="sidebar scrollable"
                       ref={scrolleableDiv}
                       style={{width: isMobile() ? height - 10: 'auto'}}>
                      <ConversationList onSelectClientHandler={setConversation}
                                        parentRef={scrolleableDiv}
                                        setButtonsActivated={setButtonsActivated}
                                        />
                  </div>
                )
              )}
              {!isMobile() && (
                <div className="sidebar">
                    <ConversationList onSelectClientHandler={setConversation}
                                    parentRef={scrolleableDiv}
                                    setButtonsActivated={setButtonsActivated}
                                    />
                </div>
              )}
              {(messageList) && (
                <div className="messenger-message-list-container"
                     style={{width: isMobile() ? height - 10 : 'auto'}}>
                    {messageList}
                </div>
              )}
              {!messageList && (
                Logo
              )}
            </AudioPlayerProvider>
          </MessageNoteContextProvider>
      </MessageContext.Provider>
    </div>
  );
}