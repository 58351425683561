import React, {Component} from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import {Chips} from 'primereact/chips';
import { MultiSelect } from 'primereact/multiselect';
import {Checkbox} from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import {InputSwitch} from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import {Password} from 'primereact/password';
import { Calendar } from 'primereact/calendar';
import { Editor } from 'primereact/editor';
import { ToggleButton } from 'primereact/togglebutton';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import { Message } from 'primereact/message';
import ChildForm from './ChildForm';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { addLocale } from 'primereact/api';
import NotificationBody from "../components/Notifications/Notification/NotificationBody";
import ProviderPaymentService from "../components/Providers/Provider/ProviderPaymentService";
import WhatsappGatewayService from "../components/Providers/Provider/WhatsappGatewayService";
import WhatsappMaxNotification from "../components/Providers/Provider/WhatsappMaxNotification";
import {InputNumber} from "primereact/inputnumber";
import BusinessIntegrationMaxCapacity from "../components/Providers/Provider/BusinessIntegrationMaxCapacity";
registerPlugin(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileValidateSize);


class InputForm extends Component {

    constructor() {
        super();
        this.showError = this.showError.bind(this);
    }

    showError(resp) {
        this.messages.show({ life: 5000, severity: 'error', summary: 'Error', detail: resp });
    }

    inputFormSwitch = () => {
        let inputElement = null;
        let error = null;

        let customAttributes = {};

        if (this.props.dependenceOf){
            customAttributes[this.props.elementType] = this.props.dependenceOf;
            this.props.dependenceOf.map(dependence => {
                let filter_field = this.props.fields.filter(x => x.id === dependence);
                customAttributes[filter_field[0].id] = filter_field[0].config.value;
                return customAttributes;
            });
        }
        switch (this.props.elementType) {
            case('text'):
                if (this.props.elementConfig.error){
                    error = 'p-error';
                }

                inputElement =
                    <InputText
                        {...this.props.elementConfig}
                        value={this.props.value}
                        onChange={this.props.changed}
                        className={error}
                        disabled={this.props.disabled}
                    />;
                break;
            case('textarea'):
                inputElement =
                    <InputTextarea
                        {...this.props.elementConfig}
                        value={this.props.value}
                        onChange={this.props.changed}
                    />;
                break;
            case('batch'):
                break;
            case('tags'):
                inputElement =
                    <Chips
                        {...this.props.elementConfig}
                        value={this.props.value}
                        onChange={this.props.changed}
                    />;
                break;
            case('multiSelect'):
                inputElement =
                    <MultiSelect
                        {...this.props.elementConfig}
                        value={this.props.value}
                        onChange={this.props.changed}
                    />;
                break;
            case('checkbox'):
                inputElement =
                    <Checkbox
                        {...this.props.elementConfig}
                        checked={this.props.value}
                        onChange={this.props.changed}
                    />;
                break;
            case('radio'):
                inputElement =
                    <RadioButton
                        {...this.props.elementConfig}
                        value={this.props.value}
                        onChange={this.props.changed}
                    />;
                break;
            case('switch'):
                inputElement =
                    <InputSwitch
                        {...this.props.elementConfig}
                        onChange={this.props.changed}
                        checked={this.props.value}
                        disabled={this.props.disabled}
                    />;
                break;
            case('dropdown'):
                if (this.props.elementConfig.error){
                    error = 'p-error';
                }

                inputElement =
                    <Dropdown
                        {...this.props.elementConfig}
                        options={this.props.elementConfig.options}
                        value={this.props.value}
                        onChange={this.props.changed}
                        placeholder={this.props.elementConfig.placeholder}
                        autoWidth={false}
                        className={error}
                    />;
                break;
            case('password'):
                inputElement =
                    <Password
                        {...this.props.elementConfig}
                        value={this.props.value}
                        onChange={this.props.changed}
                    />;
                break;
            case('calendar'):
                addLocale('es', {
                    firstDayOfWeek: 1,
                    dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
                    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
                    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
                    monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                    monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
                });
                inputElement =
                    <Calendar
                        {...this.props.elementConfig}
                        value={this.props.value}
                        onChange={this.props.changed}
                        locale="es"
                        placeholder={this.props.elementConfig.placeholder}
                        dateFormat={"dd/mm/yy"}
                    />;
                break;
            case('editor'):
                const headerEditor = (
                    <span className="ql-formats">
                        <button className="ql-bold" aria-label="Bold"/>
                        <button className="ql-italic" aria-label="Italic"/>
                        <button className="ql-underline" aria-label="Underline"/>
                        <button className="ql-link" aria-label="Link"/>
                    </span>
                );
                inputElement =
                    <Editor
                        {...this.props.elementConfig}
                        value={this.props.value}
                        onTextChange={this.props.changed}
                        headerTemplate={headerEditor}
                    />;
                break;
            case('providerPaymentService'):
                inputElement =
                    <ProviderPaymentService
                        {...this.props.elementConfig}
                        {...customAttributes}
                        errors={this.props.providerPaymentServiceFormError}
                        value={this.props.value}
                        onChange={this.props.changed}
                    />;
                break;
            case('whatsappGatewayService'):
                inputElement = (
                    <WhatsappGatewayService
                        {...this.props.elementConfig}
                        {...customAttributes}
                        value={this.props.value}
                        onChange={this.props.changed}
                    />
                );
                break;
            case('whatsappMaxNotification'):
                inputElement = (
                    <WhatsappMaxNotification
                        {...this.props.elementConfig}
                        {...customAttributes}
                        value={this.props.value}
                        onChange={this.props.changed}
                    />
                );
                break;
            case('businessIntegrationMaxCapacity'):
                inputElement = (
                    <BusinessIntegrationMaxCapacity
                        {...this.props.elementConfig}
                        {...customAttributes}
                        value={this.props.value}
                        onChange={this.props.changed}
                    />
                );
                break;
            case('notificationBody'):
                inputElement =
                    <NotificationBody
                        {...this.props.elementConfig}
                        {...customAttributes}
                        errors={this.props.notificationTypeFormError}
                        value={this.props.value}
                        onChange={this.props.changed}
                    />;
                break;
            case('toggleButton'):
                inputElement =
                    <ToggleButton
                        {...this.props.elementConfig}
                        checked={this.props.value}
                        onChange={this.props.changed}
                    />;
                break;
            case('childForm'):
                inputElement = <ChildForm typeElement={this.props.typeElement} children={this.props.children}
                                          options={this.props.options} title={this.props.title}
                                          changed={this.props.changed} clicked={this.props.clicked}
                                />;
                break;
            case('button'):
                inputElement =
                    <Button label={this.props.value} className={this.props.classes} onClick={this.props.clicked} type='button' />;
                break;
            case('file_upload'):
                let labelIdle = this.props.labelIdle !== undefined ? this.props.labelIdle :
                    'Arrastre y Suelte los archivos o seleccione <span class="filepond--label-action"> Buscar </span>';

                let labelFileLoading = 'Cargando';
                let labelFileLoadError = 'Error en la carga';
                let labelFileProcessing = 'Subiendo';
                let labelFileProcessingComplete = 'Subida completada';
                let labelFileProcessingAborted = 'Subida cancelada';
                let labelFileProcessingError = 'Error en la carga';
                let labelTapToCancel = 'Toque para cancelar';
                let labelTapToRetry = 'Toque para reintentar';
                let labelTapToUndo = 'Toque para deshacer';
                let labelButtonRemoveItem = 'Eliminar';
                let labelButtonAbortItemLoad = 'Abortar';
                let labelButtonRetryItemLoad = 'Reintentar';
                let labelButtonAbortItemProcessing = 'Cancelar';
                let labelButtonUndoItemProcessing = 'Deshacer';
                let labelButtonRetryItemProcessing = 'Reintentar';
                let labelButtonProcessItem = 'Subir';
                let labelFileTypeNotAllowed = 'Tipo de archivo inválido';
                let fileValidateTypeLabelExpectedTypes = 'Esperado {allTypes}';
                let server = null;

                if(this.props.server === undefined){
                    server = {
                        url: this.props.url,
                        process: {
                            withCredentials: true,
                            timeout: 240000,
                            headers: {
                                Authorization: 'Bearer '+ localStorage.getItem('token')
                            },
                            onerror: (response) => {
                                let resp;
                                try {
                                    resp = JSON.parse(response);
                                    resp = Object.values(resp)[0]
                                } catch (e) {
                                    resp = 'Error'
                                }
                                this.showError(resp);
                            }
                        },
                        revert: null
                    };
                } else {
                    server = this.props.server
                }

                inputElement =
                    <FilePond {...this.props.elementConfig} server={server} name={this.props.typeElement}
                              labelIdle={labelIdle} labelFileLoading={labelFileLoading} labelFileLoadError={labelFileLoadError}
                              labelFileProcessing={labelFileProcessing} labelFileProcessingComplete={labelFileProcessingComplete}
                              labelFileProcessingAborted={labelFileProcessingAborted} labelFileProcessingError={labelFileProcessingError}
                              labelTapToCancel={labelTapToCancel} labelTapToRetry={labelTapToRetry} labelTapToUndo={labelTapToUndo}
                              labelButtonRemoveItem={labelButtonRemoveItem} labelButtonAbortItemLoad={labelButtonAbortItemLoad}
                              labelButtonRetryItemLoad={labelButtonRetryItemLoad} labelButtonAbortItemProcessing={labelButtonAbortItemProcessing}
                              labelButtonUndoItemProcessing={labelButtonUndoItemProcessing} labelButtonRetryItemProcessing={labelButtonRetryItemProcessing}
                              labelButtonProcessItem={labelButtonProcessItem} labelFileTypeNotAllowed={labelFileTypeNotAllowed}
                              fileValidateTypeLabelExpectedTypes={fileValidateTypeLabelExpectedTypes} files={this.props.files}

                    />;
                break;
            case('number'):
                inputElement =
                    <InputNumber
                        {...this.props.elementConfig}
                        value={this.props.value}
                        onValueChange={this.props.changed}
                        className={error}
                    />
                break;
            default:
                inputElement =
                    <InputText
                        {...this.props.elementConfig}
                        value={this.props.value}
                        onChange={this.props.changed}
                    />;
                break;
        }

        return inputElement;

    };

    getLabelHtml = (elementType, element) => {
        let nameInput = null;
        switch (elementType) {
            case('text'):
                nameInput = <span className="p-float-label">{element}<label htmlFor="input">{this.props.traduction}</label></span>;
                break;
            case('number'):
                nameInput = <span className="p-float-label">{element}<label htmlFor="input">{this.props.traduction}</label></span>;
                break;
            case('dropdown'):
                nameInput = <span className="p-float-label">{element}<label htmlFor="days">{this.props.traduction}</label></span>;
                break;
            case('textarea'):
                nameInput = <span className="p-float-label">{element}</span>;
                break;
            case('switch'):
                nameInput = <div><label htmlFor="switch">{this.props.traduction}</label><span className="p-float-label">{element}</span></div>;
                break;
            case('checkbox'):
                nameInput = <div>{element}<label htmlFor="checkbox" className="p-checkbox-label">{this.props.traduction}</label></div>;
                break;
            case('password'):
                nameInput = <span className="p-float-label">{element}<label htmlFor="password">{this.props.traduction}</label></span>;
                break;
            default:
                nameInput = element;
                break;
        }
        return nameInput;
    };

    render() {
        let element = this.inputFormSwitch();
        let body = null;
        let messageError = null;
        let labelHtml = this.getLabelHtml(this.props.elementType, element);

        if (this.props.elementConfig !== undefined){
            if (this.props.elementConfig.error !== undefined){
                if (this.props.elementConfig.error !== null){
                    messageError = (
                        <div className="col-12 md-3">
                            <Message severity="error" text={this.props.elementConfig.error} style={{ float: 'right', marginBotton: '30px', paddingLeft: '.5em' }}/>
                        </div>
                    );
                }
            }
        }
        let errorUpload = (
            <div className="grid">
                <div className="col-12">
                    <Messages ref={(el) => this.messages = el} />
                </div>
            </div>
        );
        if (!this.props.frecuencyForm){
            body = (<div className={this.props.elementType !== 'childForm' ? (this.props.elementType === 'button' ? 'col-6 ' : null) : "" }>
                        {errorUpload}
                        <div className="grid form-group">
                            <div className={(this.props.colsClass && this.props.colsClass.input) || "col-12 md-9"}>
                                {labelHtml}
                            </div>
                            {messageError}
                        </div>
                    </div>);
        } else {
            body = (
                <div className="col-2">
                    <div className="grid form-group">
                        <div className={(this.props.colsCLass && this.props.colsClass.error) || "col-12"}>
                            {labelHtml}
                        </div>
                    </div>
                </div>
           );
        }

        return (
            body
        );
    }

}

export default InputForm;
