import React, { Component } from 'react';
import axios from 'axios';
import MoneyField from '../UI/MoneyField';
import '../assets/css/ReceivableAmount.css';

class ReceivableAmount extends Component {

    _isMounted = false;
    state = {
        accrued_amount:{
            total_loans: null,
            total_interest: null,
            amount: null, 
            amount_borrowed: null, 
            amount_interest: null
        },
        discounted_amount: null,
        get_total_payments: {
            total_loans: null,
            total_interest: null
        },
        total_amount: {
            total_loans: null,
            total_interest: null
        },
        expired_amount: {
            total_loans: null,
            total_interest: null
        },
        not_expired_amount: {
            total_loans: null,
            total_interest: null
        },
        loading: true
    }

    getColsByPercentage(total, portion){
        if(!total || !portion){
            return false
        }
        let percentage = ((portion * 100) / total)
        return Math.round((percentage * 12) / 100)

    }

    getPercentage(total, value){
        return ((value * 100) / total)
    }

    componentDidMount(){
        this._isMounted = true;
        axios.get('/statistics/amount_receivable')
                .then(response => {
                    if (this._isMounted){
                        this.setState({
                            accrued_amount: response.data.accrued_amount,
                            discounted_amount: response.data.discounted_amount,
                            get_total_payments: response.data.get_total_payments,
                            total_amount: response.data.total_amount,
                            expired_amount: response.data.expired_amount,
                            not_expired_amount: response.data.not_expired_amount,
                            loading: false
                        });
                    }

                }).catch(error => {
                    this.setState({loading: false});
                    console.log('Se produjo un error. Intente nuevamente en unos minutos');
                });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render(){
        
        const net_value = this.state.accrued_amount && this.state.discounted_amount && (<div className="col-12">
                                    <div className="grid">
                                        <div className="col-12 amount-box">
                                            <div className="overview-box">
                                                <div className="overview-box-content" style={{'backgroundColor': '#6aacd9'}}>
                                                    <div className="overview-box-title overview-box-description">Valor Neto</div>
                                                    <div className="overview-box-count"><MoneyField decimals={0}>{this.state.accrued_amount.amount + (this.state.accrued_amount.amount_interest || 0) - this.state.discounted_amount}</MoneyField></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>);
        
        const discounted_amount = this.state.discounted_amount && (<div className="col-12">
                                            <div className="grid">
                                                <div className="col-12 amount-box">
                                                    <div className="overview-box">
                                                        <div className="overview-box-content" style={{'backgroundColor': '#6aacd9'}}>
                                                            <div className="overview-box-title overview-box-description">Bonificación</div>
                                                            <div className="overview-box-count"><MoneyField decimals={0}>{this.state.discounted_amount}</MoneyField></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>);
        
        const accrued_amount = (<div className={"col-12"}>
                                    <div className="grid ">
                                        <div className="col-12 amount-box">
                                            <div className={"parent-box overview-box"}>
                                                <div className="overview-box overview-box-4">
                                                    <div className="overview-box-content" style={{'backgroundColor': 'rgb(69, 117, 150)'}}>
                                                        <div className="overview-box-title overview-box-description">Comprobantes Emitidos <br/> En Cuenta Corriente</div>
                                                        <div className="overview-box-count"><MoneyField decimals={0}>{this.state.accrued_amount.amount + (this.state.accrued_amount.amount_interest || 0)}</MoneyField></div>
                                                    </div>
                                                </div>                                          
                                                <div className="col-12 amount-box">
                                                    <div className={'grid p-justify-center'}>
                                                        <div className="col-11">
                                                            {net_value}
                                                            {discounted_amount}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>);

        const get_total_payments = (<div className={"col-12"}>
                                        <div className="grid">
                                            <div className="col-12 amount-box">
                                                <div className="overview-box">
                                                    <div className="overview-box-content" style={{'backgroundColor': 'rgb(103, 169, 97)'}}>
                                                        <div className="overview-box-title overview-box-description">Cobranzas<br/>{this.getPercentage(this.state.accrued_amount.amount + this.state.accrued_amount.amount_interest - this.state.discounted_amount, this.state.get_total_payments.total_loans + this.state.get_total_payments.total_interest).toFixed(2) + '%' }</div>
                                                        <div className="overview-box-count"><MoneyField decimals={0}>{this.state.get_total_payments.total_loans}</MoneyField></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>);

        const expired_amount_box = (<div className={"col-12"}>
                                        <div className="grid">
                                            <div className="col-12 amount-box">
                                                <div className="overview-box overview-box-grey overview-box-4" >
                                                    <div className="overview-box-content">
                                                        <div className="overview-box-title overview-box-description">Vencido<br/>{this.getPercentage(this.state.total_amount.total_loans + this.state.total_amount.total_interest, this.state.expired_amount.total_loans + this.state.expired_amount.total_interest).toFixed(2) + '%'}</div>
                                                        <div className="overview-box-count"><MoneyField decimals={0}>{this.state.expired_amount.total_loans + (this.state.expired_amount.total_interest || 0)}</MoneyField></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>);

        const not_expired_amount_box = (<div className={"col-12"}>
                                            <div className="grid">
                                                <div className={'col-12 amount-box'}>
                                                    <div className="overview-box overview-box-2">
                                                        <div className="overview-box-content">
                                                            <div className="overview-box-title overview-box-description">{'A Vencer ' + this.getPercentage(this.state.total_amount.total_loans + this.state.total_amount.total_interest, this.state.not_expired_amount.total_loans + this.state.not_expired_amount.total_interest).toFixed(2) + '%'}</div>
                                                            <div className="overview-box-count"><MoneyField decimals={0}>{(this.state.not_expired_amount.total_loans + (this.state.not_expired_amount.total_interest || 0)).toFixed(2)}</MoneyField></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>);

    	const total_amount_box = (<div className={"col-12"}>
                                        <div className="grid ">
                                                <div className="col-12 amount-box">
                                                    <div className={"parent-box overview-box"}>
                                                        <div className="overview-box overview-box-red overview-box-4">
                                                            <div className="overview-box-content">
                                                                <div className="overview-box-title overview-box-description">Cuentas<br/>Por Cobrar<br/>{this.getPercentage(this.state.accrued_amount.amount + this.state.accrued_amount.amount_interest - this.state.discounted_amount, this.state.total_amount.total_loans + this.state.total_amount.total_interest).toFixed(2) + '%'}</div>
                                                                <div className="overview-box-count"><MoneyField decimals={0}>{this.state.total_amount.total_loans + (this.state.total_amount.total_interest || 0)}</MoneyField></div>
                                                            </div>
                                                        </div>                                          
                                                        <div className="col-12 amount-box">
                                                            <div className={'grid p-justify-center'}>
                                                                <div className="col-11">
                                                                    {expired_amount_box}
                                                                    {not_expired_amount_box}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                </div>);

        const balance_control = (<div className={"col-12"}>
                <div className="grid">
                    <div className={'col-12 amount-box'}>
                        <div className="overview-box overview-box-yellow overview-box-4">
                            <div className="overview-box-content">
                                <div className="overview-box-title overview-box-description">Control de Saldo</div>
                                <div className="overview-box-count"><MoneyField decimals={0}>{((this.state.accrued_amount.amount + (this.state.accrued_amount.amount_interest || 0)) - ((this.state.get_total_payments.total_loans || 0) + (this.state.discounted_amount || 0) + (this.state.total_amount.total_loans + (this.state.total_amount.total_interest || 0))))}</MoneyField></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);

        return (
        	<div className={"col-12 dashboard-box"}>
                <div className={"grid"}>
                    {this.state.loading || accrued_amount}
                    {this.state.loading || get_total_payments}
                    {this.state.loading || total_amount_box}
                    {this.state.loading || balance_control}
                </div>
        	</div>
        );

    }
}

export default ReceivableAmount;