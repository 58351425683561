import React, {useEffect, useState, useRef, useContext, Fragment} from 'react';
import {throttle} from "throttle-debounce";
import moment from 'moment';
import jwtDecode from "jwt-decode";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Avatar } from 'primereact/avatar';
import { MultiSelect } from "primereact/multiselect";
import { Toast } from 'primereact/toast';
import ApiLoan from "../../../service/ApiLoanService";
import { formatDateMessage, formatMoney, listToObject } from "../../../utils/utils";
import { markCurrentUser } from "../../../utils/users";
import { markAsSeen } from "../../../utils/messenger";
import {MessageContext} from "../../../context/messageContext";
import MessageContextItem from '../MessageContextItem';
import { PHONE } from "../../../shared/contactTypes";
import { WHATSAPP } from "../../../shared/channelTypes";
import { Badge } from 'primereact/badge'
import BasicDropDown from "../../../UI/BasicDropDown";
import TemplateOverlay from "../../Templates/TemplateOverlay";
import Compose from '../Compose';
import Toolbar from '../Toolbar';
import MessageMedia from "../MessageMedia";
import './MessageList.scss';
import AudioRecord from "../AudioRecord";
import ConversationView from "./ConversationView";
import InfiniteScroll from 'react-infinite-scroller';
import Picker from "emoji-picker-react";
import ReactTooltip from "react-tooltip";
import { ProgressBar } from 'primereact/progressbar';
import {MessageNoteContext} from "../../../context/messageNoteContext";
import MessageNoteCalendar from "../MessageNoteCalendar/MessageNoteCalendar";
import MessageNoteReminder from "../MessageNote/MessageNoteReminder";
import CustomSpinner from "../../../UI/CustomSpinner";
import { convertMimetypeType } from '../../../shared/utility';
import MessageForwardModal from "../MessageForward/MessageForwardModal";
import ContactSelectorModal from "../ContactSelector/ContactSelectorModal";
import {CompanyContext} from "../../../context/companyContext";
import { MessageMediaPreview } from '../MessageMediaPreview';
import DialogGeneric from "../../../UI/DialogGeneric";
import {Dialog} from "primereact/dialog";
import MessagesNoteModal from "../MessageNote/MessagesNoteModal";
import { ScrollPanel } from 'primereact/scrollpanel';
import TemplatesView from '../../Templates/TemplatesView';
import ClientFormCusmon from '../../Clients/Client/ClientFormCustom';
import { Sidebar } from 'primereact/sidebar';
import { Menu } from 'primereact/menu';
import { Dropdown } from 'primereact/dropdown';
import MessageSkeleton from '../MessageSkeleton';
import ToolbarButton from '../ToolbarButton';
import { DateTime } from 'luxon';


export default function MessageList(props) {
  const [currentClient, setCurrentClient] = useState(null);
  const [newMessage, setNewMessage] = useState(null);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [vars, setVars] = useState([]);
  const [templates, setTemplates] = useState({
    loading: false,
    templates: []
  });
  const [text, setText] = useState('');
  const [messageType, setMessageType] = useState({
    value: null,
    name: null
  });
  const [contactSelected, setContactSelected] = useState(null);
  const [userListDropdownState, setUserListDropdownState] = useState(false);
  const [userGroupListDropdownState, setUserGroupListDropdownState] = useState(false);
  const [customTagsListDropdownState, setCustomTagsListDropdownState] = useState(false);
  const [customTagsSelected, setCustomTagsSelected] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState(false);
  const [customTagsOptions, setCustomTagsOptions] = useState([]);
  const [activeNotes, setActiveNotes] = useState(false);
  const [messagesPerPage, setMessagesPerPage] = useState(20);
  const [firstLoading, setFirstLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const toast = useRef(null);
  const messageListComponent = useRef(null);
  const scrollParentRef = useRef(null);
  const [loadingConversation, setLoadingConversation] = useState(true);
  const message_context = useContext(MessageContext);
  const [mimetype, setMimetype] = useState('chat');
  const [showPicker, setShowPicker] = useState(false);
  const [hideShowLeftItems, setHideShowLeftItems] = useState(false);
  const [startAudio, setStartAudio] = useState(false);
  const [files, setFiles] = useState([]);
  const [onFiles, setOnFiles] = useState(false);
  const [whatsappTemplateHeaderConfig, setWhatsappTemplateHeaderConfig] = useState(null);
  const apiService = new ApiLoan();
  const [groups, setGroups] = useState([]);
  const lastMessageRef = useRef(null);
  const [selectedUsersOption, setSelectedUsersOption] = useState(null);
  const [showSelectUser, setShowSelectUser] = useState(false);
  const [selectedGroupsOption, setSelectedGroupsOption] = useState(null);
  const [showSelectGroup, setShowSelectGroup] = useState(false);
  const [showTemplate, setShowTemplate]= useState(false);
  const [showModal,setShowModal] = useState(false);
  const [showClientView, setShowClientView] = useState(false);
  const [itemUserGroupMenu, setItemUserGroupMenu] = useState([]);
  const [scrollAtBottom, setScrollAtBottom] = useState(true);
  const [scrollAtBottomCount, setScrollAtBottomCount] = useState(0);
  const [showTemplateWhatsappDialog, setShowTemplateWhatsappDialog] = useState(false)
  const [templateWhatsappDialog, setTemplateWhatsappDialog] = useState({
    value: '',
    template_uuid: '',
    title: ''
  });
  const refUserGroupMenu = useRef(null);
  const refEditBotNotesMenu= useRef(null);
  const [scheduledMessageButtonsDialog, setScheduledMessageButtonsDialog] = useState(true)
  const [showTemplates, setShowTemplates] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [cursorPosition, setCursorPosition] = useState(null);
  const [onStar, setOnStar] = useState(false);
  const composeRef = useRef(null);
  const company_context = useContext(CompanyContext);
  const {setIsTopBarVisible} = company_context;

  useEffect(() => {
    const updateWindowDimensions = () => {
      const isMobileView = window.innerWidth <= 750;
      setIsTopBarVisible(!isMobileView);
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, [setIsTopBarVisible]);

  useEffect(() => {
    setText('');
  }, [currentClient]);

  const cursorPositionComposeHandler = (start) => {
    setCursorPosition((prevState) => {
        return {
            ...prevState,
            cursorPosition: start,
        }
    })
  }

  let filesTmp = []

  const {selectedClient, globalClients, selectedMessageNote, messageNotes, errorNotes, loadingNote, setFoundMessage,
    updateSelectedClient, updateMessageNotes, updateErrorNotes, updateExpirationNotes} = useContext(MessageNoteContext);
  const {chatbotEnabled} = company_context;
  const socket = company_context.socket;
  const isMobile = () => {
    return window.innerWidth <= 750;
  };
  const [onDrag, setOnDrag] = useState(false);

  useEffect(() => {
    let users =  {
      label: 'Usuarios',
      icon: 'pi pi-user',
      command: () => {
        setShowSelectUser(true);
      }
    }
    let groups = {
      label: 'Equipos',
      icon: 'pi pi pi-users',
      command: () => {
        setShowSelectGroup(true);
      }
    }
    setItemUserGroupMenu([users, groups]);
  },[currentClient])

  useEffect(() => {
    setIsTopBarVisible(false);
  },[setIsTopBarVisible])


  useEffect(() => {
    if (onFiles) setText('')
  },[onFiles]);

  useEffect(() => {
    if(text.startsWith('/')){
      if(text.startsWith('//')){
          if ((['DIALOG_360', 'CLOUD-API'].includes(localStorage.getItem('gatewayWaba')))){
            setShowPicker(false);
            setShowTemplate(true);
          }else{
            setShowTemplate(false);
          }
      }else{
          setShowPicker(false);
          setShowTemplate(true);
      }
    }else{
      setShowTemplate(false);
    }
  },[text]);

  useEffect(() => {
    if (props.client.id !== currentClient?.id){
      setCurrentClient(props.client);
      setMessages([]);
      message_context.updateMessageContext(null)
      setFirstLoading(true)
      message_context.updateForward(false);
      message_context.updateSelectedForwardMessages([]);
    }

    const contacts = props.client.contacts.filter((c) => c.contact_type_id === PHONE);
    let sc = null;
    const client_info = {...props.client};
    let tags = client_info.tags;

    if (contacts.length > 0) {
      sc = contacts[0];
    }

    if (props.client.contact) {
      const contactsByValue = listToObject(contacts, 'value');
      if (contactsByValue[props.client.contact.value]){
        sc = contactsByValue[props.client.contact.value];
      }
    }
    setOnFiles(false);
    setShowClientView(false);
    setFiles([])
    setContacts(contacts);
    setContactSelected(sc);
    getClientVariables();
    setShowSelectUser(false);
    setShowSelectGroup(false);
    setCustomTagsSelected(tags);

    setCustomTagsListDropdownState(false);
    setUserListDropdownState(false);
    setUserGroupListDropdownState(false);

    markAsSeen(true, props.client.id);
    setPage(1);
    setOnDrag(false);
    setShowModal(false);
    setOnStar(false);
    setScrollAtBottom(true);

    return () => {
      setTotalPages(1)
      setPage(0)
      setHasMoreMessages(false)
    }
  }, [props.client]);

  useEffect(() => {
    if (props.client.id === currentClient?.id){
      if (firstLoading && page === 1){
        setLoadingMessages(true);
        getMessages(page);
        setFirstLoading(false)
      }
    }
  }, [page, props.client, currentClient])

  useEffect(() => {
    socket.on(props.client.id + '-' + WHATSAPP, addNewMessage)
    socket.on('message_failed-' + props.client.id + '-' + WHATSAPP, failedMessage)
    socket.on('delete_reminder-' + localStorage.getItem('provider_id'), deleteNoteReminder);
    socket.on('seen_reminder-' + localStorage.getItem('provider_id'), seenNoteReminder);
    socket.on('update_status_message-' + localStorage.getItem('provider_id'), updateStatusMessage);
    socket.on('update_message_reaction-' + localStorage.getItem('provider_id'), updateMessageReaction);
    socket.on('delete_message_reaction-' + localStorage.getItem('provider_id'), deleteMessageReaction);
    socket.on('update_message-' + localStorage.getItem('provider_id'), updateMessage);
    socket.on('update_client-' + localStorage.getItem('provider_id'), updateClient);
    return () => {
        socket.off(props.client.id + '-' + WHATSAPP, addNewMessage)
        socket.off('message_failed-' + props.client.id + '-' + WHATSAPP, failedMessage)
        socket.off('delete_reminder-' + localStorage.getItem('provider_id'), deleteNoteReminder);
        socket.off('seen_reminder-' + localStorage.getItem('provider_id'), seenNoteReminder);
        socket.off('update_status_message-' + localStorage.getItem('provider_id'), updateStatusMessage);
        socket.off('update_message_reaction-' + localStorage.getItem('provider_id'), updateMessageReaction);
        socket.off('delete_message_reaction-' + localStorage.getItem('provider_id'), deleteMessageReaction);
        socket.off('update_message-' + localStorage.getItem('provider_id'), updateMessage);
        socket.off('update_client-' + localStorage.getItem('provider_id'), updateClient);
      }
  },[messages, props.client, currentClient, page, totalPages, messageNotes, toast, scrollAtBottom]);

  useEffect(() => {
    const messageTypesAllowed = ['document', 'image', 'audio', 'template', 'video', 'reaction', 'vcard']

    if (messageTypesAllowed.includes(messageType.name)){
      sendMessage();
    }
  }, [messageType.name, whatsappTemplateHeaderConfig])

  useEffect(() => {
    const messageTypesAllowed = ['forward']

    if (messageTypesAllowed.includes(messageType.name)){
      sendForwardMessage();
    }
  }, [messageType.name])

  useEffect(() => {
    if (userListDropdownState){
      setCustomTagsListDropdownState(false);
      setUserGroupListDropdownState(false);
    }
  },[userListDropdownState]);

  useEffect(() => {
    if (customTagsListDropdownState){
      setShowSelectUser(false);
      setShowSelectGroup(false);
      setUserListDropdownState(false);
      setUserGroupListDropdownState(false);
    }
  },[customTagsListDropdownState]);

  useEffect(() => {
    if (userGroupListDropDown){
      setUserListDropdownState(false);
      setCustomTagsListDropdownState(false);
    }
  }, [userGroupListDropdownState])

  useEffect(() => {
    if (userListDropdownState || customTagsListDropdownState ){
      setActiveDropdown(true);
    } else {
      setActiveDropdown(false);
    }

  },[userListDropdownState, customTagsListDropdownState ]);

  useEffect(() => {
    if (props.buttonsActivated){
      setCustomTagsListDropdownState(false);
      setUserListDropdownState(false);
      setUserGroupListDropdownState(false)
    }
  },[props.buttonsActivated]);

  useEffect(() => {
    if (currentClient){
      let custom_tags = message_context.customTags;
      let client = {...currentClient};

      const client_custom_tags = client.client_custom_tags;

      custom_tags = custom_tags.map(custom_tag => {
        client_custom_tags.map(client_tag => {
          if (client_tag.id === custom_tag.id){
            custom_tag['fixed_tag'] = client_tag.fixed_tag
          }
        });
        return custom_tag;
      });

      setCustomTagsOptions(custom_tags);
    }
  }, [message_context.customTags, currentClient]);

  useEffect(() => {
    const usersListWithCurrentUserMarked = markCurrentUser(message_context.users);
    setUsers(usersListWithCurrentUserMarked);
    setGroups(message_context.groups)
  }, [message_context.groups, message_context.users])

  useEffect(() => {
    if (activeNotes && currentClient){
      if (selectedClient === null){
        updateSelectedClient(currentClient.id);
      }
    } else {
      if (selectedClient !== null && !globalClients){
        updateSelectedClient(null);
      }
    }
  }, [activeNotes, currentClient, selectedClient, globalClients])

  useEffect(() => {
    if (message_context.selectedMessageContext !== null && !loadingMessages){
      if (messages.filter(x => x.id === message_context.selectedMessageContext)[0] === undefined) {
        if (hasMoreMessages){
          getMoreMessages(page);
        }
      } else {
        message_context.updateSelectedMessageContext(null);
      }
    }
  }, [loadingMessages, messages, hasMoreMessages, message_context.selectedMessageContext])

  useEffect(() => {
    if (selectedMessageNote !== null){
      setActiveNotes(false)
    }
  }, [selectedMessageNote])

  useEffect(() => {
    if (message_context.selectedMessageContext !== null){
      message_context.updateLoadingMessages(true);
    }
  }, [message_context.selectedMessageContext])

  useEffect(() => {
    if (message_context.selectedMessageContext === null && message_context.loadingMessages){
      message_context.updateFoundMessageContext(true);
      message_context.updateLoadingMessages(false);
    }
  }, [messages, message_context.loadingMessages, message_context.selectedMessageContext])

  useEffect(() => {
    if (!message_context.loadingMessages &&  message_context.foundMessageContext ){
      message_context.updateFoundMessageContext(false)
    }
  }, [message_context.loadingMessages, message_context.foundMessageContext, messages])

  useEffect(() => {
    if (errorNotes !== null && activeNotes){
      toast.current.show({severity: 'error', summary: 'Nota/Recordatorio', detail: errorNotes,
        sticky: true});
      updateErrorNotes(null);
    }
  }, [errorNotes, activeNotes]);

  useEffect(() => {
    if (message_context.messageReaction !== null){
      setMessageType(() => {
        return {
          name: 'reaction',
          value: {
            'message_id': message_context.messageReaction.message_id,
            'emoji': message_context.messageReaction.emoji,
          }
        }
      });
    }
  }, [message_context.messageReaction])

  useEffect(() => {
    if (message_context.forward && message_context.selectedForwardContact !== null){
      if (message_context.selectedForwardMessages.length > 0){
        setMessageType(() => {
          return {
            name: 'forward',
            value: {
              'messages': message_context.selectedForwardMessages,
              'to': message_context.selectedForwardContact
            }
          }
        });
      }
    }
  }, [message_context.forward, message_context.selectedForwardMessages, message_context.selectedForwardContact])

  useEffect(() => {
    if (message_context.selectedContacts[message_context.selectedContacts.length - 1] === -1) {
      setMessageType(() => {
        let selectedContacts = [...message_context.selectedContacts];
        selectedContacts.pop();
        message_context.updateSelectedContacts([]);
        setText(JSON.stringify(
          {
            "vcards": selectedContacts
          }
        ))
        return {
          name: 'vcard',
          value: {
            "vcards": selectedContacts
          }
        }
      });
    }
  }, [message_context.contacts, message_context.selectedContacts])

  useEffect(() => {
    if (currentClient?.conversation?.user) {
      setSelectedUsersOption(markCurrentUser([currentClient?.conversation?.user])[0]);
    } else {
      setSelectedUsersOption(null);
    }
  }, [currentClient]);

  useEffect(() => {
    if (currentClient?.conversation?.group && groups) {
      let newGroups = [...groups]
      let newSelectGroupsOption = newGroups.filter(x => x.id === currentClient?.conversation?.group?.id)[0]
      setSelectedGroupsOption(newSelectGroupsOption);
    } else {
      setSelectedGroupsOption(null);
    }
  }, [currentClient]);

  useEffect(() => {
    if (messageType.name === 'scheduled_message'){
      setMessageType((prevState) => {
        return {
          name: prevState.name,
          value: {
            reminder_date: prevState.value.reminder_date,
            template_uuid: templateWhatsappDialog.template_uuid
          }
        }
      })
    }
  }, [templateWhatsappDialog]);

  const updateClient = (newClient) => {
    if (newClient.id === props.client.id){
      getClientVariables();
      setCurrentClient(prevClient => ({
        ...prevClient,
        name: newClient.firstname + ' ' + newClient.lastname
      }));
      const newContact = newClient.contacts.filter((c) => c.contact_type_id === PHONE);
      if (newContact.length !== 0){
        newContact.map((element) => {
          if ((element?.id === contactSelected?.id && element?.value !== contactSelected?.value) || contacts.length === 0){
            resetMessages(newContact, element);
          }
        })
      }else{
        resetMessages();
      }
    }
  }

  const resetMessages = (contacts=[], selectContact=null) => {
    setMessages([]);
    message_context.updateMessageContext(null)
    setFirstLoading(true)
    message_context.updateForward(false);
    message_context.updateSelectedForwardMessages([]);

    const client_info = {...props.client};
    let tags = client_info.tags;

    setOnFiles(false);
    setShowClientView(false);
    setFiles([])
    setContacts(contacts);
    setContactSelected(selectContact);
    getClientVariables();
    setShowSelectUser(false);
    setShowSelectGroup(false);
    setCustomTagsSelected(tags);

    setCustomTagsListDropdownState(false);
    setUserListDropdownState(false);
    setUserGroupListDropdownState(false);

    markAsSeen(true, props.client.id);
    setPage(1);
    setOnDrag(false);
    setShowModal(false);
    setOnStar(false);
    setScrollAtBottom(true);
    getMessages(1)

    return () => {
      setTotalPages(1)
      setPage(0)
      setHasMoreMessages(false)
    }
  }

  const updateClientCustomTagsHandler = (values, client, contact) => {
    let formData = {
      'custom_tags': values,
      'fixed': true,
      'contact': contact
    }

    apiService.postResource({
      url: '/clients/'+ client.id + '/custom_tags',
      data: formData
    });
  }
  const updateClientCustomTagsThrottled = useRef(throttle(500, updateClientCustomTagsHandler));

  const updateClientCustomTags = (values) => {
    setCustomTagsSelected(values);
    updateClientCustomTagsThrottled.current(values, currentClient, contactSelected);
  }

  const scrollBottom = (message) => {
    setTimeout(() => messageListComponent.current?.scrollIntoView(false), 50)
    setNewMessage(message);
    setScrollAtBottomCount(0);
  }

  useEffect(() => {
    if (scrollAtBottom) setScrollAtBottomCount(0);
  }, [scrollAtBottom])

  const addNewMessage = (message, checkMessage=true) => {
    let newMessages = [...messages];
    message = formatDateMessage(message);
    if (!message.reaction){
      if (checkMessage){
        if(!checkIfMessageExist(message, newMessages)){
          newMessages.unshift(message);
        } else {
          let new_messages_index = newMessages.findIndex(x => x.id === undefined);
          if (new_messages_index > -1){
            newMessages[new_messages_index] = message;
          }
        }
      } else {
        newMessages.unshift(message);
      }
      setMessages(newMessages);
      markAsSeen(true, props.client.id);
      setFoundMessage(false);
    } else {
      newMessageReaction(message)
    }
    if (message.type !== 'received') {
      scrollBottom(message);
    } else {
      if (scrollAtBottom) scrollBottom(message);
      else setScrollAtBottomCount( prevCount => prevCount + 1);
    }
  }

  const updateStatusMessage = ({message, status}) => {
    let newMessages = [...messages];
    let message_index = newMessages.findIndex(x => x.id === message.id);
    if (message_index === -1) message_index = newMessages.findIndex(x => x.emoji === message.emoji);
    if (message_index > -1) {
      newMessages[message_index][status] = true
      setNewMessage(message);
      setMessages(newMessages.filter(x => x.id !== undefined))
    }
  }

  const failedMessage = ({message, error}) => {
    let msgs = [...messages];
    let message_failed_index = msgs.findIndex(x => x.id === message.id);
    if (message_failed_index > -1){
      msgs[message_failed_index].failed = true
      setMessages(msgs);
    }
    toast.current.show({severity: 'error', summary: 'Mensaje no enviado', detail: error, sticky: true});
  }

  const deleteNoteReminder = (message) => {
    if (toast.current !== null){
      let msgs = [...messages];
      let new_messages = msgs.filter(x => x.id !== message.id);
      setMessages(new_messages);

      let allowed_message_types = ['note_reminder', 'scheduled_message'];
      if (allowed_message_types.includes(message.message_type.name)){
        let messageNoteKey = message.message_type.name === 'scheduled_message' ? 'scheduled_messages' : 'note_reminders';
        let updated_messages = messageNotes[messageNoteKey].filter(x => x.id !== message.id);
        updateMessageNotes(prevState => {
          return {
            ...prevState,
            [messageNoteKey]: updated_messages
          }
        });
      }
      setActiveNotes(false)

      let detail = '';
      if (message.message_type.name === 'note'){
        detail = 'Se elimino la nota';
      } else if (message.message_type.name === 'note_reminder'){
        detail = 'Se elimino el recordatorio';
      } else {
        detail = 'Se elimino el mensaje programado';
      }
      toast.current.show({severity: 'success', summary: message.message_type.description, detail: detail, sticky: true});
    }
  }

  const seenNoteReminder = (message) => {
    if (toast.current !== null) {
      let allowed_message_types = ['note_reminder', 'scheduled_message'];
      if (!allowed_message_types.includes(message.message_type.name)){
        return null;
      }
      let messageNoteKey = message.message_type.name === 'scheduled_message' ? 'scheduled_messages' : 'note_reminders';
      let updated_messages = messageNotes[messageNoteKey].filter(x => x.id !== message.id)

      updateMessageNotes(prevState => {
        return {
          ...prevState,
          [messageNoteKey]: updated_messages
        }
      });

      updateExpiredNoteReminders(updated_messages)

      setActiveNotes(false)

      let msgs = [...messages];
      let msg_seen_index = msgs.findIndex(x => x.id === message.id);

      if (msg_seen_index > -1){
        msgs[msg_seen_index].seen = true;
        msgs[msg_seen_index].note_reminder.seen = true;
        setMessages(msgs);
      }

      let detail = '';
      if (message.message_type.name === 'note'){
        detail = 'Se marcó como visto la nota';
      } else if (message.message_type.name === 'note_reminder'){
        detail = 'Se marcó como visto el recordatorio';
      } else {
        detail = 'Se marcó como visto el mensaje programado';
      }

      toast.current.show({
        severity: 'success', summary: message.message_type.description, detail: detail,
        sticky: true
      });
    }
  }

  const updateMessageReaction = (message) => {
    if (message.parent === null){
      return null;
    }
    let parent_id = message.parent.id;

    let reaction = {
      'id': message.id,
      'client_id': message.client_id,
      'type': message.type,
      'emoji': message.emoji,
    }

    let msgs = [...messages];
    let msg_parent_index = msgs.findIndex(x => x.id === parent_id);

    if (msg_parent_index > -1){
      let msg_parent_reactions = [...msgs[msg_parent_index].reactions];
      let existingReactionIndex = msg_parent_reactions.findIndex(x => x.id === reaction.id);

      if (existingReactionIndex !== -1) {
        msg_parent_reactions[existingReactionIndex] = reaction;
      } else {
        msg_parent_reactions.push(reaction);
      }

      msgs[msg_parent_index] = { ...msgs[msg_parent_index], reactions: msg_parent_reactions };
      setMessages(msgs)
    }
  }

  const updateMessage = (message) => {
    setMessages(prevState => {
      const updatedMessages = prevState.map(oldMessage => {
        if (oldMessage.id === message.id) {
          return {
            ...oldMessage,
            ...message
          };
        }
        return oldMessage;
      });
      return updatedMessages;
    });
  }

  const deleteMessageReaction = (message) => {
    if (message.parent === null){
      return null;
    }
    let parent_id = message.parent.id;

    let msgs = [...messages];
    let msg_parent_index = msgs.findIndex(x => x.id === parent_id);

    if (msg_parent_index > -1){
      let msg_parent_reactions = msgs[msg_parent_index].reactions;

      msgs[msg_parent_index].reactions = msg_parent_reactions.filter(x => x.id !== message.id);
      setMessages(msgs)
    }
  }

  const newMessageReaction = (message) => {
    if (message.parent === null){
      return null;
    }
    let parent_id = message.parent.id;

    let reaction = {
      'id': message.id,
      'client_id': message.client_id,
      'type': message.type,
      'emoji': message.emoji,
    }

    let msgs = [...messages];
    let msg_parent_index = msgs.findIndex(x => x.id === parent_id);

    if (msg_parent_index > -1){
      let msg_parent_reactions = [...msgs[msg_parent_index].reactions];
      let existingReactionIndex = msg_parent_reactions.findIndex(x => x.id === reaction.id);

      if (existingReactionIndex === -1) {
        msg_parent_reactions.push(reaction);
        msgs[msg_parent_index] = {...msgs[msg_parent_index], reactions: msg_parent_reactions};
        setMessages(msgs)
      }
    }
  }

  const updateExpiredNoteReminders = (notes) => {
    const dateNow = DateTime.now().toFormat('dd/MM/yyyy');
    let reminders_expired = notes.filter(x => {
      let noteReminderDate = DateTime.fromISO(x.note_reminder.reminder_date);
      if (noteReminderDate.invalid){
        noteReminderDate = DateTime.fromFormat(x.note_reminder.reminder_date, "yyyy-MM-dd HH:mm:ss" );
      }
      return noteReminderDate.toFormat('dd/MM/yyyy') === dateNow
    });
    updateExpirationNotes(reminders_expired.length);
  }

  const clearMessages = (messages) => {
    let messagesToClean = [...messages]
    const cleanedMessages = messagesToClean.filter((msg) => {
      if(msg.id){
        return msg
      }
    })
    return cleanedMessages;
  }

  const checkIfMessageExist = (message, messages) => {
    const currentMessages = [...messages];
    let existMessage = false;
    currentMessages.forEach((msg) => {
      if(msg.type === 'sent' && msg.conversationId){
        if ((!msg.read)) msg.read = message.read
        if ((!msg.sent)) msg.sent = message.sent
        if ((!msg.delivered)) msg.delivered = message.delivered
        if(msg.body === message.emoji && msg.recipient === message.recipient){
          existMessage=true;
        }
      }
    })
    return existMessage;
  }

  const getMessages = (message_page) => {
    apiService.getResources({
        url: '/messages',
        filters: [
            {"name": "client_id", "op": "equals", "val": props.client.id}
        ],
        order_by: [{"field": "id", "direction": "desc"}],
        results_per_page: messagesPerPage,
        page: message_page
    }).then(response => {
        let response_data = response.data.objects;
        setTotalPages(response.data.total_pages);
        message_page < response.data.total_pages ? setHasMoreMessages(true) : setHasMoreMessages(false);
        setMessages([...messages, ...response_data]);
        setLoadingMessages(false);
    });
  };


  const getClientVariables = () => {
    apiService.getResources({
      url: '/clients/' + props.client.id  + '/variables',
    }).then(response => {
        let vars = response.data.objects;
        setVars(vars);
    });
  };

  const getTemplate = (templateName) => {
    let foundTemplate = templates.templates.filter((template) => {
      return (template.title === templateName)
    })
    if(foundTemplate.length > 0){
      return foundTemplate[0].body
    }else {
      setTemplates((prevState) => {
        return {
          ...prevState,
          loading: true,
        }
      });
      apiService.getResources({
        url: '/templates',
        filters: [{
          name: 'title',
          op: 'eq',
          val: templateName
        }]
      }).then(response => {

          if(response.data.objects.length > 0){
            foundTemplate = response.data.objects[0];
            setTemplates((prevState) => {
              return {
                templates: [
                  ...prevState.templates,
                  foundTemplate
                ]
              }
            });
            setText(replaceUserVars(foundTemplate.body));
          }
          setTemplates((prevState) => {
            return {
              ...prevState,
              loading: false,
            }
          });
      }).catch((error) => {
        setTemplates((prevState) => {
          return {
            ...prevState,
            loading: false,
          }
        });
        return error;
      });
    }
  }

  const addTemplates = (templateList) => {
    const templateIds = templates.templates.map((template) => template.id)
    const templatesToAdd = templateList.filter((template) => !templateIds.includes(template.id));
    setTemplates((prevState) => {
      return {
        ...prevState,
        templates: [
          ...prevState.templates,
          ...templatesToAdd
        ]
      };
    });
  }

  const updateTemplate = (templateToUpdate) => {
    const updatedTemplates = templates.templates.map((template) => {
      if(template.id === templateToUpdate.id){
        return templateToUpdate
      }
      return template
    });

    setTemplates((prevState) => {
      return {
        ...prevState,
        templates: [
          ...updatedTemplates
        ]
      };
    });
  }

  const createTemplate = (newTemplate) => {
    setTemplates((prevState) => {
      return {
        ...prevState,
        templates: [
          ...prevState.templates,
          newTemplate
        ]
      };
    });
  }

  const deleteTemplate = (removedTemplate) => {

    const updatedTemplates = templates.templates.filter((template) => template.id !== removedTemplate.id)
    setTemplates((prevState) => {
      return {
        ...prevState,
        templates: [
          ...updatedTemplates
        ]
      };
    });
  }

  const templateError = (message) => {
    toast.current.show({severity: 'error', summary: 'Plantillas', detail: message});
  }

  const assignConversation = (data) => {
    const params = {...data}
    return apiService
    .postResource({
        ...params,
        url: '/conversations/' + props.client.id + '/assign',
    })
  }
 
  const sendMessageSubmit = (event) => {
    event.preventDefault();
    if (onFiles) {
      files.map((element)=> {
        sentFile(element);
      })
      setOnFiles(false);
    }
    sendMessage();
  }

  const addMessageLoading = () => {
    let params = {};
    const token = localStorage.getItem('token');
    const decoded_jwt = jwtDecode(token);
    const dateNow = DateTime.now().setZone('America/Argentina/Buenos_Aires').toFormat("yyyy-MM-dd'T'HH:mm:ss");
    let text = 'Cargado...'

    params['recipient'] = contactSelected.value;
    params['country_prefix'] = contactSelected.country_prefix;
    params['client_id'] = parseInt(props.client.id);
    params['channel_id'] = WHATSAPP;
    params['user_id'] = decoded_jwt.user_claims.user_id
    params['message_type'] = messageType.name;
    params['message_type_data'] = messageType.value;

    let user = {
      user: {
        username: localStorage.getItem('username')
      }
    }

    let potentialMessage = {...params, ...user, ...mimetype, body: text, emoji: text, type: 'sent', created: dateNow,
    conversationId: params['client_id'] + moment().format('YYYYMMDD'), reactions: []}
    addNewMessage(potentialMessage, false)
  }

  const removeMessageLoading = () => {
    let message = [...messages]
    let params = {};
    const token = localStorage.getItem('token');
    const decoded_jwt = jwtDecode(token);
    let text = 'Cargado...'

    params['recipient'] = contactSelected.value;
    params['country_prefix'] = contactSelected.country_prefix;
    params['client_id'] = parseInt(props.client.id);
    params['channel_id'] = WHATSAPP;
    params['user_id'] = decoded_jwt.user_claims.user_id
    params['message_type'] = messageType.name;
    params['message_type_data'] = messageType.value;

    let user = {
      user: {
        username: localStorage.getItem('username')
      }
    }

    let potentialMessage = {...params, ...user, ...mimetype, body: text, emoji: text, type: 'sent',
    conversationId: params['client_id'] + moment().format('YYYYMMDD'), reactions: []}
    message.filter(element => element !== potentialMessage);
    setMessages(message);
  }

  const sendMessage = () => {
    let params = {};
    const token = localStorage.getItem('token');
    const decoded_jwt = jwtDecode(token);
    const dateNow = DateTime.now().setZone('America/Argentina/Buenos_Aires').toFormat("yyyy-MM-dd'T'HH:mm:ss");

    params['recipient'] = contactSelected.value;
    params['country_prefix'] = contactSelected.country_prefix;
    params['client_id'] = parseInt(props.client.id);
    params['channel_id'] = WHATSAPP;
    params['user_id'] = decoded_jwt.user_claims.user_id
    params['message_type'] = messageType.name;
    params['message_type_data'] = messageType.value;
    if (message_context.messageContext !== null){
      params['message_context'] = message_context.messageContext.id;
    }

    let user = {
      user: {
        username: localStorage.getItem('username')
      }
    }
    let fields = {
      url: '/messages/single',
      data: params,
    }

    let mimetype = {
      mime_type: messageType?.value?.mimetype
    };

    let captions = undefined;

    if (messageType?.value?.filename) {
      captions = {
        filename: messageType?.value?.filename
      }
      captions = JSON.stringify(captions);
    }

    let potentialMessage = {...params, ...user, ...mimetype, body: text, emoji: text, type: 'sent', created: dateNow, captions: captions, message_type: messageType,
      conversationId: params['client_id'] + moment().format('YYYYMMDD'), reactions: []}

    if (message_context.messageContext !== null){
      potentialMessage['parent'] = message_context.messageContext
    }
    if (whatsappTemplateHeaderConfig){
      potentialMessage['body'] = whatsappTemplateHeaderConfig.url
      potentialMessage['captions'] = JSON.stringify({'caption': text, 'filename': whatsappTemplateHeaderConfig.file})
      potentialMessage['mime_type'] = whatsappTemplateHeaderConfig.mimetype
    }
    if (messageType?.value){
      if (!['note', 'note_reminder', 'reaction', 'forward', 'scheduled_message', 'vcard'].includes(messageType.name)) {
        addNewMessage(potentialMessage, false);
      }
    }

    setText('')

    setMessageType((prevState) => {
      return {
        value: null,
        name: 'chat'
      }
    });

    message_context.updateMessageContext(null);
    setTemplateWhatsappDialog({
      value: '',
      template_uuid: '',
      title: ''
    });

    if (message_context.forward){
      message_context.updateSelectedForwardMessages([]);
      message_context.updateForward(false);
      message_context.updateSelectedForwardContact(null);
    }

    if(messageType?.value){
      apiService.postResource(fields).then(response => {
          params['type'] = 'sent';
          params['created'] = dateNow;
          params['id'] = response.data.id;
      }).catch(error => {
        setMessages(clearMessages([...messages]))
        let response = error?.response

        if (response?.status !== undefined) {

          if (!response.status) {
            let summary = 'Error de conexión';
            let detail = 'Por favor verificar tu conexión a internet';

            if (response?.data?.message?.error !== undefined){
              summary = 'Mensaje no enviado';
              detail = response.data.message.error;
            }

            toast.current.show({severity: 'error', summary: summary, detail: detail, sticky: true});

          } else if (response.status === 400){

            let summary = 'Error';
            let detail = response?.data?.message?.error === undefined ? '' : response.data.message.error;

            if (typeof detail === 'object'){

              if (response?.data?.message?.error?.message) {
                detail = response?.data?.message?.error?.message;

              } else {
                detail = response?.data?.message?.error?.error_data?.details;
              }

            }

            detail = response?.data?.message?.message_type_data?.reminder_date === undefined ? detail :
                response.data.message.message_type_data.reminder_date;

            toast.current.show({severity: 'error', summary: summary, detail: detail, sticky: true});

          } else {

            if (response?.data?.message?.error !== undefined){

              if (response?.data?.message?.error?.message) {
                toast.current.show({severity: 'error', summary: 'Mensaje no enviado', detail: response?.data?.message?.error?.message});

              } else {
                toast.current.show({severity: 'error', summary: 'Mensaje no enviado', detail: response?.data?.message?.error});
              }

            } else {
              toast.current.show({severity: 'error', summary: 'Error de conexión', detail: 'Notificar al administrador del inconveniente'});
            }

          }
        } else {

          if (error?.response?.status === 500){
            toast.current.show({severity: 'error', summary: 'Error de conexión'});
          }

          if (error?.response?.status === 404){
            toast.current.show({severity: 'error', summary: 'Mensaje no enviado', detail: error.response.data.message.error});
          }

        }
      });
    }

    setWhatsappTemplateHeaderConfig(null);
    setShowPicker(false);
    setMimetype('chat');
  };

  const sendForwardMessage = () => {
    let params = {};
    const token = localStorage.getItem('token');
    const decoded_jwt = jwtDecode(token);

    params['recipient'] = contactSelected.value;
    params['country_prefix'] = contactSelected.country_prefix;
    params['client_id'] = parseInt(props.client.id);
    params['channel_id'] = WHATSAPP;
    params['user_id'] = decoded_jwt.user_claims.user_id
    params['message_type'] = messageType.name;
    params['message_type_data'] = messageType.value;
    if (message_context.messageContext !== null){
      params['message_context'] = message_context.messageContext.id;
    }

    let fields = {
      url: '/messages/forward',
      data: params,
    }

    setText('')

    setMessageType((prevState) => {
      return {
        value: null,
        name: 'chat'
      }
    });
    message_context.updateMessageContext(null);
    message_context.updateSelectedForwardMessages([]);
    message_context.updateForward(false);
    message_context.updateSelectedForwardContact(null);

    apiService.postResource(fields).then(response => {
      params['type'] = 'sent';
      params['created'] = DateTime.now().setZone('America/Argentina/Buenos_Aires').toFormat("yyyy-MM-dd'T'HH:mm:ss")
      params['id'] = response.data.id;
    });

    setMimetype('chat');
  };

  const searchVarValueByName = (varName) => {
    const foundVar = vars.filter((variable) => {
      return varName === variable.name;
    })
    if(foundVar.length > 0 && foundVar[0]['value'] !== undefined){
      let value = foundVar[0]['value'];
      if(['{ClientTotalAmount}', '{DebtorTotalDebt}', '{DebtorTotalPaid}', '{DebtorExpiredDebt}'].includes(varName)){
        value = formatMoney(foundVar[0]['value'],2, ',', '.','$');
      }
      return value;
    }
    return false
  }

  function replaceUserVars(text) {
    const regex = new RegExp(vars.map((variable) => variable.name).join('|'), 'g');
    return text.replace(regex, (m, $1) => searchVarValueByName(m) || m);
  }

  function replaceTemplates(text) {
    const regex = new RegExp('{{.*}}', 'g');
    return text.replace(regex, (m, $1) => getTemplate(m.replace(/{|}/g, '')) || m);
  }

  const onChangeBodyTextHandler = (value) => {
    let textChanged = replaceUserVars(replaceTemplates(value));
    setText(textChanged);

    setMessageType((prevState) => {
      let prevMessageType = prevState.name;
      if (prevMessageType !== 'note' && prevMessageType !== 'note_reminder'){
        prevMessageType = 'chat';
      }

      let newMessageType = {
        name: prevMessageType,
        value: {
          'body': textChanged,
        }
      }
      if (prevMessageType === 'note_reminder') {
        newMessageType = {
          name: prevMessageType,
          value: {
            ...prevState.value,
            'body': textChanged,
          }
        }
      }
      return newMessageType
    });

  }

  const handleTemplateSelection = (value) => {
    let content = value.content;
    let whatsapp_template = value.whatsapp_template;
    let whatsapp_template_header_config = value.template_header_config;
    let textChanged = replaceUserVars(replaceTemplates(content));
    setText(textChanged);

    if (whatsapp_template){
      if (whatsapp_template_header_config){
        setWhatsappTemplateHeaderConfig(whatsapp_template_header_config)
        setMimetype(convertMimetypeType(whatsapp_template_header_config.mimetype))
      }
      setMessageType(() => {
        return {
          name: 'template',
          value: {
            'template_uuid': value.template_uuid
          }
        }
      });
    } else {
      if (whatsapp_template_header_config === null || whatsapp_template_header_config === undefined){
        setMessageType(() => {
          return {
            name: 'chat',
            value: {
              'body': textChanged
            }
          }
        });
      } else {
        let message_type_template = convertMimetypeType(value.template_header_config.mimetype)
        whatsapp_template_header_config['caption'] = textChanged
        setText(textChanged)
        setWhatsappTemplateHeaderConfig(whatsapp_template_header_config)
        setMessageType(() => {
          return {
            name: message_type_template,
            value: whatsapp_template_header_config
          }
        });
        setMimetype(message_type_template)
      }
    }

  }

  const addMessageMedia = (message_type, media) => {
    if (message_type === 'audio' || message_type === 'document' || message_type === 'image' || message_type === 'video'){
        setMimetype(message_type);
        setText(media.url);
        setMessageType(() => {
        return {
          name: message_type,
          value: {
            'url': media.url,
            'id': media.id,
            'mimetype': media.mimetype,
            'filename': media.filename,
            'size': media.size
          }
        }
      });
    }
    else if (message_type === 'note'){
      setMessageType((prevState) => {
        return {
          name: message_type,
          value: {
            'body': prevState.value?.body === undefined ? '' : prevState.value.body
          }
        }
      })
    }
    else if (message_type === 'note_reminder'){
      setMessageType((prevState) => {
        return {
          name: message_type,
          value: {
            'body': '',
            'reminder_date': null
          }
        }
      })
    }
    else if (message_type === 'scheduled_message'){
      setMessageType((prevState) => {
        return {
          name: message_type,
          value: {
            'template_uuid': '',
            'reminder_date': null
          }
        }
      })
    }
  }

  const messageMediaError = (error, summary) => {
    toast.current.show({severity: 'error', summary: summary, detail: error});
  }

  const contactListDropDown = (
    <BasicDropDown key='contactList' button={<FontAwesomeIcon icon={"caret-down"} color={'#19418BE5'} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome btn-caret-down"}/>}>
      {contacts.map((contact, index) => {
        return (<div key={index} onClick={() => setContactSelected(contact)}>{contact.value}</div>)
      })}
    </BasicDropDown>
  );

  const updateChatbotSubscriptionHandler = (chatbot_subscription) => {
    let formData = {
      'client_id': currentClient.id,
      'chatbot_subscription': chatbot_subscription,
    }

    apiService.postResource({
      url: '/clients/chatbot_subscription',
      data: formData
    }).then(response => {
        toast.current.show({severity: 'success', summary: 'Bot', detail: response.data.message, sticky: true});
        setCurrentClient((prevState) => {
          return {
            ...prevState,
            chatbot_subscription: chatbot_subscription
          }
        });
    }).catch(error => {
      toast.current.show({severity: 'error', summary: 'Bot', detail: error.response.data.message, sticky: true});
    });
  }

  const chatbotClientCheckbox = chatbotEnabled && currentClient ? (
    <button data-tip data-for="chatBot" className="p-link" id="chatbot_subscription"
            onClick={(e) => updateChatbotSubscriptionHandler(!currentClient.chatbot_subscription)}>
      <ToolbarButton key="chatbot" className={"fa-layers fa-fw btn-actions btn-actions-default toolbar-button btn-fontawesome"}
                     icon={"fa-regular fa-robot"}
                     style={{width: '2.3em', height: '2.3em'}}
      >
          {!currentClient.chatbot_subscription ? <FontAwesomeIcon icon={'fa-sharp fa-regular fa-slash-back'} color={'#00000'} size={'2x'} transform={'right-2 down-1'} /> : null}
      </ToolbarButton>
    </button>
  ) : null;

  let selectedUser = '';
  if(currentClient){
    if(currentClient.conversation){
      selectedUser = currentClient.conversation.user;
    }
  }

  const hideUserGroupList = (event) => {
    if (showSelectUser || showSelectGroup){
      setShowSelectUser(false);
      setShowSelectGroup(false);
      setUserListDropdownState(false);
    } else{
      setUserListDropdownState(true);
      refUserGroupMenu.current.toggle(event);
    }
  }

  const userGroupListButton = (
    <button data-tip data-for="userGroupList" className="p-link" onClick={hideUserGroupList}>
      <FontAwesomeIcon icon={(showSelectUser || showSelectGroup) ? "times" : "fa-regular fa-users"} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"}/>
    </button>
  );

  const onUserGroupChangeHandler = (data) => {
    let params = {
      data: {}
    }

    if (data.username !== undefined){
      params['data']['user_id'] = data.id
    } else {
      params['data']['group_id'] = data.id
    }

    params['data']['recipient'] = props.client.contacts[0].value;
    params['data']['country_prefix'] = props.client.contacts[0].country_prefix;
    params['data']['channel_id'] = WHATSAPP;

    assignConversation(params)
    .then((response) => {
      let group_filter_tmp = groups.filter(x => x.id === response.data.group_id)[0];

      let group_tmp = null;
      if (group_filter_tmp !== undefined){
        group_tmp = {
          id: group_filter_tmp.id,
          name: group_filter_tmp.name
        }
      }

      let user_tmp = users.filter(x => x.id === response.data.user_id)[0];

      setCurrentClient({...currentClient, conversation: {...currentClient.conversation, user: user_tmp, group: group_tmp}})
    })
    setShowSelectGroup(false);
    setShowSelectUser(false);
  }

  const userGroupListDropDown = (
    <div>
      <Menu model={itemUserGroupMenu} popup ref={refUserGroupMenu} id="popup_menu" />
    </div>
  );

  const userListDropDown = showSelectUser ? (
    <Dropdown className="multiselect-custom-user-group-list" value={selectedUsersOption} optionLabel="username" options={users} filter={!isMobile()}
                 onChange={(e) => onUserGroupChangeHandler(e.value)} placeholder="Usuarios"
                 resetFilterOnHide={true}
    />
  ) : null;

  const groupListDropDown = showSelectGroup ? (
    <Dropdown className="multiselect-custom-user-group-list" value={selectedGroupsOption} optionLabel="name" options={groups} filter={!isMobile()}
                 onChange={(e) => onUserGroupChangeHandler(e.value)} placeholder="Equipos"
                 resetFilterOnHide={true}
    />
  ) : null;

  const userGroupList = (
       <Fragment key={'userGroupList'}>
          {userGroupListButton}
         <div className='userGroupList'>
            {userGroupListDropDown}
            {userListDropDown}
            {groupListDropDown}
          </div>
       </Fragment>
  );

  const noteListButton = (
    <button data-tip data-for="noteList" className="p-link" onClick={(e) => setActiveNotes(!activeNotes)}>
      <FontAwesomeIcon icon={!activeNotes ? "fa-regular fa-note-sticky" : 'times'} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"}/>
    </button>
);

  const noteList = (
      <Fragment key={'noteList'}>
        {noteListButton}
      </Fragment>
  );

  const customTagsListButton = (
      <button data-tip data-for="customTagsList" className="p-link" onClick={(e) => setCustomTagsListDropdownState(!customTagsListDropdownState)}>
        <FontAwesomeIcon icon={!customTagsListDropdownState ? "fa-regular fa-tag" : 'times'} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"}/>
      </button>
  );

  const selectItemTemplate = value => {
    if (value) {
      return (
          <div className="my-multiselected-item-token">
            <span>{value.name}</span>
          </div>
      );
    } else {
      return <span className="my-multiselected-empty-token">Etiquetar</span>;
    }
  };

  const customTagsListDropDown = customTagsListDropdownState ? (
        <MultiSelect className="multiselect-custom-tags-list" value={customTagsSelected} optionLabel="name" options={customTagsOptions} filter={!isMobile()}
                     onChange={(e) => updateClientCustomTags(e.value)} placeholder="Etiquetar"
                     selectedItemTemplate={selectItemTemplate}
                     resetFilterOnHide={true}
                     maxSelectedLabels={0} selectedItemsLabel={'{0} etiquetas'}
        />
  ) : null;


  const customTagsList = (
      <Fragment key={'customTagsList'}>
        {customTagsListButton}
        <div className='customTagsList'>
          {customTagsListDropDown}
        </div>
      </Fragment>
  );

  const clientEditButton = currentClient ? (
    <a key={'clientEditButton'} data-tip data-for="clientEdit" onClick={() => hideShowClientView()}>
      <FontAwesomeIcon icon={"fa-regular fa-pen-to-square"} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"}/>
    </a>
  ) : '';

  let itemsForEditBotNotesMenu = [
    {
      label:
      <i>
          <FontAwesomeIcon icon={"fa-regular fa-pen-to-square"} style={{width: '1.3em', height: '1.3em'}}/>
          <span className="menu-font-awesome-icon"> Editar Cliente </span>
      </i>,
      command: () => { hideShowClientView(); },
    },
    {
      label:
      <i>
          <FontAwesomeIcon icon={"fa-regular fa-robot"} style={{width: '1.3em', height: '1.3em'}}/>
          <span className="menu-font-awesome-icon"> {!currentClient?.chatbot_subscription ? 'Activar bot' : 'Desactivar bot'} </span>
      </i>,
      command: () => { updateChatbotSubscriptionHandler(!currentClient.chatbot_subscription); },
    },
    {
      label:
      <i>
          <FontAwesomeIcon icon={"fa-regular fa-note-sticky"} style={{width: '1.3em', height: '1.3em'}}/>
          <span className="menu-font-awesome-icon"> Notas </span>
      </i>,
      command: () => { setActiveNotes(!activeNotes); },
    }
  ]

  const editBotNotesMenu = (
    <Menu model={itemsForEditBotNotesMenu} popup={true} ref={refEditBotNotesMenu}  />
  )

  const hideShowButton = (
    <div key={'hideShowButton'}>
      <a className="p-link" onClick={(event) => actionHideShowButton(event)}>
        <FontAwesomeIcon icon={"ellipsis-vertical"} color={'#0436A0'} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"}/>
      </a>
    </div>
  );

  const hideShowClientView = () => {
    setShowClientView(showClientView ? false : true);
  }

  const actionHideShowButton = (event) => {
    event.preventDefault();
    refEditBotNotesMenu.current.toggle(event);
  }

  const actionShowPicket = (event) => {
    event.preventDefault();
    if (showPicker){
      setShowPicker(false);
    }else{
      setShowPicker(true);
      if (showTemplate){
        setShowTemplate(false);
        setText('');
      }
    }
  }

  const backShowButton = isMobile() && (
    <div key={'backButton'}>
      <button className="p-link" onClick={() => props.onSelectClientHandler(null)}>
        <FontAwesomeIcon icon={"arrow-left"} color={'#000000'} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome btn-back"}/>
      </button>
    </div>
  );

  const emojisButton = show => {
    return (
      <Fragment>
        {!onFiles && (
          <button className="p-link" data-tip data-for="emojisButton" onClick={actionShowPicket}>
            <FontAwesomeIcon icon={showPicker ? "keyboard" : "fa-regular fa-face-smile"} className={'btnEmoji botonTransp'}/>
          </button>)}
        {!isMobile() &&
          <ReactTooltip id="emojisButton" place="left" effect="solid">
            {showPicker ? 'Teclado' : 'Emojis'}
          </ReactTooltip>
        }
      </Fragment>
    )
  };

  const sendContacts = () => {
    message_context.updateSelectedContactsModal(true);
  }
  const moreOptionButton = (
    <Fragment>
      <div style={{ display: 'flex', bottom: isMobile() ? startAudio ? '' : showPicker ? '' : '50px' : ''}}>
        {!startAudio && (
          <Fragment>
              {!showPicker && (
                !onFiles && (
                  <MessageMedia
                  sendContacts={sendContacts}
                  setShowTemplates={setShowTemplates}
                  onMessageType={setMessageType}
                  onMessageMediaCreate={addMessageMedia}
                  onMessageMediaError={messageMediaError}
                  messageType={messageType.name}
                  setShowModal={setShowModal}
                  showModal={showModal}
                  />
              ))}
              {!showPicker && (
                !onFiles && (
                <TemplateOverlay
                  showTemplates={showTemplates}
                  setShowTemplates={setShowTemplates}
                  onTemplateSelection={handleTemplateSelection}
                  onTemplateUpdate={updateTemplate}
                  onGetTemplates={addTemplates}
                  onTemplateDelete={deleteTemplate}
                  onTemplateError={templateError}
                  onTemplateCreate={createTemplate}/>
              ))}
        </Fragment>)}
      </div>
    </Fragment>
  );

  let composeLeftItems = [
    <Fragment key={'composeRightsItems'}>
      {emojisButton(false)}
      {moreOptionButton}
    </Fragment>
  ]

  const sendButton = (
    <Fragment>
      {!startAudio && (
          !(text === '' ? onFiles ? false : true : !showTemplate ? false : true) && (
            <button className="p-link" onClick={(event)=>{sendMessageSubmit(event)}} disabled={text === '' ? onFiles ? false : true : !showTemplate ? false : true}>
              <FontAwesomeIcon icon='fa-regular fa-paper-plane' className={text === '' ? onFiles ? 'btn botonSend' : 'btn botonF2' : !showTemplate ? 'btn botonTransp' : 'btn botonF2'}/>
            </button>
          )
      )}
    </Fragment>
  );

  const audioButton = (
    <Fragment>
      {!showPicker && (
            !onFiles && (
              text === '' && (
                <AudioRecord
                startAudio={setStartAudio}
                onMessageType={setMessageType}
                onMessageMediaCreate={addMessageMedia}
                onMessageMediaError={messageMediaError}
                addMessageLoading={addMessageLoading}
                removeMessageLoading={removeMessageLoading}
                />
              )
            )
          )
        }
    </Fragment>
  );

  let composeRightItems = [
    <Fragment key={'composeRightsItems'}>
      {sendButton}
      {audioButton}
      </Fragment>
  ];

  const openForwardContacts = () => {
    message_context.updateForwardContactModal(true);
  }

  let composeRightForwardItems = [
    <div>
      <button className="p-link" onClick={(event) => {openForwardContacts(event)}} disabled={message_context.selectedForwardMessages.length === 0}>
        <FontAwesomeIcon icon='fa-regular fa-share' className={message_context.selectedForwardMessages.length === 0 ? 'btn-forward  btn-forward-disabled' : 'btn-forward'}/>
      </button>
    </div>
  ];

  const onNoteCancelHandler = () => {
    setMimetype('chat')
    setMessageType((prevState) => {
      let newBody = prevState.value
      if (prevState.name === 'note_reminder'){
        newBody = prevState.value
        delete newBody['reminder_date']
      }
      if (prevState.name === 'scheduled_message'){
        newBody = prevState.value
        delete newBody['reminder_date']
        delete newBody['template_uuid']
      }

      return {
        value: newBody,
        name: 'chat'
      }
    });
    if (templateWhatsappDialog.template_uuid !== ''){
      setTemplateWhatsappDialog({
        value: '',
        template_uuid: '',
        title: ''
      });
    }

  }

  let buttonSent = [
    <Fragment>
      {!(text === '') && (
        <button className="p-link" onClick={(event)=>{sendMessageSubmit(event)}} disabled={text === ''}>
          <FontAwesomeIcon icon='fa-regular fa-paper-plane' className={text === '' ? 'btn botonF2' : 'btn botonSend'}/>
        </button>
      )}
    </Fragment>
  ];

  let noteCancel = [
    <Fragment>
      <i className="overlay-button" onClick={() => onNoteCancelHandler()}>
            <FontAwesomeIcon icon={"fa-regular fa-xmark"} className={"btn botonCancel"} />
      </i>
    </Fragment>
  ];

  let leftItems = '';

  if(!loadingMessages){
    leftItems = [contactListDropDown];
  }
  const onEmojiClick = (event, emojiObject) => {
    event.preventDefault();
    let inputElement = composeRef.current;

    const start = inputElement.selectionStart;
    const end = inputElement.selectionEnd;
    const textBefore = inputElement.value.substring(0, start);
    const textAfter = inputElement.value.substring(end);
    const newText = textBefore + emojiObject.emoji + textAfter;
    const newCursorPosition = start + emojiObject.emoji.length;

    setText(newText);
    setMessageType(prevInput => ({
      name: prevInput.name,
      value: {
        'body': newText
      }
    }));

    cursorPositionComposeHandler(newCursorPosition);

    setTimeout(() => {
      if (composeRef.current) {
        composeRef.current.selectionStart = composeRef.current.selectionEnd = newCursorPosition;
      }
    });
  };

  const nextPageScroll = () => {
    setTimeout(() => lastMessageRef.current?.scrollIntoView(false), 500);
  }

  const getMoreMessages = async (param_page) => {
    setScrollAtBottom(true);
    nextPageScroll();
    if (!loadingMessages){
      setLoadingMessages(true)
      if (hasMoreMessages){
        let new_page = param_page + 1;
        setPage(new_page)
        getMessages(new_page);
        return new_page
      }
    }
  }

  const  laberReactTooltip = (id, placeholder) => {
    return (
      <div>
        {!isMobile() &&
          <ReactTooltip id={id} place="bottom" effect="solid">
            {placeholder}
          </ReactTooltip>
        }
      </div>
    )
  }
  useEffect(() => {
    messages.map((message) => {
      if ((message?.recipient === contactSelected?.value) || (message?.sender === contactSelected?.value)){
        setLoadingConversation(true);
      }else{
        setLoadingConversation(false);
      }
      return message;
    });
  },[contactSelected]);

  const handleBottonScroll = () => {
    const element = scrollParentRef.current;
    const scrollAtBottom = element.scrollHeight - element.scrollTop <= element.clientHeight + 1;
    setScrollAtBottom(scrollAtBottom);
  };

  const labelButton = (
    <div>
      {currentClient ? laberReactTooltip('clientShow','Vista cliente') : ''}
      {currentClient ? laberReactTooltip('clientEdit','Editar cliente'): ''}
      {laberReactTooltip('userGroupList','Asignar usuario/grupo')}
      {laberReactTooltip('customTagsList','Asignar etiquetas')}
      {currentClient ? laberReactTooltip('noteList','Notas') : ''}
      {currentClient?.chatbot_subscription ? laberReactTooltip('chatBot','Bot Activado') : laberReactTooltip('chatBot','Bot Desactivado')}
    </div>
  )
  const conversationsView =  !loadingMessages && currentClient?.id === props.client.id ?
      (<ConversationView scrollAtBottom={scrollAtBottom} onStar={onStar} key={contactSelected?.value} client={props.client} currentClient={currentClient} newMessage={newMessage} setNewMessage={setNewMessage} messages={messages} page={page}
                         lastMessageRef={lastMessageRef} messagesPerPage={messagesPerPage} contactSelected={contactSelected} />) : null;

  const conversationView = contacts && !loadingMessages && currentClient?.id === props.client.id ? (
    <Fragment key={'conversationView'}>
      {(!onDrag && !onFiles) && (
        <div className="scrollable" ref={scrollParentRef} onScroll={handleBottonScroll}>
          <div className="message-list-container" ref={messageListComponent}>
            <InfiniteScroll
              pageStart={page}
              loadMore={(paginate) => getMoreMessages(page)}
              isReverse={true}
              hasMore={hasMoreMessages}
              threshold={1}
              initialLoad={false}
              loader={loadingConversation ? <div className="center" key={0}> <ProgressBar mode="indeterminate"/> </div> : <div className="center"> <p>No se encontró ningún mensaje.</p> </div>}
              useWindow={false}
              getScrollParent={() => scrollParentRef.current}
            >
              {conversationsView}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </Fragment>) : loadingConversation ? <div className="scrollable message-list-container">  <MessageSkeleton count={5}/> </div> : null;

  let toolbarClassName = 'toolbar messenger-toolbar';
  if (activeDropdown){
    toolbarClassName = 'toolbar messenger-toolbar active';
  }

  const onNoteReminderCalendarHandler = (value) => {
    setMessageType((prevState) => {
      return {
        name: prevState.name === 'note_reminder' ? 'note_reminder' : 'chat',
        value: {
          'body': prevState.value?.body === undefined ? '' : prevState.value.body,
          'reminder_date': value
        }
      }
    });
  }

  const onNoteReminderCalendar = () => {
    return (
        <MessageNoteCalendar
            onChange={onNoteReminderCalendarHandler}
            messageType={messageType}
        />
    )
  }

  const onScheduledMessageCalendarHandler = (value) => {
    setMessageType((prevState) => {
      return {
        name: prevState.name === 'scheduled_message' ? 'scheduled_message' : 'chat',
        value: {
          'template_uuid': prevState.value?.template_uuid === undefined ? '' : prevState.value.template_uuid,
          'reminder_date': value
        }
      }
    });
  }

  const footerScheduledMessageHandler = (e) => {
    setShowTemplateWhatsappDialog(true)
  }
  const footerScheduledMessage = () => {
    let label = templateWhatsappDialog.title !== '' ? templateWhatsappDialog.title : 'Seleccione Plantilla de Whatsapp'
    return (
      <div className={'footer-scheduled-message'}>
        <div style={{borderBottom: '1px solid #dee2e6', margin: '1.25rem 0', padding: '0 1.125rem'}}></div>
        <button className="p-link" onClick={(e) => footerScheduledMessageHandler(e)}>
          {label}
        </button>
      </div>
    )
  }

  const templateWhatsappGateway = (['DIALOG_360', 'CLOUD-API'].includes(localStorage.getItem('gatewayWaba')))

  const templateWhatsappDialogValues = {
        'url': '',
        'method': 'get',
        'header': 'Plantillas Guardadas',
        'show_footer': false,
        'submit_label': 'Crear',
        'blockScroll': true,
        'maximizable': true,
        'sumaryToast': 'Etiquetas',
        'fields': [
            {
                name: 'notification_whatsapp',
                elementType: 'notificationWhatsappBody',
                elementConfig: {
                    onClose: setShowTemplateWhatsappDialog,
                    updateTemplateValue: setTemplateWhatsappDialog,
                    filter: [{ name: "whatsapp_template", op: "eq", val: templateWhatsappGateway }]
                },
                value: templateWhatsappDialog,
                elementClass: 'col-12'
            }],
        'buttons_rounded': true
    }

  const dialogTemplateWhatsapp = showTemplateWhatsappDialog ? (
        <DialogGeneric visible={showTemplateWhatsappDialog} show_footer={false}
                       setVisible={setShowTemplateWhatsappDialog}
                       closeModal={true} {...templateWhatsappDialogValues}/>
    ) : null

  const onScheduledMessageCalendar = () => {
    return (
        <Fragment key={scheduledMessageButtonsDialog}>
          <MessageNoteCalendar
              onChange={onScheduledMessageCalendarHandler}
              footer={footerScheduledMessage}
              messageType={messageType}
              inline={true}
              datePickerStyle={'datepicker-block'}
          />
          {dialogTemplateWhatsapp}
        </Fragment>
    )
  }

  let render_modal_notes = activeNotes ? <MessageNoteReminder enabled={activeNotes} setEnabled={setActiveNotes}
                                                        isGlobal={false}/> : null;

  let render_note_messages = selectedMessageNote !== null && !globalClients ? <MessagesNoteModal
      selectedMessage={selectedMessageNote} selectedClient={selectedClient} contactSelected={contactSelected}
      activeNotes={activeNotes} setActiveNotesEnabled={setActiveNotes} clientFullName={currentClient}
  /> : null;


  let render_modal_forward = message_context.forwardContactModal ? <MessageForwardModal
      enabled={message_context.forward}
      setEnabled={message_context.updateForward} /> : null;

  let render_modal_contact = message_context.selectedContactsModal ? <ContactSelectorModal
      enabled={message_context.contacts}
      setEnabled={message_context.updateContacts} /> : null;

  let noteReminderButtons = [
    <Fragment key={'noteReminderButtons'}>
      {onNoteReminderCalendar()}
      <i className="overlay-button" onClick={() => onNoteCancelHandler()}>
        <FontAwesomeIcon icon={"fa-regular fa-xmark"} className={"btn botonCancel"}/>
      </i>
    </Fragment>
  ];


  const scheduledMessageContentStyle = {textAlign: 'center', paddingTop: '2rem'}
  const sendScheduledEventDisabled = messageType.name === 'scheduled_message' ? templateWhatsappDialog.template_uuid !== '' && messageType.value.reminder_date !== null ? 'btn-send-message-scheduled' : 'btn-send-message-scheduled-disabled' : ''
  const footerScheduled = (
      <div>
        <i className="overlay-button" onClick={(event) => sendMessageSubmit(event)}>
          <FontAwesomeIcon icon={"check"} className={`btn botonSend ${sendScheduledEventDisabled}`}/>
        </i>
        <i className="overlay-button" onClick={() => onNoteCancelHandler()}>
          <FontAwesomeIcon icon={"fa-regular fa-xmark"} className={"btn botonCancel"}/>
        </i>
      </div>
  )
  let scheduledMessageButtons = [
    <Fragment key={'scheduledMessageButtons'}>
      <Dialog header={'Mensajes Programados'} visible={scheduledMessageButtonsDialog} contentStyle={scheduledMessageContentStyle}
              onHide={() => setScheduledMessageButtonsDialog(!scheduledMessageButtonsDialog)}
              footer={footerScheduled} closable={false}>
        {onScheduledMessageCalendar()}
      </Dialog>
    </Fragment>
  ];

  const rightItemsCompose = () => {
    let items = composeRightItems;
    if (messageType.name === 'note') {
      items = buttonSent
      items.push(noteCancel);
    }
    else if (messageType.name === 'note_reminder') {
      items = buttonSent
      items.push(noteReminderButtons);
    }
    else if (messageType.name === 'scheduled_message'){
      items = buttonSent
      items.push(scheduledMessageButtons);
    }
    else if (message_context.forward){
      items = composeRightForwardItems
    }
    return items
  }

  const onMessageForwardCancelHandler = () => {
    message_context.updateSelectedForwardMessages([]);
    message_context.updateForward(false);
    setMimetype('chat')
    setMessageType((prevInput) => {
      return {
        name: 'chat',
        value: null
      }
    });
  }
  let forwardCancel = [
    <Fragment>
      <i className="overlay-button" onClick={() => onMessageForwardCancelHandler()}>
        <FontAwesomeIcon icon={"fa-regular fa-xmark"} className={"btn botonCancel"} />
      </i>
    </Fragment>
  ];

  const leftItemsCompose = () => {
    let items = null;
    if (messageType.name === 'note') {
      items = emojisButton(true);
    }
    else if (messageType.name === 'note_reminder') {
      items = emojisButton(true);
    }
    else if (messageType.name === 'scheduled_message'){
      items = [];
    }
    else if (message_context.forward){
      items = forwardCancel
    } else {
      items = composeLeftItems;
    }
    return items
  }

  const filePreview = (file) => {
    filesTmp.push(file)
    setFiles(filesTmp);
    setOnFiles(true);
  }

  const sentFile = (file) => {
    addMessageLoading();
    const formData = new FormData()
    formData.append('file_upload', file);
    apiService.postResourceBlob({
        url: '/messages/media',
        data: formData,
        blob: file.type
    }).then(response => {
      if (response.status === 200){
        removeMessageLoading();
        addMessageMedia(convertMimetypeType(response.data.media.mimetype), response.data.media);
      }
    }).catch((error) => {
      removeMessageLoading();
      if (error.response.data?.message?.size !== undefined) toast.current.show({severity: 'error', summary: 'Error en Multimedia ' + file.name, detail: error.response.data?.message?.size});
      if (error.response.data?.message?.mimetype !== undefined) toast.current.show({severity: 'error', summary: 'Error en Multimedia ' + file.name, detail: error.response.data?.message?.mimetype});
    })
  };

  const onPaste = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;
    for (let index in items) {
      const item = items[index];
      if (item.kind === 'file') {
        filePreview(item.getAsFile());
      }
    }
  }

  const dragOverHandler = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    if (ev.dataTransfer.effectAllowed === 'copy' && !showModal) setOnDrag(true); 
  }

  const dragLeave = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    setOnDrag(false);
  }

  const dropHandler = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    if (ev.dataTransfer.items) {
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === 'file') {
          let file = ev.dataTransfer.items[i].getAsFile();
          if (file.size !== 0) filePreview(file);
        }
      }
      removeDragData(ev)
    }
    setOnDrag(false)
  }

  const removeDragData = (ev) => {
    if (ev.dataTransfer.items) {
      ev.dataTransfer.items.clear();
    } else {
      ev.dataTransfer.clearData();
    }
  }

  let viewClientEdit = (
    <Sidebar className='p-sidebar-md' visible={showClientView} position="right" onHide={() => hideShowClientView()}>
      {currentClient && (
        <Fragment>
          <h3>Información de Cliente</h3>
          <ScrollPanel style={{ height: '90vh' }}>
            <ClientFormCusmon id={currentClient.id} client={currentClient} contacts={contacts}/>
          </ScrollPanel>
        </Fragment>
      )}
    </Sidebar>
  );

  let viewClientEditMobile = (
    <Dialog header={'Información de Cliente'} style={{ width: '94vh', height: 'auto' }} visible={showClientView} onHide={() => setShowClientView(false)}>
      {currentClient && (
        <Fragment>
            <ClientFormCusmon id={currentClient.id} client={currentClient} contacts={contacts}/>
        </Fragment>
      )}
    </Dialog>
  );

  let messageContextItem = (
    <MessageContextItem/>
  );

  let viewModal = (
    <div style={{position: 'absolute'}}>
      {render_modal_notes}
      {render_note_messages}
      {render_modal_forward}
      {render_modal_contact}
      {windowDimensions.width <= 1000 ? viewClientEditMobile : viewClientEdit}
    </div>
  )

  let buttonScrollButton = (
    (!scrollAtBottom && !loadingMessages && !showPicker && !showTemplate && !onFiles && !onDrag) && (
      <Avatar onClick={scrollBottom} icon="pi pi-angle-down" className='scroll-botton-button p-overlay-badge' shape="circle" >
        {(scrollAtBottomCount !== 0) && (
          <Badge value={scrollAtBottomCount} />
        )}
      </Avatar>
    )
  )

  let componseInput = (
    (!onDrag && (
      <div className={'compose-element'}>
        <Compose
          messagesDivRef={messageListComponent}
          onChange={onChangeBodyTextHandler}
          leftItems={leftItemsCompose()}
          rightItems={rightItemsCompose()}
          contextItems={messageContextItem}
          onSubmitForm={sendMessageSubmit}
          value={text}
          mimetype={mimetype}
          loading={templates.loading}
          messageType={messageType.name}
          startAudio={!startAudio}
          onFiles={onFiles}
          showTemplate={showTemplate}
          composeRef={composeRef}
        />
      </div>
      ))
  );

  let backgroundImage = (
    <div className='content-imagen'></div>
  )

  let toolbarTop = (
    ((!onDrag) && (
      <Fragment>
        <Toolbar hideShowLeftItems={hideShowLeftItems}
                 title={ (loadingMessages && <ProgressBar mode="indeterminate"/>) || currentClient.name}
                 backButton={backShowButton}
                 leftItems={contacts[0]?.value === undefined ? <BasicDropDown  red={true} button={'Sin Número'}></BasicDropDown> : leftItems}
                 rightItems={windowDimensions.width <= 1000  ? hideShowLeftItems ? [backShowButton, clientEditButton, customTagsList, userGroupList, noteList, chatbotClientCheckbox, hideShowButton] : [ editBotNotesMenu,customTagsList, userGroupList, hideShowButton] :
                [clientEditButton, customTagsList, userGroupList, noteList, chatbotClientCheckbox]}
                className={toolbarClassName}
        />
        {labelButton}
      </Fragment>
    ))
  )

  return (
      <div onPaste={(event) => onPaste(event)} onDragOver={(event) => dragOverHandler(event)} onDrop={(event) => dropHandler(event)} key={currentClient?.id} className='content'>
        <Toast ref={toast}/>
        {backgroundImage}
        {buttonScrollButton}
        {toolbarTop}
        {onDrag && (<div onDrop={(event) => dropHandler(event)} onDragLeave={(event) => dragLeave(event)} className='dropZone'/>)}
        {conversationView}
        {onFiles && (<MessageMediaPreview files={files} setOnFiles={setOnFiles} setFiles={setFiles}></MessageMediaPreview>)}
        {showPicker && (<Picker pickerStyle={{ height: 'auto', width: "auto", zIndex: "0" }} onEmojiClick={onEmojiClick}/>)}
        {showTemplate && (<TemplatesView text={text} onTemplateSelection={handleTemplateSelection} setShowTemplate={setShowTemplate} setText={setText}/>)}
        {componseInput}
        {viewModal}
        {loadingNote || message_context.loadingMessages ? <CustomSpinner status={'Obteniendo mensajes...'} style={{paddingLeft: '22%'}}/> : null}
      </div>
    );
}
