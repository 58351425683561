import React, {Fragment, useEffect, useRef, useState} from "react";
import {Dropdown} from "primereact/dropdown";
import ApiLoan from "../../service/ApiLoanService";
import MoneyField from "../../UI/MoneyField";
import {Button} from "primereact/button";
import { Toast } from 'primereact/toast';

const PayOrderSingle = props => {
    const [gateways, setGateways] = useState(null);
    const [gatewayId, setGatewayId] = useState(null);
    const [loans, setLoans] = useState(null);
    const [loanId, setLoanId] = useState(null);
    const [installments, setInstallments] = useState(null);
    const [installmentsOptions, setInstallmentsOptions] = useState(null);
    const [installmentId, setInstallmentId] = useState(null);
    const [disabledButton, setDisabledButton] = useState(true);


    useEffect(() => {
        if (installmentId !== null){
            if (disabledButton){
                setDisabledButton(false);
            }
        } else {
            setDisabledButton(true);
        }
    }, [installmentId, disabledButton]);

    useEffect(() => {
        let apiService = new ApiLoan();

        apiService.getResources({
            url: '/coelsa/user_gateways'
        }).then(response => {
            const user_gateways_active = response.data.objects.filter((user_gateway) => {
                return user_gateway.active;
            });
            const user_gateways = user_gateways_active.map(user_gateway => {
               return {
                   label: user_gateway.gateway.description,
                   value: user_gateway.gateway.slug
                }
            });

            setGateways(user_gateways);
        }).catch(error => {
            //const message_detail='Hubo un error al buscar la cuotas.'
            //props.history.push({pathname: '/pay_orders', state: {message: message_detail}});
        });

    }, []);
    useEffect(() => {
        let apiService = new ApiLoan();

        apiService.getResources({
            url: '/loans/not/paid',

        }).then(response => {
            const remote_loans = response.data.objects.map(loan => {
                return {
                    label: loan.remote_loan_id,
                    value: loan.id
                }
            });
            setLoans(remote_loans);

        }).catch(error => {
            //const message_detail='Hubo un error al buscar la cuotas.'
            //props.history.push({pathname: '/pay_orders', state: {message: message_detail}});
        });
    }, []);

    useEffect(() => {
        if (loanId !== null){
            getInstallments(loanId);
        } else {
            setInstallmentId(null);
        }
    }, [loanId]);


    const getForm = () => {
        return (
            <div className="col-12 form-group">
                <div className="grid">
                    <div className={"col-12 md-12"}>
                        <Dropdown value={gatewayId} options={gateways}
                                  onChange={(e) => setGatewayId(e.value)}
                                  placeholder="Seleccione una gateway" showClear={true}
                                  filter={true}/>
                    </div>
                    <div className={"col-6 md-6"}>
                        {renderLoan()}

                    </div>
                    <div className="col-6 md-6">
                        {renderInstallment()}
                    </div>
                    <div className={"col-12 md-12"}>
                        {renderDescriptionInstallment()}
                    </div>
                    <div className={"col-12 md-12"}>
                        <Button label="Generar" disabled={false}
                                onClick={generatePayOrder} />
                    </div>

                </div>
            </div>
        )
    };

    const renderLoan = () => {
        let form = null;
        if (gatewayId !== null){
            form = (
                <Dropdown value={loanId} options={loans}
                          onChange={(e) => setLoanId(e.value)}
                          placeholder="Seleccione una operación" showClear={true}
                          filter={true}/>
            );
        }
        return form;
    };
    const renderInstallment = () => {
        let form = null;
        if (loanId !== null){
            form = (
                <Dropdown value={installmentId} options={installmentsOptions}
                      onChange={(e) => setInstallmentId(e.value)}
                      placeholder="Seleccione una cuota" showClear={true}
                      filter={true}/>
            );
        }
        return form;
    }

    const renderDescriptionInstallment = () => {
        let form = null;
        if (installmentId !== null){
            let installment = installments.filter(x => x.id === installmentId)[0]
            if (installment !== undefined){
                let due_date = new Date(installment.due_date).toLocaleDateString();
                let amount = installment.amount;
                form = (
                    <Fragment>
                        <div>
                            <h3>Detalles de la cuota</h3>
                        </div>
                        <p><b>Fecha de Vencimiento:</b> {due_date}</p>
                        <p><b>Monto: </b><MoneyField>{amount}</MoneyField></p>
                    </Fragment>
                );
            }

        }
        return form;
    };


    const getInstallments = (remote_loan_id) => {
        let apiService = new ApiLoan();
        let url = '/installments/not/paid/';
        apiService.getResource({
            url: url,
            resource_id: remote_loan_id
        }).then(response => {
            const result = response.data.objects.map(installment => {
                return {
                    label: installment.number,
                    value: installment.id
                }
            });
            setInstallments(response.data.objects);
            setInstallmentsOptions(result);
        }).catch(error => {
            //this.props.history.push( '/' );
        });
    };

    const generatePayOrder = () => {
        setDisabledButton(true);

        let apiService = new ApiLoan();
        let formData = {
            'installment_id': installmentId,
            'gateway': gatewayId
        }
        apiService.postResource({
            url: '/pay_orders/single',
            data: formData
        }).then(response => {
            if (response.status === 201){
                props.history.push({pathname: '/pay_orders', state: {message: 'Se creó satisfactoriamente'}});
            }
        }).catch(error => {
            const message_detail = error.response.data.message;
            showMessageToast(message_detail, 'error');
        });
    }

    const toast = useRef(null);

    const showMessageToast = (message, severity) => {
        toast.current.show({severity: severity, summary: 'Orden de Pago', detail: message});
    };


    return (
        <div className="grid p-fluid">
            <Toast ref={toast} />
            <div className="col-12 p-lg-12">
                <div className="card card-w-title">
                    <div className="grid p-fluid">
                        <div className="col-11 md-10 p-lg-9">
                            <h1>Nueva Orden de Pago</h1>
                            {getForm()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default PayOrderSingle;