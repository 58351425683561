import React, { useState, useEffect, useContext } from 'react';
import DialogGeneric from "../../../UI/DialogGeneric";
import { MessageContext } from "../../../context/messageContext";

const ContactSelectorModal = (props) => {
    const [visibleDialog, setVisibleDialog] = useState(false);
    const [valueDialog, setValueDialog] = useState(null);
    const [modal, setModal] = useState(null);
    const { contacts, selectedContacts, selectedContactsModal, updateSelectedContactsModal, updateSelectedContacts, updateContacts } = useContext(MessageContext);
    const [resources, setResources] = useState(null);

    useEffect(() => {
        if (props.enabled) {
            setVisibleDialog(props.enabled)
        }
    }, [props.enabled])

    useEffect(() => {
        if (visibleDialog) {
            dialogMessageForward('list')
        }
    }, [visibleDialog])

    useEffect(() => {
        if (valueDialog !== null) {
            setModal(<DialogGeneric visible={visibleDialog} withoutCancel={true} closable={true} setVisible={setVisibleDialog}
                closeModal={true} selectedContacts={selectedContacts} url={"/messages/single"} {...valueDialog} />);
        }
    }, [valueDialog, visibleDialog, selectedContacts]);

    useEffect(() => {
        if (!visibleDialog && selectedContactsModal) {
            if (modal !== null) {
                setModal(null);
            }
            if (valueDialog !== null) {
                setValueDialog(null);
                updateContacts(!contacts);
                updateSelectedContactsModal(!selectedContactsModal)
                if (selectedContacts.length > 0) {
                    updateSelectedContacts([])
                }
            }
        }
    }, [visibleDialog, props.enabled, modal, contacts, selectedContactsModal])

    useEffect(() => {
        if (selectedContacts.length === 0) {
            setVisibleDialog(true);
        }
    }, [selectedContacts]);

    useEffect(() => {
        selectedContacts.length > 0 ? setResources(selectedContacts) : setResources(null);
    }, [selectedContacts]);

    const getDialogParams = (action) => {
        let data = {};
        let title = 'Seleccionar contacto/s';
        switch (action) {
            case 'list':
                data['width'] = { 'width': '35vw' };
                data['height'] = { 'min-height': '30vw', 'max-height': '60vw' };
                data['title'] = title;
                data['url'] = 'URL';
                data['method'] = 'post';
                data['fields'] = [{
                    name: 'selectedContacts',
                    elementType: 'contactSelector',
                    elementConfig: {},
                    elementClass: 'col-12 md-12',
                }];
                break;
        }
        return data;
    }

    const getHeaderDialog = (title) => {
        return (
            <div className="p-dialog-title">
                <span>
                    <b>{title}</b>
                </span>
            </div>
        );
    }

    const dialogMessageForward = (action) => {
        const params = getDialogParams(action);

        if (Object.keys(params).length === 0) {
            return setVisibleDialog(false);
        }

        let value_dialog = {
            'url': params.url,
            'method': params.method,
            'header': getHeaderDialog(params.title, action),
            'show_footer': false,
            'footer_type': 'shareContacts',
            'submit_label': 'Crear',
            'sumaryToast': 'Contactos',
            'fields': params.fields,
            'width': params.width,
            'height': params.height,
            'buttons_rounded': true,
        }

        setValueDialog(value_dialog)
    }

    return visibleDialog ? modal : null;

}

export default ContactSelectorModal;
