import React, { Component, Fragment } from "react";

import ClientAttributes from "./ClientAttributes";
import ContactList from "../Contacts/ContactList";
import ClientLoans from "./ClientLoans";
import CustomTagsList from "../../Audiences/Audience/CustomTagsList";
import ClientPaymentTables from "./ClientPaymentTables";
import Balances from "../../General/Balances";
import SearchListContainer from "../../../UI/SearchList/SearchListContainer";

import ApiLoan from "../../../service/ApiLoanService";
import Messages from "../../Messages/Messages";
import "../../../assets/css/ClientMultiView.css";
import ClientPaymentsNotProcessed from "./ClientPaymentsNotProcessed";
import ClientPaymentButtons from "./ClientPaymentButtons";


var moment = require("moment");

class ClientMultiView extends Component {
  state = {
    loadingLoans: true,
    loadingPayments: true,
    dynamicGridCols: {
      clientCard: 3,
      contactCard: 4,
      loansCard: 5,
      eventsCard: 6,
      loanDetailsCard: 6
    },
    client: {
      lastname: null,
      firstname: null,
      document_number: null,
      cuil: null,
      notification_subscription: null,
      loans: [],
      loadingLoans: true
    },
    activeTabIndex: 0,
    loans: [],
    total_due: null,
    default_status: null,
    selectedLoan: {
      id: null,
      remote_loan_id: null
    }
  };

  apiService = new ApiLoan();
  onTabChangeHandler = this.onTabChangeHandler.bind(this);
  onChangeOperationHandler = this.onChangeOperationHandler.bind(this);

  onTabChangeHandler(event) {
    this.setState({ activeTabIndex: event.index });
  }

  checkScreenSize(width) {
    let { dynamicGridCols } = this.state;
    let newDynamicGridCols;

    if (width < 1515) {
      newDynamicGridCols = {
        ...dynamicGridCols,
        clientCard: 5,
        contactCard: 7,
        loansCard: 12,
        eventsCard: 12,
        loanDetailsCard: 12
      };
    } else if (width < 1741) {
      newDynamicGridCols = {
        ...dynamicGridCols,
        eventsCard: 12,
        loanDetailsCard: 12
      };
    } else {
      newDynamicGridCols = false;
    }

    return newDynamicGridCols;
  }

  onChangeOperationHandler(operation) {
    this.setState({
      selectedLoan: operation,

    })
  }

  componentDidMount() {
    let dynamicGridCols = this.checkScreenSize(window.innerWidth);

    if (dynamicGridCols) {
      this.setState({
        dynamicGridCols: dynamicGridCols
      });
    }

    this.apiService
      .getResource({
        url: "/debtors/",
        resource_id: this.props.match.params.id
      })
      .then(response => {
        let { client } = this.state;
        for (let field in client) {
          client[field] = response.data[field];
        }
        client['client_id'] = this.props.match.params.id;
        let clientActiveLoans = client.loans.filter( loan => loan.active)
        client.loadingLoans = true;
        let selectedLoan = {
          id: null,
          remote_loan_id: null
        }
        if(clientActiveLoans.length > 0){
          selectedLoan = {
            id: clientActiveLoans[0]["id"],
            remote_loan_id: clientActiveLoans[0]["remote_loan_id"]
          }
        }
        this.setState({
          client: client,
          loadingLoans: false,
          selectedLoan: selectedLoan
        });
      });

    let url = "/loans/debtor";

    if (this.props.match.params.id !== undefined) {
      let uri = "/" + this.props.match.params.id;
      url = url.concat(uri);
    }

    this.apiService
      .getResource({
        url: url
      })
      .then(response => {
        let { client } = this.state;
        const loans = response.data.loans;

        client.loans.map(loan => {
          const fetchedLoan = loans.find(
            loadedLoan => loadedLoan.id === loan.id
          );
          loan.default_status = fetchedLoan.default_status;
          loan.paid_installments =
            String(
              fetchedLoan.installments.filter(installment => installment.paid)
                .length
            ) +
            "/" +
            String(fetchedLoan.installments.length);
          loan.clean_installment = fetchedLoan.installments[0].amount;

          let expiredInstallments = fetchedLoan.installments.filter(
            installment => {
              let installment_date = moment(installment.due_date).format(
                "YYYY-MM-DD"
              );
              let date_now = moment(new Date()).format("YYYY-MM-DD");
              return !installment.paid && installment_date < date_now;
            }
          );

          loan.installments_due = expiredInstallments.length;

          let expiredInstallmentsAmount = expiredInstallments.reduce(
            (prevInstallmentAmount, installment) => {
              prevInstallmentAmount = parseFloat(prevInstallmentAmount) || 0;
              let installmentPaymentAmount = installment.payments.reduce(
                (prevPayment, payment) => {
                  prevPayment = prevPayment || 0;
                  return prevPayment + parseFloat(payment.amount);
                },
                0
              );
              return (
                parseFloat(installment.amount) -
                installmentPaymentAmount +
                prevInstallmentAmount
              );
            },
            0
          );

          loan.interest = fetchedLoan.installments
            .filter(installment => !installment.paid)
            .reduce((prevInstallment, currentInstallment) => {
              let previous = prevInstallment || 0;
              return (
                currentInstallment.interests.reduce((p, c) => {
                  let prev = p || 0;
                  return prev + parseFloat(c.amount);
                }, 0) + previous
              );
            }, 0);

          loan.loan_total_due = expiredInstallmentsAmount + loan.interest;

          return loan;
        });
        client.loadingLoans = false;
        this.setState({
          loans: response.data.loans,
          client: client,
          total_due: response.data.debtor_mv.expired_debt,
          default_status: response.data.loans[0] !== undefined ? response.data.loans[0].default_status : '',
          loadingPayments: false
        });
      });
  }

  render() {
    const {selectedLoan, client} = this.state
    const clientLoans = client.loans.filter((loan) => {
      return loan.id === selectedLoan.id ? loan : null;
    })

    return (
      <Fragment>
        <div className="grid">
          <div
            className={
              "col-12 md-5 p-lg-" + this.state.dynamicGridCols.clientCard
            }
          >
            <div className="card card-w-title">
              <div className="grid first-row-client">
                <div className="col-12">
                  <h1>Deudor</h1>
                  {!this.state.loadingLoans && (
                    <ClientAttributes client={this.state.client} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              "col-12 md-12 p-custom-12 p-lg-" +
              this.state.dynamicGridCols.loansCard
            }
          >
            <div className="card card-w-title">
              <div className="grid first-row-client">
                <div className="col-12">
                  <div className="operation-select">
                    <h1>Operación</h1>
                    <SearchListContainer
                      resource={'loans'}
                      fields={['remote_loan_id']}
                      inputValue={selectedLoan.remote_loan_id}
                      onChange={this.onChangeOperationHandler}
                      filterParams={{
                        filters: [{
                          "name": "client_id",
                          "op": "eq",
                          "val": this.props.match.params.id
                        },
                        {
                          "name": "active",
                          "op": "eq",
                          "val": 1
                        }],
                        order_by: [
                        {
                          "field": "paid",
                          "direction": "asc"
                        },
                        { 
                          "field": "created",
                          "direction": "asc"
                        }]
                      }}
                    />
                  </div>
                  <ClientLoans
                    loans={clientLoans}
                    activeTabIndex={this.state.activeTabIndex}
                    onTabChangeHandler={this.onTabChangeHandler}
                    loading={this.state.client.loadingLoans}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid">
          <div
            className={
              "col-12 md-6 p-lg-" + this.state.dynamicGridCols.eventsCard
            }
          >
            <div className="card card-w-title">
              <div className="grid second-row-client messages">
                <div className="col-12">
                  <h1>Mensajes</h1>
                  <Messages
                      url={"/messages"}
                      client_id={this.props.match.params.id}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              "col-12 md-12 p-lg-" +
              this.state.dynamicGridCols.loanDetailsCard
            }
          >
            <div className="card card-w-title">
              <div className="grid second-row-client">
                <div className="col-12">
                <div className="operation-select">
                    <h1>Detalle de la operación</h1>
                    <SearchListContainer
                      resource={'loans'}
                      fields={['remote_loan_id']}
                      inputValue={selectedLoan.remote_loan_id}
                      onChange={this.onChangeOperationHandler}
                      filterParams={{
                        filters: [{
                          "name": "client_id",
                          "op": "eq",
                          "val": this.props.match.params.id
                        },
                        {
                          "name": "active",
                          "op": "eq",
                          "val": 1
                        }]
                      }}
                    />
                  </div>
                  <div>
                    {!this.state.loadingPayments && (
                      <ClientPaymentsNotProcessed selectedLoan={selectedLoan.id}/>
                    )}
                  </div>
                  <div className="grid">
                    {!this.state.loadingPayments && (
                      <ClientPaymentButtons selectedLoan={selectedLoan.id} />
                    )}
                  </div>
                  {!this.state.loadingPayments && (
                    <ClientPaymentTables
                      selectedLoan={selectedLoan.id}
                      loans={this.state.loans}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className={"col-12 md-12 p-lg-12"}>
            <div className="card card-w-title">
              <div className="col-12">
                <h1>Tags</h1>
                  <CustomTagsList client_id={this.props.match.params.id}/>
                </div>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className={"col-12 md-12 p-lg-12"}>
            <div className="card card-w-title">
              <div className="grid">
                <div className="col-12">
                <div className="operation-select">
                    <h1>Balance</h1>
                    <SearchListContainer
                      resource={'loans'}
                      fields={['remote_loan_id']}
                      inputValue={selectedLoan.remote_loan_id}
                      onChange={this.onChangeOperationHandler}
                      filterParams={{
                        filters: [{
                          "name": "client_id",
                          "op": "eq",
                          "val": this.props.match.params.id
                        },
                        {
                          "name": "active",
                          "op": "eq",
                          "val": 1
                        }]
                      }}
                    />
                </div>
                  {!this.state.loadingLoans && (
                    <Balances
                      selectedLoan={selectedLoan.id}
                      loans={this.state.client.loans}
                      activeTabIndex={this.state.activeTabIndex}
                      onTabChangeHandler={this.onTabChangeHandler}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ClientMultiView;
