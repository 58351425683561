import React, {useState, useEffect, useRef, Fragment, useMemo} from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Divider} from "primereact/divider";
import {CopyToClipboard} from "react-copy-to-clipboard";
import ApiLoan from "../../../service/ApiLoanService";
import {Toast} from "primereact/toast";

const BusinessSheetColumns = (props) => {
    const [columnFields, setColumnFields] = useState({
        message_id: '',
        template_uuid: '',
        template_group: '',
        first_name: '',
        last_name: '',
        phone: '',
        country_prefix: '',
    });

    const [urlPage, setUrlPage] = useState('');
    const [sheetName, setSheetName] = useState('');

    const traductionFields = {
        message_id: 'Identificador del Mensaje',
        template_uuid: 'Identificador Plantilla',
        template_group: 'Grupo Plantilla',
        first_name: 'Nombre del Cliente',
        last_name: 'Apellido del Cliente',
        phone: 'Teléfono',
        country_prefix: 'Código País',
        columns: '',
        url: 'URL',
        sheet: 'Hoja'
    }

    const [templateType, setTemplateType] = useState('template_uuid');
    const [disableSubmit, setDisableSubmit] = useState(true)

    const templateTypeDescription = {
        template_uuid: 'Identificador Plantilla',
        template_group: 'Grupo Plantilla'
    }

    const [customVariables, setCustomVariables] = useState([]);
    const [showCustomVariables, setShowCustomVariables] = useState(false);

    const apiService = new ApiLoan();

    useEffect(() => {
        setUrlPage(props.sheet.urlPage)
    }, [props.sheet.urlPage]);

    useEffect(() => {
        setSheetName(props.sheet.sheetName)
    }, [props.sheet.sheetName]);

    useEffect(() => {
        setColumnFields(props.sheet.columns)
    }, [props.sheet.columns])

    useEffect(() => {
        if (props.sheet.templateType){
            setTemplateType(props.sheet.templateType)
        }
    }, [props.sheet.templateType])

    useEffect(() => {
        let formErrors = {}

        let valid = true

        let _fields = Object.entries(columnFields)
        _fields.map((x, index) => {
            if (x[1] === ''){
                valid = false;
                formErrors[x[0]] = 'Campo Inválido';
            }
            return x
        });
        if (templateType === 'template_uuid'){
            if (formErrors['template_group']){
                delete formErrors['template_group'];
            }
        }
        else if (templateType === 'template_group'){
            if (formErrors['template_uuid']){
                delete formErrors['template_uuid'];
            }
        }
        if (urlPage === ''){
            formErrors['urlPage'] = 'Campo Inválido';
            valid = false;
        }
        if (sheetName === ''){
            formErrors['sheetName'] = 'Campo Inválido';
            valid = false;
        }

        if (Object.keys(formErrors).length === 0){
            valid = true;
        }

        setDisableSubmit(!valid);
    }, [columnFields, urlPage, sheetName]);


    useEffect(() => {
        apiService.getResources({
            url: '/provider/custom_variables',
        }).then(response => {
            setCustomVariables(response.data.objects)
        });
    }, []);

    const updateColumn = (value, column) => {
        setColumnFields((prevState) => {
            return {
                ...prevState,
                [column]: value.toUpperCase()
            }
        });
    }

    const updateTemplateTypeHandler = (value) => {
        if (value === 'template_uuid'){
            setColumnFields((prevState) => {
                return {
                    ...prevState,
                    template_group: ''

                }
            });
        } else {
            setColumnFields((prevState) => {
                return {
                    ...prevState,
                    template_uuid: ''
                }
            });
        }
        setTemplateType(value);
    }

    const sheetColumnFields = useMemo(() => {
        return (
            <Fragment>
                <div className="col-12 md-12 mt-3 flex">
                    <div className="field col-6">
                        <span className="p-float-label">
                            <InputText id="inputtext" value={urlPage} required={true}
                                       onChange={(e) => setUrlPage(e.target.value)}/>
                            <label htmlFor="inputtext">URL</label>
                        </span>
                    </div>
                    <div className="field col-6">
                        <span className="p-float-label">
                            <InputText id="inputtext" value={sheetName} required={true}
                                       onChange={(e) => setSheetName(e.target.value)}/>
                            <label htmlFor="inputtext">Hoja</label>
                        </span>
                    </div>
                </div>
                <Divider/>
                <div className="text-500 mt-0 flex">
                    <FontAwesomeIcon icon={'fa-regular fa-circle-info'}
                                     color={'#46d6b7'}
                                     size={'5x'}
                                     style={{width: '0.3em', height: '0.3em', marginRight: '0.1em'}}/>
                    Las letras en la parte superior (A, B, C, etc.) son los encabezados de columna, que indican
                    la posición de cada columna en la hoja de cálculo
                </div>
                <Divider/>
                <div className="col-8 md-8 col-offset-3 mt-2 flex">
                    <div className="field col-12">
                        <div className="flex flex-wrap gap-3">
                            <div className="flex align-items-center">
                                <RadioButton inputId="template_uuid" name="template_type" value="template_uuid"
                                             onChange={(e) => updateTemplateTypeHandler(e.value)}
                                             checked={templateType === 'template_uuid'}/>
                                <label htmlFor="template_uuid"
                                       className="ml-2">{templateTypeDescription['template_uuid']}</label>
                            </div>
                            <div className="flex align-items-center">
                                <RadioButton inputId="template_group" name="template_type" value="template_group"
                                             onChange={(e) => updateTemplateTypeHandler(e.value)}
                                             checked={templateType === 'template_group'}/>
                                <label htmlFor="template_group"
                                       className="ml-2">{templateTypeDescription['template_group']}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 md-12 mt-3 flex">
                    <div className="field col-6">
                        <span className="p-float-label">
                            <InputText id="inputtext" value={columnFields.message_id} required={true}
                                       keyfilter={'alpha'} maxLength={1}
                                       onChange={(e) => updateColumn(e.target.value, 'message_id')}/>
                            <label htmlFor="inputtext">Identificador del Mensaje</label>
                        </span>
                    </div>
                    <div className="field col-6">
                        <span className="p-float-label">
                            <InputText id="inputtext" value={columnFields[templateType]} required={true}
                                       keyfilter={'alpha'} maxLength={1}
                                       onChange={(e) => updateColumn(e.target.value, templateType)}/>
                            <label htmlFor="inputtext">{templateTypeDescription[templateType]}</label>
                        </span>
                    </div>
                </div>
                <div className="col-12 md-12 flex">
                    <div className="field col-6">
                        <span className="p-float-label">
                            <InputText id="inputtext" value={columnFields.first_name} required={true}
                                       keyfilter={'alpha'} maxLength={1}
                                       onChange={(e) => updateColumn(e.target.value, 'first_name')}/>
                            <label htmlFor="inputtext">Nombre del Cliente</label>
                        </span>
                    </div>
                    <div className="field col-6">
                        <span className="p-float-label">
                            <InputText id="inputtext" value={columnFields.last_name} required={true}
                                       keyfilter={'alpha'} maxLength={1}
                                       onChange={(e) => updateColumn(e.target.value, 'last_name')}/>
                            <label htmlFor="inputtext">Apellido del Cliente</label>
                        </span>
                    </div>
                </div>
                <div className="col-12 md-12 flex">
                    <div className="field col-6">
                        <span className="p-float-label">
                            <InputText id="inputtext" value={columnFields.phone} required={true}
                                       keyfilter={'alpha'} maxLength={1}
                                       onChange={(e) => updateColumn(e.target.value, 'phone')}/>
                            <label htmlFor="inputtext">Teléfono</label>
                        </span>
                    </div>
                    <div className="field col-6">
                        <span className="p-float-label">
                            <InputText id="inputtext" value={columnFields.country_prefix} required={true}
                                       keyfilter={'alpha'} maxLength={1}
                                       onChange={(e) => updateColumn(e.target.value, 'country_prefix')}/>
                            <label htmlFor="inputtext">Código País</label>
                        </span>
                    </div>
                </div>
            </Fragment>
        )
    }, [columnFields, templateType, urlPage, sheetName]);

    const handleSubmit = (e) => {
        e.preventDefault()

        let fields = {...columnFields}
        if (templateType === 'template_uuid'){
            delete fields.template_group;
        } else if (templateType === 'template_group'){
            delete fields.template_uuid;
        }

        let formData = {
            "url": urlPage,
            "sheet": sheetName,
            "headers": true,
            "message_error": "XX",
            "validate_columns": fields
        }

        let valid = false;

        apiService.postResource({
            'url': '/provider_scheduled/sheet',
            'data': formData
        }).then(response => {
            valid = true
            refToast.current.show({severity: 'success', summary: 'Validación Sheet', detail: 'Validación exitosa'});
        }).catch(error => {
            valid = false;

            Object.entries(error.response.data.messages[0]).reduce((acc, [key, value]) => {
                let detail = `${traductionFields[key]} - ${value}` ;

                refToast.current.show({severity: 'error', summary: 'Validación Sheet', detail: detail,
                    life:5000, group: "nose" });

                return acc;
            }, []);

        }).finally(() => {
            if (valid){
                props.onSheetColumnsUpdate({columnFields: fields, urlPage: urlPage, sheetName: sheetName,
                    templateType: templateType});
                props.setShowSheetFieldsDialog(false)
            }
        });
    }

    let footer = () => {
        return (
            <div className={'flex justify-content-between '}>
                <div className={''}>
                    <Button label={'Visualizar Variables'} icon="pi pi-eye"
                            onClick={(e) => { setShowCustomVariables(true)} }>
                    </Button>
                </div>
                <div className=''>
                    <Button label={'Aceptar'} icon="pi pi-check" disabled={disableSubmit}
                            onClick={(e) => { handleSubmit(e)} }>
                    </Button>
                </div>
            </div>
        );
    }

    const showCustomVariablesFields = useMemo(() => {
        return customVariables.map((variable) => {
            return (
                <div className={"field col-4"} key={variable}>
                    <CopyToClipboard text={variable.slug}>
                        <a href={''} onClick={(event) => {
                            event.preventDefault()
                        }}
                           className='btn-actions' style={{padding: '10px 1px 0px 10px', borderRadius: '30px'}}>
                            <span>
                                <FontAwesomeIcon icon={'fa-regular fa-copy'} size="sm" color={'#FD971F'}
                                                 style={{width: '1.5em', height: '1.5em'}}/>
                            </span>
                        </a>
                    </CopyToClipboard>
                    <b className='pl-3'>{variable.slug}</b><br/>
                </div>
            );
        });
    }, [customVariables]);

    const refToast = useRef(null);

    return (
        <Fragment>
            <Dialog header="Configuración de columnas Sheet" visible={props.showSheetFieldsDialog} style={{width: '30vw'}}
                    onHide={() => {
                        if (!props.showSheetFieldsDialog) return;
                        props.setShowSheetFieldsDialog(false);
                    }}
                    className={'p-fluid'}
                    footer={footer}>
                <div className="grid">
                    <Toast ref={refToast}/>
                    {sheetColumnFields}
                </div>
            </Dialog>
            <Dialog header="Variables a utilizar en Sheet" visible={showCustomVariables} style={{width: 'auto', minWidth: '50vw'}}
                    onHide={() => setShowCustomVariables(false)}>
                <div className="custom-modal-body">
                    <div className={'grid col-12'}>
                        {showCustomVariablesFields}
                    </div>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default BusinessSheetColumns;