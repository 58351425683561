import React, {Fragment, useEffect, useState, useRef, useContext} from "react";
import { Toast } from 'primereact/toast';
import Resources from "../Resources/Resources";
import {RadioButton} from "primereact/radiobutton";
import moment from 'moment';
import { TableContext } from "../../context/tableContext";


const CoelsaHolidays = props => {
    const [resource, setResource] = useState({
        title: 'Feriados',
        url: '/coelsa/holidays',
        colsHeader: [
            { field: 'calendar_day', header: 'Fecha', date: true},
            { field: 'action', header: 'Acciones' }
        ],
        action: {
            add: '/new',
            delete: true
        },
        params: {
        },
        urlTable: [
            '/coelsa/holidays',
        ]
    });
    const [resourceParams, setResourceParams] = useState({});
    const [holidayAction, setHolidayAction] = useState('upcoming');

    const {updateTableFields} = useContext(TableContext);

    useEffect(() => {
        if (holidayAction === 'upcoming'){
            let filters = [
                {
                    name: 'calendar_day',
                    op: 'gte',
                    val: moment(new Date()).format('YYYY-MM-DD')
                }
            ];
            setResourceParams((prevState) => {
                let new_params = {...prevState};
                new_params['filters'] = filters
                return {
                    ...prevState,
                    ...new_params
                }
            });
        } else {
            setResourceParams((prevState) => {
                let new_params = prevState;

                if (new_params.filters !== undefined){
                    delete new_params.filters
                }
                return {
                    ...prevState,
                    ...new_params
                }
            });
        }
    }, [holidayAction]);

    useEffect(() => {
        let params = {};
        params["reset"] = true;
        updateTableFields(params);
    }, [resourceParams])

    const toast = useRef(null);

    const toggleRadioButton = (value) => {
        setHolidayAction(value);
    }

    let dataTableHeader = (
            <div className="table-header">
                <div className={"flex p-jc-end"}>
                    <div className="ml-2 mr-2">
                        <label className={"mr-2"} htmlFor="reminder">Próximos Feriados</label>
                        <RadioButton inputId="upcoming" name="holidays" value={'upcoming'} onChange={(e) => toggleRadioButton(e.value)} checked={holidayAction === 'upcoming'} />
                    </div>
                    <div className={'ml-2 mr-2'}>
                        <label className={"mr-2"} htmlFor="all_holidays">Todos los Feriados</label>
                        <RadioButton inputId="all_holidays" name="holidays" value={'all_holidays'} onChange={(e) => toggleRadioButton(e.value)} checked={holidayAction === 'all_holidays'} />
                    </div>
                </div>
            </div>
    );


    return (
        <Fragment>
            <Toast ref={toast} />
            <Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader}
                       customActions={resource.customActions} params={resourceParams} action={resource.action}
                       urlTable={resource.urlTable} header={dataTableHeader}
            />
        </Fragment>
    );

}

export default CoelsaHolidays;