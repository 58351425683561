import React, { useEffect } from 'react';
import ApiLoan from '../../service/ApiLoanService';
import { useHistory } from "react-router-dom";
import CustomSpinner from '../../UI/CustomSpinner';

const ProviderScheduleds = (props) => {
    const history = useHistory();
    useEffect(() => {
        let apiService = new ApiLoan();
        if (props.match.params.action === undefined){
            history.push('/');
            return;
        }
        apiService.getResources({
            'url': '/provider_scheduled',
            'filters': [
                {
                    "name": "provider_scheduled_action__slug", "op": "has", "val": props.match.params.action
                }
            ],
        }).then(response => {
            if (response.data.objects.length === 0) {
                history.push('/provider_scheduled/new/' + props.match.params.action);
            } else {
                history.push('/provider_scheduled/' + response.data.objects[0].id + '/edit/' + props.match.params.action);
            }
        })
    }, [])

    return (<CustomSpinner status={'Cargando Mensajes Programados...'}/>)
}

export default ProviderScheduleds;