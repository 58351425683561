import React, {useState, useEffect} from 'react';

import { CSVLink } from "react-csv";

import ApiLoan from "../../service/ApiLoanService";
import {useHistory} from "react-router-dom";


export default function CsvExample({CSV_TYPE}){
    const [providerCsvConfig, setProviderCsvConfig] = useState({
        loading: true,
        csvConfig: {}
    });
    const csvRowExamples = {
        CLIENTS_BULK_CSV_SETTINGS: [
            ["lastname", "firstname", "document_number", "cuil", "extra_info_1", "extra_info_2", "extra_info_3", "phone", "country_prefix", "email", "custom_tags", "user_identity", "group"],
            ["RIQUELME", "JOSE FELIX", "11222333", "23112223339", "Info 1", "Info 2", "Info 3", "2616223344", "54", "demo@pip.com.ar", "tag1, tag2", "usuario1", "RRHH"],
            ["ALTAMIRANO", "ROBERTO JUAN", "22333444", "20223334449", "Info 1", "Info 2", "Info 3", "2616445566", "54", "demo@pip.com.ar", "tag3, tag2", "usuario2", "Ventas"],
            ["TORRES", "ALBERTO OSVALDO", "33444555", "20334445557", "Info 1", "Info 2", "Info 3", "2616334455", "54", "demo@pip.com.ar", "tag1", "usuario3", "RRHH"]
        ],
        LOANS_BULK_CSV_SETTINGS: [
            ["document_number", "cbu", "remote_loan_id", "amount_borrowed", "amount", "requested_installment", "number", "due_date", "description", "operation"],
            ["11222333", "2850590940090418135201", "100357", "10000", "12000", "3", "1", "2018-04-10", 'Detalle', "Prestamo"],
            ["11222333", "2850590940090418135201", "100357", "10000", "12000", "3", "2", "2018-05-10", 'Detalle', "Prestamo"],
            ["11222333", "2850590940090418135201", "100357", "10000", "12000", "3", "3", "2018-06-11", 'Detalle', "Prestamo"],
            ["22333444", "4170231820000000010500", "100311", "10000", "12000", "3", "1", "2019-02-11", 'Detalle', "Prestamo"],
            ["22333444", "4170231820000000010500", "100311", "10000", "12000", "3", "2", "2019-03-11", 'Detalle', "Prestamo"],
            ["22333444", "5170231820000000010500", "100311", "10000", "12000", "3", "3", "2019-04-10", 'Detalle', "Prestamo"]
        ],
        PAY_ORDER_CSV_SETTINGS: [
            ["gateway","remote_loan","payment_number","payment_date"],
            ["Coelsa","100357","1","2014-10-21"],
            ["Coelsa","100357","2","2019-10-21"],
            ["Coelsa","100357","3","2017-10-21"],
            ["Coelsa","100311","1","2019-10-22"]
        ],
        PAYMENTS_CSV_SETTINGS: [
            ["remote_loan_id", "payment_date", "payment_number", "amount", "operation"],
            ["100357", "2018-04-10", "1", "3704.58", "Recibo"],
            ["100311", "2019-02-11", "1", "3704.58", "Recibo"],
            ["100311", "2019-03-11", "2", "3704.58", "Recibo"],
            ["100311", "2019-04-10", "3", "3704.58", "Recibo"]
        ],
        DEBIT_PAY_ORDER_CSV_SETTINGS: [
            ["gateway", "amount", "description", "remote_pay_order", "document_number", "firstname", "lastname", "cuil", "cbu", "payment_date"],
            ["Coelsa","300","Descripcion 1","RRR778","11222555","Julio","Gomez","20112225552","2850590940090418135201","25/09/2020"],
            ["Coelsa","400","Descripcion 2","PQQ 132","11555888","Francisco","Letizia","20115558887","2850590940090418135201","25/09/2020"]
        ],
    }

    const history = useHistory();

    useEffect(() => {
        const apiLoan = new ApiLoan();
        apiLoan
        .getResource({
            url: "/csv/config"
        })
        .then(response => {
            if (response.data[CSV_TYPE] === null){
                history.push('/');
            }

            setProviderCsvConfig({
                loading: false,
                csvConfig: response.data,
            });
        });
    },[])

    function generateExample(csvConfig){
        const csvRawExample = csvRowExamples[CSV_TYPE]
        let csvRawExampleHeader = csvRawExample[0]
        const newHeader = csvRawExampleHeader.map((columnHeader) => {
            return csvConfig[CSV_TYPE][columnHeader]
        })
        csvRawExample[0] = newHeader
        return csvRawExample
    }
    let component = '';

    if(!providerCsvConfig.loading){
        const exampleData = generateExample(providerCsvConfig.csvConfig);
        component = (<CSVLink data={exampleData} separator={","} className={'p-button p-component p-download'} filename={'ejemplo.csv'}>
                        <span className="pi pi-download"> Ejemplo</span>
                    </CSVLink>);
    }

    return component;
}
