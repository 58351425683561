import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {Button} from 'primereact/button';
import ApiLoan from '../../service/ApiLoanService';
import {Toast} from "primereact/toast";
import InputField from '../Forms/InputField';
import TemplateVariables from './TemplateVariables';
import '../../assets/css/CustomModalTemplate.css'
import axios from "axios";
import Picker from "emoji-picker-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Dialog} from "primereact/dialog";
import {InputTextarea} from "primereact/inputtextarea";
import {InputText} from 'primereact/inputtext';
import {CompanyContext} from "../../context/companyContext";

const TemplateForm = ({ gateway, template, onSubmit, onCloseModal, onBack, action, headerTitle, setShowDialog,
                          showDialog, canCreateCustomVariables, businessIntegration, titleButton }) => {

    let values = {
        whatsapp_template: {
            value: businessIntegration ? (localStorage.getItem('gatewayWaba') === 'CLOUD-API') : false,
            error: null,
        },
        title: {
            value: '',
            error: null,
        },
        template_multimedia: {
            value: false,
            error: null
        },
        template_header_config: {
            value: null,
            error: null
        },
        body: {
            value: '',
            error: null,
        },
        category: {
            value: '',
            error: null,
        },
        business_integration: {
          value: businessIntegration,
          error: null,
        },
        template_group: {
            value: '',
            error: null,
        }
    }

    const [mainState, setState] = useState({
        template: template,
        values,
        loading: false,
        showHelp: false,
    });
    const [templateImgUrl, setTemplateImgUrl] = useState(null);
    const [disableButton, setDisableButton] = useState(false);
    const [acceptedMimeTypes, setAcceptedMimeTypes] = useState([]);
    const [files, setFiles] = useState([]);

    const refInputBody = useRef(null);

    const [showPicker, setShowPicker] = useState({
        show: false,
        cursorPosition: null
    })

    const [customVariables, setCustomVariables] = useState([]);
    const [newCustomVariable, setNewCustomVariable] = useState(false);

    const apiService = new ApiLoan();

    const company_context = useContext(CompanyContext);

    const socket = company_context.socket;

    useEffect(() => {
        if (action === 'edit' && templateImgUrl !== null && files.length === 0){
            let url = axios.defaults.baseURL + '/messages/media?url_media='+templateImgUrl
            setFiles(prevState => {
                return [{
                    source: url,
                    options: {
                        type: 'local'
                    }
                }];
            });
        }
    }, [action, templateImgUrl])


    const newCustomVariableEvent = (data) => {
        let old_custom_variables = [...customVariables];

        let new_variable = {
            id: data.id,
            name: data.name,
            description: data.description,
            example: data.example,
        }
        old_custom_variables.push(new_variable);

        setCustomVariables(prevState => {
           return [
                ...prevState,
               ...old_custom_variables
           ]
        });
    }


    useEffect(() => {
        socket.on('new-provider-custom-variable-' + localStorage.getItem('provider_id'), newCustomVariableEvent)
        return () => {
          socket.off('new-provider-custom-variable-' + localStorage.getItem('provider_id'), newCustomVariableEvent)
        }
      }, [newCustomVariable])

    useEffect(() => {
        if (template){
            let updatedValues = {
                ...mainState.values
            };
            updatedValues.title.value = template.title
            updatedValues.body.value = template.body
            updatedValues.template_group.value = template.template_group
            if (template.template_header_config !== null){
                updatedValues.template_header_config.value = template.template_header_config;
                updatedValues.template_multimedia.value = true;
                if (action === 'edit'){
                    setTemplateImgUrl(template.template_header_config.url);
                }
            }

            setState({
                ...mainState,
                values: updatedValues,
            });
        }
    }, [template, action]);

    useEffect(() => {
        let mimetypes = [
            'image/jpg',
            'image/png',
            'image/jpeg',
            'application/pdf',
            'video/mp4'
        ]
        if (gateway === 'OPEN-WA'){
            mimetypes.splice(mimetypes.findIndex(x => x === 'video/mp4'), 1);
        }
        setAcceptedMimeTypes(mimetypes);
    }, [gateway])

    useEffect(() => {
        setDisableButton(mainState.values.template_multimedia.value);
        if (!mainState.values.template_multimedia.value){
            if (mainState.values.template_header_config.value !== null) {
                let attribute = 'template_header_config'
                let updatedValues = {
                    ...mainState.values
                };
                updatedValues[attribute]['value'] = null;
                updatedValues[attribute]['error'] = null;

                setState({
                    ...mainState,
                    values: updatedValues,
                });
            }
        }
    }, [mainState.values.template_multimedia.value])

    useEffect(() => {
        if (files.length === 0 && mainState.values.template_multimedia.value && !disableButton){
            setDisableButton(true)
        }
    }, [files, mainState.values.template_multimedia.value, disableButton])

    const showHelp = () => {
        setState((prevState) => {
            return {
                ...prevState,
                showHelp: !prevState.showHelp
            }
        })
    }

    useEffect(() => {
        if (mainState.values.whatsapp_template.value && mainState.values.body.value.length >= 1024) {
            let trimmedBody = mainState.values.body.value.slice(0, 1024);
            setState(prevState => ({
                ...prevState,
                values: {
                    ...prevState.values,
                    body: {
                        ...prevState.values.body,
                        value: trimmedBody,
                        error: 'Cantidad máxima de caracteres alcanzada.',
                        charCount: trimmedBody.length
                    }
                }
            }));
        }
    }, [mainState.values.whatsapp_template.value]);


    useEffect(() => {
        if (!canCreateCustomVariables){
            return null;
        }
        apiService.getResources({
            url: '/provider/custom_variables',
        }).then(response => {
            setCustomVariables(response.data.objects)
        });

    }, []);

    const handleChange = (event) => {
        let updatedValues = { ...mainState.values };
        if (event.name === 'body' && mainState.values.whatsapp_template.value) {
            if (event.value.length >= 1024) {
                updatedValues.body.error = 'Cantidad máxima de caracteres alcanzada.';
                const inputType = event.nativeEvent && event.nativeEvent.inputType;
                if (inputType === 'insertFromPaste') {
                    updatedValues.body.value = event.value.slice(0, 1024);
                } else if (mainState.values.body.value.length < 1024 || inputType === 'deleteContentBackward') {
                    updatedValues.body.value = event.value.slice(0, 1024);
                } else {
                    updatedValues.body.value = mainState.values.body.value;
                }
            } else {
                updatedValues.body.value = event.value;
                updatedValues.body.error = null;
            }
            updatedValues.body.charCount = updatedValues.body.value.length;
        } else {
            updatedValues.body.error = null;
            updatedValues[event.name].value = event.value;
        }

        setState({
            ...mainState,
            values: updatedValues,
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        setState((prevState) => {
            return {
                ...prevState,
                loading: true
            }
        })

        let data = {};

        Object.keys(mainState.values).forEach((key) => {
            data[key] = mainState.values[key]['value'];
        });

        if (data.whatsapp_template === false){
            delete data.category;
        }
        if (data.template_multimedia === false){
            delete data.template_header_config;
        }
        if (data.template_group === ''){
            delete data.template_group;
        }

        if (canCreateCustomVariables){
            let variables = customVariables.map(x => {
                return {
                    id: x.id,
                    name: x.name
                };
            });

            const matchedVariables = new Set(data['body'].match(/\{[^}]+\}/g) || []);

            data['custom_variables'] = variables.reduce((acc, variable) => {
                if (matchedVariables.has(variable.name)) {
                    acc.push({id: variable.id});
                }
                return acc;
            }, [])
        }

        let requestMethod = apiService.postResource;
        let args = {
            url: '/templates',
            data: data,
        }

        if(template){
            requestMethod = apiService.patchResource;
            args['resource_id'] = template.id;
        }
        
        requestMethod(args).then((response) => {
            onSubmit({
                ...response.data,
                id: template ? template.id : response.data.id
            })
        }).catch(error => {
            let object_keys = Object.keys(error.response.data.message)

            setState((prevState) => {
                object_keys.map(x => {
                    return prevState.values[x].error = error.response.data.message[x][0]
                })
                prevState.loading = false
                return {
                    ...prevState
                }
            })
            toast.current.show({severity:'error', summary: 'Plantillas', detail: 'Verifique los datos del formulario', life: 3000});

        })
    }

    const header = (
        <div className="custom-modal-header">
            <div className="custom-modal-header-title">
            <span className="custom-tag-get-back" onClick={onBack}>
                <FontAwesomeIcon icon={'arrow-left'}/>
            </span>
            {headerTitle}
            </div>
        </div>
    );

    const categoriesOptions =  [
        {
            'label': 'Utilidad',
            'value': 'UTILITY',
        },
        {
            'label': 'Marketing',
            'value': 'MARKETING',
        },
        {
            'label': 'Autenticación',
            'value': 'AUTHENTICATION',
        }
    ];


    const category = ( mainState.values.whatsapp_template.value ?
        <div className="field col-12 ml-2">
            <InputField type={'dropdown'}
                        name={'category'}
                        small_errors={mainState.values.category.error}
                        value={mainState.values.category.value} traduction={'Categoría'}
                        options={categoriesOptions}
                        onChange={(event) => {handleChange(event.target)}}
            />
        </div> : null
    );

    const template_whatsapp = ( gateway === 'CLOUD-API' ?
        <div className="field col-12 ml-2">
            <InputField type={'switch'} value={mainState.values.whatsapp_template.value}
                        traduction={'Plantilla de Whatsapp'}
                        name={'whatsapp_template'}
                        disabled={businessIntegration}
                        onChange={(event) => {handleChange(event.target)}}
            />
        </div> : null
    );

    const template_multimedia = (
        <div className="field col-12 ml-2">
            <InputField type={'switch'} value={mainState.values.template_multimedia.value}
                        traduction={'Agregar Multimedia'}
                        name={'template_multimedia'}
                        onChange={(event) => {handleChange(event.target)}}
            />
        </div>
    );

    const template_multimedia_properties = {
        allowMultiple: false,
        maxFiles: 1,
        acceptedFileTypes: acceptedMimeTypes,
        maxFileSize: '5MB',
        allowImagePreview: true,
        labelIdle: 'Arrastre y Suelte el archivo o seleccione <span class="filepond--label-action"> Buscar </span>',
        onupdatefiles: (fileItems => {
            setFiles(prevState => {
                return fileItems.map(fileItem => {
                    return fileItem.file
                });
            });
        }),
        files: files,
        server: {
            url: axios.defaults.baseURL + '/messages/media?upload=1',
            load:  (url, load, error, progress, abort, headers) => {
                fetch(url,{
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    })
                }).then(res => {
                    return res.blob();
                }).then(a => {
                    load(a)
                    setDisableButton(false)
                }).catch(error);
            },
            process: {
                withCredentials: true,
                timeout: 600000,
                headers: {
                    Authorization: 'Bearer '+ localStorage.getItem('token')
                },
                onerror: (response) => {
                    toast.current.show({severity:'error', summary: 'Multimedia', detail: 'Error al subir', life: 3000});
                },
                onload: (response) => {
                    response = JSON.parse(response);
                    if (response['status_code'] === 200){
                        let attribute = 'template_header_config'
                        let updatedValues = {
                            ...mainState.values
                        };

                        updatedValues[attribute]['value'] = response.media;
                        if (updatedValues[attribute]['error'] !== null){
                            updatedValues[attribute]['error'] = null;
                        }

                        setState(prevState => {
                            return {
                                ...prevState,
                                values: {...updatedValues}
                            }
                        });

                        setDisableButton(false)
                        setTemplateImgUrl(response.media.url)

                    }
                }
            },
            revert: null
        }
    }

    const template_multimedia_fields = (mainState.values.template_multimedia.value?
        <InputField type={'file_upload'} small_errors={mainState.values.template_header_config.error}
                    value={mainState.values.template_header_config.value?.upload_id === undefined ? '' : mainState.values.template_header_config.value?.upload_id } traduction={'Ejemplo'}
                    {...template_multimedia_properties}
        /> : null
    );

    const showPickerHandler = (start) => {
        setShowPicker((prevState) => {
            return {
                ...prevState,
                cursorPosition: start,
                show: true
            }
        })
    }

    const emoji_button = (
        <button className="p-link"
                onClick={() => showPickerHandler(refInputBody.current.selectionStart)}>
            <FontAwesomeIcon icon={"fa-regular fa-face-smile"} className='botonTransp'/>
        </button>
    )

    const getFieldError = (errors, field) => {
        let form = null;
        if (errors !== null){
            form = <small style={{color:'#f44336'}} className="p-error"> {errors} </small>
        }
        return form;
    }

    const template_group = businessIntegration ? (
        <div className="field col-12 ml-2">
            <InputText type={'text'} name={'template_group'} small_errors={mainState.values.template_group.error}
                       keyfilter="alpha"
                       value={mainState.values.template_group.value} placeholder={'Grupo'}
                       onChange={(event) => {handleChange(event.target)}}
                       className={mainState.values.template_group.error && "p-invalid"}
            />
            {getFieldError(mainState.values.template_group.error, 'template_group')}
        </div>
    ) : null;

    const body = (
        <Fragment>
            <div className="grid p-fluid">
                <div className="col-12">
                    {template_whatsapp}
                    {category}
                    {template_group}

                    <div className="field col-12">
                        <div className="col">
                            <InputText type={'text'} name={'title'} small_errors={mainState.values.title.error}
                                   value={mainState.values.title.value} placeholder={'Título'}
                                   onChange={(event) => {handleChange(event.target)}}
                                   className={mainState.values.title.error && "p-invalid"}
                            />
                            {getFieldError(mainState.values.title.error, 'title')}
                        </div>
                    </div>
                    <div className="field col-12 inline-flex">
                        <div className="col">
                            <InputTextarea
                                        className={mainState.values.body.error && "p-invalid"}
                                        type={'textarea'} name={'body'} small_errors={mainState.values.body.error}
                                        value={mainState.values.body.value} placeholder={'Contenido'}
                                        onChange={(event) => {handleChange(event.target)}}
                                        ref={refInputBody}
                            />
                            {getFieldError(mainState.values.body.error, 'body')}
                        </div>
                        <div className="field mt-4">
                            {emoji_button}
                        </div>
                    </div>
                    {template_multimedia}
                    {template_multimedia_fields}
                </div>
            </div>
        </Fragment>
    );

    const hidePicker = () => {
        setShowPicker((prevState) => {
            return {
                ...prevState,
                cursorPosition: null,
                show: false
            }
        })
    }

    const { loading } = mainState;

    const footer = (
        <div className="custom-modal-footer">
            <div className="flex p-p-2">
                <Button
                    label="Personalizar"
                    className="p-button-info"
                    icon={'pi pi-palette'}
                    iconPos='right'
                    onClick={event => showHelp()}
                    />

                <Button label={ loading ? 'Cargando...' : (titleButton) }
                        onClick={handleSubmit} className="ml-auto"
                        icon={'pi pi-plus'}
                        disabled={loading || disableButton} />

            </div>
        </div>
    );
    const toast = useRef(null);


    const onEmojiClick = (event, emojiObject) => {
        event.preventDefault();

        let input_element = refInputBody.current

        let text_before_cursor_position = input_element.value.substring(0, showPicker.cursorPosition)
        let text_after_cursor_position = input_element.value.substring(showPicker.cursorPosition, input_element.value.length)
        input_element.value = text_before_cursor_position + emojiObject.emoji + text_after_cursor_position

        handleChange(input_element)

        setShowPicker((prevState) => {
            return {
                ...prevState,
                cursorPosition: null,
                show: false
            }

        })
    };

    const emojiDialog = (
        <Dialog header="Emojis" visible={showPicker.show} style={{ width: 'auto', minWidth: '50vw' }} onHide={() => hidePicker()}>
            <div>
                {showPicker.show && (<Picker
                    disableSearchBar={false}
                    pickerStyle={{ width: "100%", zIndex: "0" }}
                    onEmojiClick={(ev, data) => onEmojiClick(ev, data)}/>)}
            </div>
        </Dialog>
    )

    const mainComponent = (
        <Dialog header={header} footer={footer} visible={showDialog} style={{ width: 'auto', minWidth: '50vw' }} onHide={() => setShowDialog(false)}>
            <Fragment>
                <Toast ref={toast} />
                <div className="custom-modal-body" style={{overflow: 'auto'}}>
                    {body}
                    {emojiDialog}
                </div>
            </Fragment>
        </Dialog>
    );


    const helperComponent = <TemplateVariables showDialog={showDialog} setShowDialog={setShowDialog}
                                               onClose={showHelp} customVariables={customVariables}
                                               canCreateCustomVariables={canCreateCustomVariables}
                                               setNewCustomVariable={setNewCustomVariable}
                                               onCloseModal={onCloseModal}/>

    return mainState.showHelp ? helperComponent : mainComponent;
}

export default TemplateForm;