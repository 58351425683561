import React, {useEffect, useState, useRef} from "react";
import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import {withRouter} from "react-router-dom";
import {Dropdown} from "primereact/dropdown";
import {Calendar} from "primereact/calendar";
import ApiLoan from "../../service/ApiLoanService";
import { saveAs } from 'file-saver';
import { Toast } from 'primereact/toast';
import { addLocale } from 'primereact/api';

const CoelsaExport = props => {

    const [loading, setLoading] = useState(true);
    const [gatewayId, setGatewayId] = useState(null);
    const [operationType, setOperationType] = useState(null);
    const [rangeDate, setRangeDate] = useState(null);
    const [disabledButton, setDisabledButton] = useState(true);

    useEffect(() => {
        let apiService = new ApiLoan();
        apiService.getResources({
            url: '/payment_methods',
            filters: [
                {"name": "slug", "op": "eq", "val": 'coelsa'}
            ],
        }).then(resp => {
            setGatewayId(resp.data.objects[0].id);
            setLoading(false);
        }).catch(error => {
            showMessageToast('Error en la carga de los medios de pago', 'error');
        });

    },[]);

    useEffect(() => {
        if (operationType !== null && rangeDate !== null){
            setDisabledButton(false);
        }
    }, [operationType, rangeDate]);

    let form = null;

    const optionsOperationType = [
        {label: 'Elija un tipo de operación', value: null},
        {label: 'TRN 121/170', value: 'fund_collected'},
        {label: 'TRN 121/165', value: 'funds_withdrawal'},
        {label: 'TRN 121/167', value: 'prontocobro_fees'},
    ];

    const getDateFields = () => {
        let form = null;
        if (operationType !== null){
            let maxDate = new Date();
            addLocale('es', {
                firstDayOfWeek: 1,
                dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
                dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
                dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
                monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
            });

            form = (
                <Calendar value={rangeDate} dateFormat="dd/mm/yy" maxDate={maxDate} locale="es"
                          onChange={(e) => setRangeDate(e.value)} placeholder="Seleccione un rango de fechas"
                          showIcon={true} selectionMode="range" readonlyInput={true} />
            );
        }
        return form;

    };

    const coelsaExportHandler = (event) => {
        event.preventDefault();
        setDisabledButton(true);
        axiosAsync();
    };

    const axiosAsync = async() => {
        let apiService = new ApiLoan();

        let res = await apiService.getResourceBlob({
            url: '/coelsa/'+operationType,
            min_date: rangeDate[0],
            max_date: rangeDate[1],
            gateway_id: gatewayId
        });

        if (res.status === 200){
            saveAs(res.data, operationType+'.csv');
        } else if (res.status === 204){
            showMessageToast('No existen registros para la fecha indicada', 'info');
        } else {
            showMessageToast('Intente nuevamente en unos minutos', 'error');
        }
        setDisabledButton(false);

    };

    const toast = useRef(null);

    const showMessageToast = (message, severity) => {
        toast.current.show({severity: severity, summary: 'Reporte Coelsa', detail: message});
    };

    if (!loading){
        form = (
            <div className="grid dashboard">
                <Toast ref={toast} />
                <div className="col-12 md-12 p-lg-12 p-fluid">
                    <form onSubmit={coelsaExportHandler}>
                        <Panel header="Reporte Coelsa" style={{minHeight: '500px'}}>
                            <div className="col-12 form-group">
                                <div className="grid">
                                    <div className={"col-12 md-12"}>
                                        <Dropdown value={operationType} options={optionsOperationType}
                                                  onChange={(e) => setOperationType(e.value)}
                                                  placeholder="Elija un tipo de operación"/>
                                    </div>
                                    <div className={"col-12 md-12"}>
                                        {getDateFields()}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md-12">
                                <Button label="Buscar..." style={{marginBottom:'10px'}}
                                        disabled={disabledButton} />
                            </div>
                        </Panel>
                    </form>
                </div>
            </div>
        );
    }

    return form;

};

export default withRouter(CoelsaExport);