import React, {useEffect, useState, useRef} from "react";
import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import {Calendar} from "primereact/calendar";
import { Toast } from 'primereact/toast';
import CircularSpinner from '../../UI/CircularSpinner';

import ApiLoan from "../../service/ApiLoanService";

import { withRouter } from "react-router";

import {Dropdown} from "primereact/dropdown";

const ReportGeneralExport = props => {
    const [paymentMethods, setPaymentMethods] = useState(null);
    const [createPayOrdersLoading, setCreatePayOrdersLoading] = useState(false);
    const [installmentsCount, setInstallmentsCount] = useState(null);
    const [rangeDate, setRangeDate] = useState(null);
    const [paymentMethodId, setPaymentMethodId] = useState(null);
    const [disabledButton, setDisabledButton] = useState(true);
    const [disabledFormButton, setDisabledFormButton] = useState(true);

    useEffect(() => {
        getPaymentMethods();
    }, []);

    useEffect(() => {
        if(rangeDate){
            setDisabledButton(false);
        }else{
            setDisabledButton(true);
        }
    }, [rangeDate]);

    useEffect(() => {
        if(typeof(installmentsCount) != 'undefined'){
            if (installmentsCount > 0 && paymentMethodId){
                setDisabledFormButton(false);
            }
        }
    }, [installmentsCount, paymentMethodId]);

    const toast = useRef(null);

    const changePaymentMethod = (event) => {
        if(typeof(event.value)!='undefined'){
            if(installmentsCount > 0){
                setDisabledFormButton(false);
            }
        }
        setPaymentMethodId(event.value);
    }
    const changeRangeDates = (event) => {
        setRangeDate(event.value);
    };

    const getPaymentMethods = () => {
        let apiService = new ApiLoan();
        
        apiService.getResources({
            url: '/payment_methods',
        }).then(response => {

            const paymentMethods = response.data.objects.map((paymentMethod) => {
                return {
                    label: paymentMethod.description,
                    value: paymentMethod.id
                }
            })

            setPaymentMethods(paymentMethods);
        })
     };

    const getInstallmentsByDates = () => {
        setDisabledButton(true);

        let apiService = new ApiLoan();
        
        apiService.getResources({
            url: '/pay_orders/count',
            start_date: rangeDate[0],
            end_date: rangeDate[1]
        }).then(response => {
            setDisabledButton(false);
            setInstallmentsCount(response.data.count);
        }).catch(error => {
            const message_detail='Hubo un error al buscar la cuotas.'
            props.history.push({pathname: '/pay_orders', state: {message: message_detail}});
        });
     };

    const createPayOrderByDates = (event) => {
        event.preventDefault();
        setDisabledFormButton(true);
        setCreatePayOrdersLoading(true);
        let apiService = new ApiLoan();
        
        apiService.postResource({
            url: '/pay_orders',
            start_date: rangeDate[0],
            end_date: rangeDate[1],
            data: {
                payment_method_id: paymentMethodId
            }

        }).then(response => {
            const message_detail='Pagos creados con exito.'
            props.history.push({pathname: '/pay_orders', state: {message: message_detail}});
        }).catch(error => {
            if (error.response) {
                if(error.response.status === 409){
                    const errorMessage = error.response.data.message
                    setDisabledFormButton(false);
                    setCreatePayOrdersLoading(false);
                    toast.current.show({severity: 'error', summary: 'Ordenes de Pago', detail: errorMessage});
                }
              }
        });
    };


    const createPayOrderButton = createPayOrdersLoading ? (<CircularSpinner/>) : 
                                                        (<Button label={'Crear Órdenes de Pago'}
                                                            className="p-button-lg"
                                                            disabled={disabledFormButton}/>);
    const installmentForm = (<div className="col-12">
                                <div className="col-12">
                                    <span>Cantidad de cuotas: {installmentsCount}</span>
                                </div>
                                <div className="col-6">
                                    <div className="col-5">
                                        <Dropdown
                                            options={paymentMethods}
                                            value={paymentMethodId}
                                            onChange={changePaymentMethod}
                                            placeholder={'Método de pago'}
                                            autoWidth={false}
                                        />
                                    </div>
                                    <div className="col-5 mt-3">
                                        <form onSubmit={createPayOrderByDates}>
                                            {createPayOrderButton}
                                        </form>
                                    </div>
                                </div>
                            </div>);

    let form = null;
    form = (
        <div className="grid dashboard">
            <Toast ref={toast} />
            <div className="col-12 md-12 p-lg-12 p-fluid">
                    <Panel header="Crear Órdenes de Pago" style={{minHeight: '500px'}}>
                        <div className={'grid'}>
                            <div className="col-12">
                                    <span>Buscar por Fecha de vencimiento de cuota</span>
                            </div>
                            <div className="col-6">
                                <div className="col-5">
                                    <Calendar value={rangeDate}
                                            dateFormat="dd/mm/yy"
                                            onChange={(e) => changeRangeDates(e)}
                                            selectionMode="range"
                                            readonlyInput={true}
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            yearRange="1995:2030"/>
                                </div>
                                <div className="col-5">
                                    <Button label={'Buscar'}
                                            className="p-button-lg"
                                            disabled={disabledButton}
                                            onClick={getInstallmentsByDates}/>
                                </div>
                            </div>

                        {(installmentsCount != null) ? installmentForm : ''}
                        </div>
                    </Panel>            

            </div>
        </div>
    );


    return form;

};

export default withRouter(ReportGeneralExport);