import React, {useContext, useEffect, useRef, useState} from 'react';
import {Toast} from "primereact/toast";
import {Button} from "primereact/button";
import ApiLoan from "../../service/ApiLoanService";
import {InputSwitch} from "primereact/inputswitch";
import {MultiSelect} from "primereact/multiselect";
import {Dropdown} from "primereact/dropdown";
import { CompanyContext } from '../../context/companyContext';
import { useHistory } from 'react-router-dom';

const AccountSettings = (props) => {

    const [data, setData] = useState({
        private_inbox: false,
        auto_assign_inbox: false,
        private_resource: false,
        private_group: false,
        users: {
            value: null,
            options: []
        },
        provider_timezone: null
    });

    const [timezoneOptions, setTimezonesOptions] = useState([]);
    const {setPrefixTimezone, setProviderTimezone, setPrivateGroup, setPrivateInbox} = useContext(CompanyContext);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        const apiService = new ApiLoan();
        apiService.getResources({
            url: 'account/settings'
        }).then(response => {
            let keys = Object.keys(response.data)
            let newData = {...data}
            keys.map(x => {
                return newData[x] = response.data[x]
            })
            setData(newData)
        });
    }, [])

    useEffect(() => {
        const apiService = new ApiLoan();
        apiService.getResources({
            url: 'timezones'
        }).then(response => {
            const options = response.data.map((tz, index) => {
                return {
                    label: tz,
                    value: tz
                }
            });
            setTimezonesOptions(options);
        });
    }, []);

    useEffect(() => {
        const apiService = new ApiLoan();

        apiService.getResources({
            url: '/conversations/users'
        }).then(response => {
            const options = response.data.objects.map(user => {
                return {
                    id: user.id,
                    username: user.username,
                }
            });
            const values = response.data.objects.map(x => {
                if (x.inbox_available === true) {
                    return {
                        id: x.id,
                        username: x.username,
                    }
                }
            }).filter(x => x !== undefined);
            setData((prevState) => {
                let users = prevState.users;
                users.options = options;
                users.value = values;
                return {
                    ...prevState,
                    users: users
                }
            })
            setLoading(false)
        });
    }, []);

    useEffect(() => {
        if (!data.private_inbox){
            if (data.private_group){
                setData((prevState) => {
                    return {
                        ...prevState,
                        ['private_group']: false
                    }
                });
            }
        }
    }, [data.private_inbox])


    const handleSubmit = (event) => {
        const apiService = new ApiLoan();

        let newData = {...data}

        newData.users = newData.users.value
        if (data.users.value.length !== 0 || !data.auto_assign_inbox){
            apiService.patchResource({
                'url': '/account/settings',
                'data': newData
            }).then(response => {
                setPrefixTimezone(response?.data?.prefix_timezone || 'ar');
                setProviderTimezone(response?.data?.provider_timezone || 'America/Argentina/Buenos_Aires');
                setPrivateInbox(response?.data?.private_inbox);
                setPrivateGroup(response?.data?.private_group);
                if (response.status === 200){
                    refToast.current.show({severity: 'success', summary: 'Configuración de Cuenta',
                        detail: 'Se actualizó correctamente.'});
                    setTimeout(() => {
                        history.push('/')
                    }, 2000);
                }
        });
        }else{
            refToast.current.show({severity: 'error', summary: 'Error Asignador Automático',
                        detail: 'Seleccionar usuarios para el autoasignador automático.'});
        }
    }

    const refToast = useRef(null);

    const selectItemUser = (value) => {
        if (value) {
            return (
                <b className={"my-multiselected-item-token"}>{value.username}</b>
            );
        } else {
            return <span className="my-multiselected-empty-token">Seleccionar Usuarios</span>;
        }
    };

    const onChangeHandler = (event) => {
        let field = event.target.id
        let value = event.target.value

        setData((prevState) => {
            return {
                ...prevState,
                [field]: value
            }
        });
    }

    const onChangeMultiselectHandler = (event) => {
        let newData = {...data};
        newData.users.value = event.target.value;
        setData(newData);
    }

    return loading ? null : (
        <div className="grid">
            <div className="col-12 md-12">
                <div className="card p-fluid">
                    <h4>Configuración de cuenta</h4>
                    <Toast ref={refToast}/>
                    <div className="formgrid grid">
                        <div className="field col-12 inline-flex">
                            <div className="field col">
                                <label htmlFor="private_inbox">Bandeja privada</label>
                                <span className="p-float-label">
                                    <InputSwitch id="private_inbox" checked={data.private_inbox}
                                                 onChange={(e) => onChangeHandler(e)}
                                    />
                                </span>
                            </div>
                            <div className="field col">
                                <label htmlFor="private_group">Grupo privado</label>
                                <span className="p-float-label">
                                    <InputSwitch id="private_group" checked={data.private_group}
                                                 disabled={!data.private_inbox}
                                                 onChange={(e) => onChangeHandler(e)}
                                    />
                                </span>
                            </div>
                            <div className="field col">
                                <label htmlFor="private_resource">Recursos Privados</label>
                                <span className="p-float-label">
                                    <InputSwitch id="private_resource" checked={data.private_resource}
                                                 onChange={(e) => onChangeHandler(e)}
                                    />
                                </span>
                            </div>
                            <div className="field col">
                                <label htmlFor="auto_assign_inbox">Asignador Automático</label>
                                <span className="p-float-label">
                                    <InputSwitch id="auto_assign_inbox" checked={data.auto_assign_inbox}
                                                 onChange={(e) => onChangeHandler(e)}
                                    />
                                </span>
                            </div>
                        </div>

                        <div className="field col">
                            <div className="field col mt-3">
                                <p>Seleccionar usuarios disponibles para recibir mensajes por autoasignador</p>
                                <span className="p-float-label">
                                    <MultiSelect id="users"
                                                 className=""
                                                 value={data.users.value}
                                                 name='users' optionLabel="username"
                                                 options={data.users.options} filter
                                                 onChange={(e) => onChangeMultiselectHandler(e)}
                                                 placeholder="Usuarios"
                                                 selectedItemTemplate={selectItemUser}
                                                 resetFilterOnHide={true}
                                                 maxSelectedLabels={5}
                                                 selectedItemsLabel={'{0} usuarios seleccionados'}
                                                 disabled={!data.auto_assign_inbox}
                                    />
                                    <label htmlFor="users">Usuarios</label>
                                </span>
                            </div>
                        </div>
                        <div className="field col-12">
                            <div className="field col mt-3">
                                {/*<p>Configurar Zona Horaria</p>*/}
                                <span className="p-float-label">
                                    <Dropdown id="provider_timezone" value={data.provider_timezone}
                                              options={timezoneOptions} label={'label'} filter={true}
                                              // filterBy={}
                                              onChange={(e) => onChangeHandler(e)} />
                                    <label htmlFor="provider_timezone">Zona Horaria</label>
                                </span>





                            </div>
                        </div>
                        <div className="field col-12">
                            <Button label={'Guardar'} icon={'pi pi-plus'} className="ml-auto"
                                    onClick={(e) => handleSubmit(e)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default AccountSettings;