import React, { useState, useEffect, useRef, Fragment } from 'react';
import "../../../assets/css/SheetForm.css";
import { Toast } from "primereact/toast";
import ApiLoan from '../../../service/ApiLoanService';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

const SaveDataForm = (props) => {
    const [formValues, setFormValues] = useState({
        id: null, error: {}
    });
    const refToast = useRef(null);
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if (props.formData) {
            setFormValues({ id: props.formData.questionnaire_type_data_save_id })
            props.formData.questionnaire_type_data_save_id ? setEdit(true) : setEdit(false);
        }
    }, [props.formData]);

    const getFieldError = (errors, field) => {
        if (errors === undefined) {
            return
        }
        let form = null;

        if (Object.keys(errors).filter(x => x === field).length > 0) {
            form = (
                <small style={{ color: '#f44336' }} className="p-error">
                    {errors[field][0]}
                </small>
            );
        }

        return form;
    }

    const errorVariable = (data) => {
        let newFormValues = { ...formValues }
        newFormValues['error'] = data.errors;
        setFormValues(newFormValues);
    }

    const handleSubmit = () => {
        if (!formValues.id && !edit) {
            errorVariable({ errors: { name: ['Campo Obligatorio'] } })
        } else {
            if (Object.keys(formValues.error).length === 0) {
                props.setFormData(formValues);
                props.hideSaveData();
            }
        }
    }

    const updatesaveData = (e) => {
        let newFormValues = { ...formValues }
        newFormValues.id = e.value;
        newFormValues['error'] = {};
        setFormValues(newFormValues);
    }

    let form = (
        <div className="grid formgrid" style={{ position: 'relative', top: '20px', paddingBottom: '20px' }}>
            <div className="col-12 md-12">
                <Toast ref={refToast} />
                <div className="grid formgrid">
                    <div className="col-12">
                        <div className="col-12">
                            <div className="grid formgrid">
                                <div className="field col">
                                    <div className='p-inputgroup'>
                                        <span className="p-float-label space-right">
                                            <div className="field col">
                                                <span className="p-float-label">
                                                    <Dropdown className={formValues?.error?.name && "p-invalid"} placeholder="Dato" showClear value={formValues?.id} options={props.questionnaireTypeDataSaveOptions} onChange={(e) => { updatesaveData(e) }} />
                                                    <label>Dato</label>
                                                </span>
                                                {getFieldError(formValues.error, 'name')}
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='p-inputgroup'>
                                <Button label={props.formData.questionnaire_type_data_save_id !== null ? 'Guardar' : 'Crear'}
                                    onClick={handleSubmit} className="p-button-raised ml-auto" style={{ width: props.formData.question_variable_config.name !== null ? '50%' : '100%' }}
                                    icon={props.formData.question_variable_config.name !== null ? 'pi pi-pencil' : 'pi pi-plus'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    return form

}

export default SaveDataForm;