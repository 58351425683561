import React from 'react';
import { Skeleton } from 'primereact/skeleton';
import { ProgressBar } from 'primereact/progressbar';

export default function MessageSkeleton(props) {
    const { count } = props;
    const skeletons = [<div className="center" key={0}> <ProgressBar mode="indeterminate" /> </div>];

    for (let i = 0; i < count; i++) {
        skeletons.push(
            <div className="p-4">
                <div className="flex mb-3">
                    <div>
                        <Skeleton width="15rem" height="4rem" className="mb-2" borderRadius="16px"></Skeleton>
                        <Skeleton width="5rem" height="4rem" className="mb-2" borderRadius="16px"></Skeleton>
                        <Skeleton width="10rem" height="4rem" className="mb-2" borderRadius="16px"></Skeleton>
                    </div>
                </div>
                <div className="flex justify-content-center mt-3">
                    <Skeleton width="6rem" height="2rem" className="mb-2"></Skeleton>
                </div>
                <div className="flex justify-content-end mt-3">
                    <div>
                        <Skeleton width="15rem" height="4rem" className="mb-2" borderRadius="16px"></Skeleton>
                    </div>
                </div>
            </div>
        );
    }
    return skeletons;
}