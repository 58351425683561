import React, { useEffect, useState } from 'react';

import ApiLoan from '../../../service/ApiLoanService';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { saveAs } from 'file-saver';

var QRCode = require('qrcode.react');

const QrCodeView = ({client_id}) => {
    const [shortUrl, setShortUrl] = useState(null);
    const [qrcodeUrl, setQrcodeUrl] = useState(null);
    const [documentNumber, setDocumentNumber] = useState(null);
    const [copied, setCopied] = useState(false);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const apiService = new ApiLoan();

        apiService.getResource(({
            url: '/debtors/' + client_id + '/qrcode_url'
        })).then(response => {
            setQrcodeUrl(response.data.qrcode_url);
            setShortUrl(response.data.short_url);
            setDocumentNumber(response.data.document_number);
            setLoading(false);
        });
    },[client_id]);

    const downloadQrcode = () => {
        const apiService = new ApiLoan();

        apiService.getResourceBlob(({
            url: '/debtors/' + client_id + '/qrcode'
        })).then(response => {
            const filename = documentNumber.concat('.','png');
            saveAs(response.data, filename);
        });
    }

    let form = null;
    if (!loading){
        form = (
            <div className="col-12">
                <div className="flex p-jc-between mt-3">
                    <div className="col-2 p-offset-4">
                        <QRCode value={`https://${shortUrl}`} size={192}/>
                    </div>
                </div>
                <div className="flex p-jc-center mt-3">
                    <div className="field grid">
                        <CopyToClipboard text={`https://${qrcodeUrl}`} onCopy={() => setCopied(true)}>
                            <FontAwesomeIcon icon={"share-alt"} style={{background: '#31D1AEE5'}} color={'white'}
                                             size="xs" className={"btn-actions btn-fontawesome"} />
                        </CopyToClipboard>

                        <button className="p-link" onClick={() => downloadQrcode()}>
                            <FontAwesomeIcon icon={"fa-regular fa-download"} style={{background: '#0885C4'}} color={'white'}
                                             size="xs" className={"btn-actions btn-fontawesome p-button-info"} />
                        </button>
                    </div>
                </div>
                {copied ? <span className="mt-3" style={{"color": "red", "display": "block"}}>Copiado.</span> : null}
            </div>
        )
    }
    return form;
}

export default QrCodeView;
