import React, { Component } from 'react';
import ReceivableAmount from '../charts/ReceivableAmount';
import OperationsByDefaultStatuses from '../charts/OperationsByDefaultStatuses';
import DebtorsRank from '../charts/DebtorsRank';
import PaymentsByMonths from '../charts/PaymentsByMonths';

export class Dashboard extends Component {

    render(){
        return (
            <div className="grid dashboard">
                <ReceivableAmount/>
                <OperationsByDefaultStatuses/>
                <DebtorsRank/>
                <PaymentsByMonths/>
            </div>
        );

    }
}