import React, { Component, Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Layout from './layout/Layout';
import asyncComponent from './hoc/asyncComponent/asyncComponent';
import * as actions from './store/actions/index';
import { switchRole } from './shared/roles';
import { withRouter } from "react-router";
import './assets/css/App.css';
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './AppStyle.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { ProSidebarProvider } from 'react-pro-sidebar';
import CompanyContextProvider from './context/providers/CompanyContextProvider';

import Clients from "./components/Clients/Clients";
import ClientForm from "./components/Clients/Client/ClientForm";
import ClientMultiView from "./components/Clients/Client/ClientMultiView";
import ClientBulk from "./components/Clients/Client/ClientBulk";

import Audiences from "./components/Audiences/Audiences";
import AudienceView from "./components/Audiences/Audience/AudienceView";
import Avatar, { ConfigProvider } from 'react-avatar';
import Loans from "./components/Loans/Loans";
import Loan from "./components/Loans/Loan/Loan";
import LoanView from './components/Loans/Loan/LoanView';
import LoanBulk from "./components/Loans/Loan/LoanBulk";

import Providers from "./components/Providers/Providers";
import Provider from "./components/Providers/Provider/Provider";
import ProviderPayment from './components/Providers/Provider/ProviderPayment';
import ProviderPaymentInformationForm from './components/Providers/Provider/ProviderPaymentInformationForm';
import ProviderEdit from "./components/Providers/Provider/ProviderEdit";
import ProviderView from "./components/Providers/Provider/ProviderView";
import ProviderInfoEdit from "./components/Providers/Provider/ProviderInfoEdit";
import ProviderChannel from "./components/Providers/Provider/ProviderChannel";
import DefaultStatuses from "./components/DefaultStatuses/DefaultStatuses";
import DefaultStatus from "./components/DefaultStatuses/DefaultStatus/DefaultStatus";
import DefaultStatusEdit from "./components/DefaultStatuses/DefaultStatus/DefaultStatusEdit";
import DefaultStatusView from "./components/DefaultStatuses/DefaultStatus/DefaultStatusView";

import Notifications from "./components/Notifications/Notifications";
import NotificationView from "./components/Notifications/Notification/NotificationView";
import NotificationForm from "./components/Notifications/Notification/NotificationForm";

import EmailSetting from "./components/EmailSettings/EmailSetting";
import EmailSettingForm from "./components/EmailSettings/EmailSetting/EmailSettingForm";
import EmailSettingView from "./components/EmailSettings/EmailSetting/EmailSettingView";

import SmsSettings from "./components/SmsSettings/SmsSettings";
import SmsSettingForm from "./components/SmsSettings/SmsSetting/SmsSettingForm";
import SmsSettingView from "./components/SmsSettings/SmsSetting/SmsSettingView";

import CustomTags from "./components/CustomTags/CustomTags";
import CustomTagForm from "./components/CustomTags/CustomTag/CustomTagForm";
import CustomTagView from "./components/CustomTags/CustomTag/CustomTagView";

import ReportNotification from "./components/Reports/ReportNotification";
import ReportGeneralExportView from "./components/Reports/GeneralExport/ReportGeneralExportView"
import ReportGeneralExportEdit from "./components/Reports/GeneralExport/ReportGeneralExportEdit";
import ReportGeneralExportTable from "./components/Reports/ReportGeneralExportDataTable";
import ReportGeneralExport from "./components/Reports/GeneralExport/ReportGeneralExport";
import ReportClientExport from './components/Reports/ClientExport/ReportClientExport';
import CoelsaExport from "./components/Reports/CoelsaExport";

import Channels from "./components/Channels/Channels";
import Channel from "./components/Channels/Channel/Channel";
import ChannelEdit from "./components/Channels/Channel/ChannelEdit";
import ChannelView from "./components/Channels/Channel/ChannelView";

import Rates from "./components/Rates/Rates";
import Rate from "./components/Rates/Rate/Rate";
import RateEdit from "./components/Rates/Rate/RateEdit";
import RateView from "./components/Rates/Rate/RateView";

import Users from "./components/Users/Users";
import UserView from "./components/Users/User/UserView";
import Profile from "./components/Users/User/Profile";

import PayOrderBulk from "./components/PayOrders/PayOrderBulk";
import InstallmentOrders from "./components/PayOrders/InstallmentOrders";
import PayOrders from "./components/PayOrders/PayOrders";
import PayOrderCreateByDate from "./components/PayOrders/PayOrderCreateByDate";
import DebitPayOrders from "./components/PayOrders/DebitPayOrders";
import DebitPayOrderSingle from "./components/PayOrders/DebitPayOrderSingle";
import MessengerComponent from "./components/Messenger/MessengerComponent";

import Transactions from "./components/Transactions/Transactions";
import Withdrawal from "./components/Transactions/Withdrawal";
import TransactionRequests from "./components/Coelsa/TransactionRequests";
import TransactionLogsCredit from "./components/Coelsa/TransactionLogsCredit";
import TransactionLogsDebit from "./components/Coelsa/TransactionLogsDebit";
import UploadCoelsaFile from "./components/Coelsa/UploadCoelsaFile";
import UploadRejectedCoelsaFile from "./components/Coelsa/UploadRejectedCoelsaFile";
import Logout from './containers/Auth/Logout/Logout';

import {SecretRoute} from "./SecretRoute";
import {Dashboard} from "./components/Dashboard";

import PayOrderSingle from "./components/PayOrders/PayOrderSingle";
import WhatsAppAnalytics from "./components/Analytics/WhatsAppAnalytics";

import autoReplies from "./components/AutoReplies/AutoReplies";
import autoReplyForm from "./components/AutoReplies/AutoReplyForm";

import ProviderWebhookForm from "./components/ProviderWebhook/ProviderWebhookForm"
import ProviderWebhooks from "./components/ProviderWebhook/ProviderWebhooks";
import ProviderWebhookView from "./components/ProviderWebhook/ProviderWebhookView";
import BackgroundTasksBulk from "./components/BackgroundTasksBulk/BackgroundTasksBulk";
import ChatBots from "./components/ChatBot/ChatBots";
import ChatBotForm from "./components/ChatBot/ChatBotForm";
import ChatBotView from "./components/ChatBot/ChatBotView";
import Questionnaires from "./components/ChatBot/Questionnaires/Questionnaires";
import QuestionnaireForm from "./components/ChatBot/Questionnaires/QuestionnaireForm";

import WhatsappWeb from "./components/WhatsappWeb/WhatsappWeb"
import QuestionnaireView from "./components/ChatBot/Questionnaires/QuestionnaireView";
import AccountSettings from "./components/AccountSettings/AccountSettings";
import UserForm from "./components/Users/User/UserForm";
import CoelsaHolidays from "./components/Coelsa/CoelsaHolidays";
import CoelsaHolidaysForm from "./components/Coelsa/CoelsaHolidaysForm";

import Groups from "./components/Groups/Groups";
import GroupForm from "./components/Groups/GroupForm";
import { pastelColors } from './utils/utils';

import ProviderScheduleds from './components/ProviderScheduled/ProviderScheduled';
import ProviderScheduledForm from './components/ProviderScheduled/ProviderScheduledForm';
import AiAssistants from './components/AiAssistants/AiAssistants';
import AiAssistantForm from './components/AiAssistants/AiAssistant/AiAssistantForm';
import AiAssistantView from './components/AiAssistants/AiAssistant/AiAssistantView';

import ChatChannelContainer from './components/ChatChannelContainer/ChatChannelContainer';
import InternalGroups from './components/InternalGroups/InternalGroups';
import InternalGroupForm from './components/InternalGroups/InternalGroupForm';
import ChatBotsPush from './components/ChatBot/ChatBotsPush';

const asyncAuth = asyncComponent(() => {
    return import('./containers/Auth/Auth');
});

const asyncClientPayment = asyncComponent(() => {
    return import('./components/Clients/Client/ClientPayment');
});
const asyncQrCode = asyncComponent(() => {
    return import('./components/Clients/QrCode/QrCodeDownload');
});



library.add(fas, fab)

class App extends Component {

    constructor(){
        super();
        this.state = { isMobile: false };
    }

    componentDidMount () {
        this.props.onTryAutoSignup();
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ isMobile: window.innerWidth <= 1024 });
    };

    render(){
        const token = localStorage.getItem('token');

        let routes = (
            <Switch>
                <Route path="/auth" component={asyncAuth} />
                <Route path="/deudores/pagar/:id" component={asyncClientPayment} />
                <Route path="/qrcode/:id" component={asyncQrCode} />

                <Route path='/default' component={() => {window.location = 'http://prontocobro.com'; return null;} }/>
            </Switch>
        );

        let indexHome = Dashboard;

        if (switchRole() === 'audience'){
            indexHome = ChatChannelContainer;
        } else if (switchRole() === 'manager') {
            indexHome = CoelsaExport;
        } else if (switchRole() === 'payment_order_creator'){
            indexHome = DebitPayOrders;
        } else if (switchRole() === 'texter' || switchRole() === 'customer_support' || switchRole() === 'supervisor' ){
            indexHome = ChatChannelContainer;
        } else if (process.env.REACT_APP_PAGE === 'pip-app'){
            indexHome = Audiences;
        }

        if (token){
            routes = (
                <Switch>
                    <SecretRoute path="/" exact component={indexHome} allowed={['admin','provider','audience','collector','manager', 'payment_order_creator', 'texter','supervisor', 'customer_support']}/>
                    <SecretRoute path="/transactions" exact component={Transactions} allowed={['admin','provider','audience','collector','payment_order_creator','supervisor']}/>
                    <SecretRoute path="/withdrawal" exact component={Withdrawal} allowed={['admin','provider','audience','collector','payment_order_creator','supervisor']}/>
                    <SecretRoute path="/logout" component={Logout} allowed={['admin','provider','client','audience','collector','manager', 'payment_order_creator','texter','supervisor','customer_support']}/>

                    <SecretRoute path="/debtors" exact component={Clients} allowed={['admin','provider','collector']}/>
                    <SecretRoute path="/debtors/new" exact component={ClientForm} allowed={['admin','provider','collector']} />
                    <SecretRoute path="/debtors/bulk" exact component={ClientBulk} allowed={['admin','provider', 'audience','collector','supervisor']} />
                    <SecretRoute path="/debtors/loans/bulk" exact component={LoanBulk} allowed={['admin','provider','collector']} />
                    <SecretRoute path="/debtor/:id/edit" component={ClientForm} allowed={['admin','provider','collector']} />
                    <SecretRoute path="/debtor/:id" exact component={ClientMultiView} allowed={['admin','provider','collector']} />

                    <SecretRoute path="/audiences" exact component={Audiences} allowed={['admin','provider','audience','collector', 'payment_order_creator', 'texter','supervisor', 'customer_support']}/>
                    <SecretRoute path="/audiences/new" exact component={ClientForm} allowed={['admin','provider','audience','collector', 'payment_order_creator', 'texter','supervisor']} />
                    <SecretRoute path="/audience/:id/edit" component={ClientForm} allowed={['admin','provider','audience','collector', 'payment_order_creator', 'texter','supervisor', 'customer_support']} />
                    <SecretRoute path="/audience/:id" exact component={AudienceView} allowed={['admin','provider','audience','collector', 'payment_order_creator', 'texter','supervisor', 'customer_support']} />

                    <SecretRoute path="/clients" exact component={Providers} allowed={['admin']} />
                    <SecretRoute path="/clients/new" exact component={Provider} allowed={['admin']} />
                    <SecretRoute path="/clients/bulk" exact component={ClientBulk} allowed={['texter', 'supervisor']} />
                    <SecretRoute path="/clients/payments" exact component={ProviderPayment} allowed={['admin', 'provider','collector','supervisor']} />
                    <SecretRoute path="/clients/payment_information" exact component={ProviderPaymentInformationForm} allowed={['provider','collector','supervisor']} />
                    <SecretRoute path="/client/info" exact component={ProviderInfoEdit} allowed={['provider','collector', 'payment_order_creator', 'texter','supervisor']} />
                    <SecretRoute path="/client/:id/channels" exact component={ProviderChannel} allowed={['admin']} />

                    <SecretRoute path="/client/:id/edit" component={ProviderEdit} allowed={['admin']} />
                    <SecretRoute path="/client/:id" exact component={ProviderView} allowed={['admin']} />

                    <SecretRoute path="/ai_assistants" exact component={AiAssistants} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/ai_assistants/new" exact component={AiAssistantForm} allowed={['admin','supervisor']} />
                    <SecretRoute path="/ai_assistant/:id/edit" exact component={AiAssistantForm} allowed={['admin','supervisor']} />
                    <SecretRoute path="/ai_assistant/:id" exact component={AiAssistantView} allowed={['admin', 'supervisor']} />

                    <SecretRoute path="/default-statuses" exact component={DefaultStatuses} allowed={['admin','provider','collector','supervisor']} />
                    <SecretRoute path="/default-statuses/new" exact component={DefaultStatus} allowed={['admin','provider','collector','supervisor']} />
                    <SecretRoute path="/default-status/:id/edit" component={DefaultStatusEdit} allowed={['admin','provider','collector','supervisor']} />
                    <SecretRoute path="/default-status/:id" exact component={DefaultStatusView} allowed={['admin','provider','collector','supervisor']} />

                    <SecretRoute path="/loans" exact component={Loans} allowed={['admin']} />
                    <SecretRoute path="/loans/new" exact component={Loan} allowed={['admin','provider','collector','supervisor']}/>
                    <SecretRoute path="/loan/:id" exact component={LoanView} allowed={['admin', 'provider','collector','supervisor']} />

                    <SecretRoute path="/email_setting" exact component={EmailSetting} allowed={['admin','provider','collector','supervisor']} />
                    <SecretRoute path="/email_setting/new" exact component={EmailSettingForm} allowed={['admin','provider','collector','supervisor']} />
                    <SecretRoute path="/email_settings/:id/edit" exact component={EmailSettingForm} allowed={['admin','provider','collector','supervisor']} />
                    <SecretRoute path="/email_settings/:id" exact component={EmailSettingView} allowed={['admin','provider','collector','supervisor']} />


                    <SecretRoute path="/sms_settings" exact component={SmsSettings} allowed={['admin']} />
                    <SecretRoute path="/sms_settings/new" exact component={SmsSettingForm} allowed={['admin']} />
                    <SecretRoute path="/sms_setting/:id/edit" exact component={SmsSettingForm} allowed={['admin']} />
                    <SecretRoute path="/sms_setting/:id" exact component={SmsSettingView} allowed={['admin']} />

                    <SecretRoute path="/custom_tags" exact component={CustomTags} allowed={['admin','provider','audience','collector', 'payment_order_creator','supervisor']} />
                    <SecretRoute path="/custom_tags/new" exact component={CustomTagForm} allowed={['admin','provider','audience','collector', 'payment_order_creator','supervisor']} />
                    <SecretRoute path="/custom_tags/:id/edit" exact component={CustomTagForm} allowed={['admin','provider','audience','collector', 'payment_order_creator','supervisor']} />
                    <SecretRoute path="/custom_tags/:id" exact component={CustomTagView} allowed={['admin','provider','audience','collector', 'payment_order_creator','supervisor']} />

                    <SecretRoute path="/notifications" exact component={Notifications} allowed={['admin','provider','audience','collector','supervisor']} />
                    <SecretRoute path="/notifications/new" exact component={NotificationForm} allowed={['admin','provider','audience','collector','supervisor']} />
                    <SecretRoute path="/notification/:id/edit" component={NotificationForm} allowed={['admin','provider','audience','collector','supervisor']} />
                    <SecretRoute path="/notification/:id" exact component={NotificationView} allowed={['admin','provider','audience','collector','supervisor']} />

                    <SecretRoute path="/reports/notification" exact component={ReportNotification} allowed={['admin','provider','audience','collector','supervisor']} />

                    <SecretRoute path="/reports/export" exact component={ReportGeneralExportTable} allowed={['admin','provider','audience','collector','supervisor']} />
                    <SecretRoute path="/reports/export/new" exact component={ReportGeneralExport} allowed={['admin','provider','audience','collector','supervisor']} />
                    <SecretRoute path="/reports/export/:id/edit" exact component={ReportGeneralExportEdit} allowed={['admin','provider','audience','collector','supervisor']} />
                    <SecretRoute path="/reports/export/:id" exact component={ReportGeneralExportView} allowed={['admin','provider','audience','collector','supervisor']} />
                    <SecretRoute path="/reports/client" exact component={ReportClientExport} allowed={['supervisor']} />

                    <SecretRoute path="/reports/coelsa" exact component={CoelsaExport} allowed={['admin','manager']}/>
                    <SecretRoute path="/coelsa/transactions" exact component={TransactionRequests} allowed={['admin','collector','manager', 'payment_order_creator']}/>
                    <SecretRoute path="/coelsa/logs/credit" exact component={TransactionLogsCredit} allowed={['admin','manager']}/>
                    <SecretRoute path="/coelsa/logs/debit" exact component={TransactionLogsDebit} allowed={['admin','manager']}/>
                    <SecretRoute path="/coelsa/upload/debit" exact component={UploadCoelsaFile} allowed={['admin','manager']}/>
                    <SecretRoute path="/coelsa/rejected/debit" exact component={UploadRejectedCoelsaFile} allowed={['admin','manager']}/>
                    <SecretRoute path="/coelsa/holidays" exact component={CoelsaHolidays} allowed={['manager']}/>
                    <SecretRoute path="/coelsa/holidays/new" exact component={CoelsaHolidaysForm} allowed={['manager']}/>

                    <SecretRoute path="/channels" exact component={Channels} allowed={['admin']} />
                    <SecretRoute path="/channels/new" exact component={Channel} allowed={['admin']} />
                    <SecretRoute path="/channel/:id/edit" component={ChannelEdit} allowed={['admin']} />
                    <SecretRoute path="/channel/:id" exact component={ChannelView} allowed={['admin']} />

                    <SecretRoute path="/rates" exact component={Rates} allowed={['admin']} />
                    <SecretRoute path="/rates/new" exact component={Rate} allowed={['admin']} />
                    <SecretRoute path="/rate/:id/edit" component={RateEdit} allowed={['admin']} />
                    <SecretRoute path="/rate/:id" exact component={RateView} allowed={['admin']} />

                    <SecretRoute path="/users" exact component={Users} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/users/new" exact component={UserForm} allowed={['admin','supervisor']} />
                    <SecretRoute path="/user/:id/edit" exact component={UserForm} allowed={['admin','supervisor']} />
                    <SecretRoute path="/user/:id" exact component={UserView} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/account/settings" exact component={AccountSettings} allowed={['supervisor']} />

                    <SecretRoute path="/pay_orders/upload" exact component={PayOrderBulk} allowed={['admin','collector', 'payment_order_creator']} />
                    <SecretRoute path="/pay_orders/new" exact component={PayOrderCreateByDate} allowed={['collector']} />
                    <SecretRoute path="/pay_orders/single" exact component={PayOrderSingle} allowed={['collector']} />
                    <SecretRoute path="/pay_orders/debit/single" exact component={DebitPayOrderSingle} allowed={['payment_order_creator']} />

                    <SecretRoute path="/pay_orders" exact component={PayOrders} allowed={['admin','collector']} />
                    <SecretRoute path="/debit_pay_orders" exact component={DebitPayOrders} allowed={['admin','payment_order_creator']} />
                    <SecretRoute path="/installment_orders" exact component={InstallmentOrders} allowed={['admin','collector']} />

                    <SecretRoute path="/profile" exact component={Profile} allowed={['admin','provider','audience','collector','manager', 'payment_order_creator', 'texter','supervisor', 'customer_support']} />
                    <SecretRoute path="/analytics/whatsapp" exact component={WhatsAppAnalytics} allowed={['admin','supervisor']} />

                    <SecretRoute path="/auto_replies" exact component={autoReplies} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/auto_replies/edit" exact component={autoReplyForm} allowed={['admin', 'supervisor']} />

                    <SecretRoute path="/provider_webhooks" exact component={ProviderWebhooks} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/setting_whatsapp" exact component={WhatsappWeb} allowed={['admin', 'supervisor', 'audience']} />
                    <SecretRoute path="/provider_webhooks/new" exact component={ProviderWebhookForm} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/provider_webhooks/:id/edit" exact component={ProviderWebhookForm} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/provider_webhooks/:id" exact component={ProviderWebhookView} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/provider_scheduled" exact component={ProviderScheduleds} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/provider_scheduled/:action" exact component={ProviderScheduleds} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/provider_scheduled/new" exact component={ProviderScheduledForm} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/provider_scheduled/new/:action" exact component={ProviderScheduledForm} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/provider_scheduled/:id/edit/:action" exact component={ProviderScheduledForm} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/provider_scheduled/:id/edit" exact component={ProviderScheduledForm} allowed={['admin', 'supervisor']} />

                    <Route path="/deudores/pagar/:id" component={asyncClientPayment} />

                    <SecretRoute path="/tasks" exact component={BackgroundTasksBulk} allowed={['admin', 'supervisor']} />

                    <SecretRoute path="/chat_bots/push" exact component={ChatBotsPush} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/chat_bots" exact component={ChatBots} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/chat_bots/new" exact component={ChatBotForm} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/chat_bots/:id/edit" exact component={ChatBotForm} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/chat_bots/:id" exact component={ChatBotView} allowed={['admin', 'supervisor']} />

                    <SecretRoute path="/questionnaires" exact component={Questionnaires} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/questionnaires/new" exact component={QuestionnaireForm} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/questionnaires/:id/edit" exact component={QuestionnaireForm} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/questionnaires/:id" exact component={QuestionnaireView} allowed={['admin', 'supervisor']} />

                    <SecretRoute path="/internal_groups" exact component={InternalGroups} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/internal_groups/new" exact component={InternalGroupForm} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/internal_groups/:id/edit" exact component={InternalGroupForm} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/groups" exact component={Groups} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/groups/new" exact component={GroupForm} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/groups/:id/edit" exact component={GroupForm} allowed={['admin', 'supervisor']} />
                    <SecretRoute path="/whatsapp/contact/:phone_number" component={MessengerComponent} allowed={['admin','provider','client','audience','collector','texter','supervisor','customer_support']}/>
                    <SecretRoute path="/loans/debtor/:client_id" component={asyncClientPayment} allowed={['admin','provider','collector','supervisor']} />
                    <Route path='/default' component={() => {window.location = 'http://prontocobro.com'; return null;} }/>

                    <Redirect to="/" />
                </Switch>
            );
        }

        return (
            <CompanyContextProvider>
                <ProSidebarProvider>
                    <ConfigProvider colors={pastelColors}>
                        <Layout key={localStorage.getItem('role')}>
                        {routes}
                        {this.props.children}
                        </Layout>
                    </ConfigProvider>
                </ProSidebarProvider>
            </CompanyContextProvider>
        );
    }

}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch( actions.authCheckState() )
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
