import React, {useState, useEffect, useRef, Fragment, useMemo} from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import {Checkbox} from 'primereact/checkbox';
import {Toast} from "primereact/toast";
import { Calendar } from 'primereact/calendar';
import { generateCronExpression } from '../../../utils/utils';
import { BUSINESS } from '../../../shared/ProviderScheduledType';
import ApiLoan from '../../../service/ApiLoanService';
import TemplateOverlay from "../../Templates/TemplateOverlay";
import BusinessSheetColumns from "./BusinessSheetColumns";

const BusinessForm = (props) => {
    const [action, setAction] = useState('create');

    const integrationTypeOptions = [
        { value: 'api', label: 'API' },
        { value: 'sheet', label: 'Sheet' }
    ];

    const [active, setActive] = useState(false);

    const [integrationFields, setIntegrationFields] = useState({
        api: {
            urlPage: '',
            platformId: '',
            method: null,
            authorization: {
                authorization_type: null,
                value: ''
            }
        },
        sheet: {
            urlPage: '',
            sheetName: '',
            columns: [],
            templateType: null
        },
        integrationType: 'api'
    });

    const [validAuthorization, setValidAuthorization] = useState(false);
    const [loadingTest, setLoadingTest] = useState(false);
    const [time, setTime] = useState('');
    const [frecuencyTime, setFrecuencyTime] = useState('30');
    const [frecuencyTimeOptions, setFrecuencyTimeOptions] = useState([]);
    const methodOptions = [
        { value: 'POST', label: 'POST' },
        { value: 'PATCH', label: 'PATCH' }
    ];
    const [showTemplateWhatsappDialog, setShowTemplateWhatsappDialog] = useState(false);
    const [showSheetFieldsDialog, setShowSheetFieldsDialog] = useState(false);
    const providerScheduledAction = BUSINESS;
    const [errors, setErrors] = useState({})
    const [disableSubmit, setDisableSubmit] = useState(true)

    useEffect(() => {
        let time_options = [];

        let time_labels = {
            '10': '10 minutos',
            '20': '20 minutos',
            '30': '30 minutos',
            '60': '1 hora',
            '120': '2 horas',
            '180': '3 horas',
            '300': '5 horas',
            '480': '8 horas',
        }

        for (let key in time_labels) {
            time_options.push({
                'value':  key,
                'label': time_labels[key]
            });
        }
        setFrecuencyTimeOptions(time_options);
    }, []);

    useEffect(() => {
        let form_errors = {}
        if (time === ''){
            form_errors['time'] = 'Hora Inválida'
        }
        if (frecuencyTime === ''){
            form_errors['frecuency'] = 'Frecuencia Inválida'
        }

        let valid = true
        Object.values(form_errors).map(x => {
            if (Object.values(x).length > 0){
                valid = false;
            }
            return x
        });

        if (valid){
            if (!validAuthorization){
                valid = false;
            }
        }

        setErrors(form_errors);
        setDisableSubmit(!valid);
    }, [time, frecuencyTime, validAuthorization, integrationFields.integrationType]);

    useEffect(() => {
        let _valid = true
        let formErrors = {}

        let _fields = integrationFields[integrationFields.integrationType]
        let _api_fields = ['urlPage', 'platformId', 'method', 'authorization']
        let _sheet_fields = ['urlPage', 'sheetName']
        let _valid_fields = integrationFields.integrationType === 'api' ? _api_fields : _sheet_fields

        _valid_fields.map(x => {
            if (_fields[x] === '' || _fields[x] === null){
                _valid = false;
                formErrors[x] = 'Campo Inválido';
            }

            return x
        });

        setErrors(formErrors)
        setDisableSubmit(!_valid);

    }, [integrationFields.integrationType, integrationFields.api, integrationFields.sheet]);

    useEffect(() => {
        let apiService = new ApiLoan();
        let edit = props.match.params.id !== undefined;
        if (edit){
            apiService.getResource({
                url: '/provider_scheduled/',
                resource_id: props.match.params.id
            }).then(response => {
                const data = response.data;
                setActive(data.active);
                let _body = JSON.parse(data.body)
                if (_body) {
                    setTime(new Date(_body['time']));
                    setFrecuencyTime(_body['frecuency_time']);
                    let integration_type = _body['integration_type']
                    let _authorization = JSON.parse(data.authorization)
                    if (integration_type === 'api') {
                        setIntegrationFields((prevState) => {
                            return {
                                ...prevState,
                                integrationType: integration_type,
                                [integration_type]: {
                                    urlPage: data.url,
                                    platformId: _body['platform_id'],
                                    method: data.method,
                                    authorization: {
                                        authorization_type: _authorization?.authorization_type === undefined ? null : _authorization?.authorization_type,
                                        value: _authorization?.value === undefined ? '' : _authorization?.value
                                    }
                                }
                            }
                        })
                    } else {
                        setIntegrationFields((prevState) => {
                            return {
                                ...prevState,
                                integrationType: integration_type,
                                [integration_type]: {
                                    urlPage: data.url,
                                    sheetName: _body['sheet_name'],
                                    columns: _body['columns'],
                                    templateType: _body['template_type']
                                }
                            }
                        })
                    }
                }
            });
            setAction('edit');
        } else {
            apiService.getResources({
                url: '/provider_scheduled',
                filters: [
                    {"name": "provider_scheduled_action_id", "op": "eq", "val": providerScheduledAction}
                ]
            }).then(response => {
                if (response.data.objects.length === 1){
                    refToast.current.show({severity: 'error', summary: 'Integración Business',
                        detail: 'Ya existe una configuración de la Integración Business.'});
                    setTimeout(() => {
                        props.history.push('/provider_scheduled')
                    }, 3000);
                }
            });
        }
    }, []);


    useEffect(() => {
        let _valid = false
        if (integrationFields.api.authorization.authorization_type === 'Basic'){
            if (integrationFields.api.authorization.user !== ''){
                _valid = true;
            }
        } else if (integrationFields.api.authorization.authorization_type === 'Bearer'){
            if (integrationFields.api.authorization.value !== ''){
                _valid = true;
            }
        } else {
            _valid = integrationFields.api.authorization.authorization_type == null;
        }

        setValidAuthorization(_valid)
    }, [integrationFields.api.authorization]);

    const authorizationOptions = [
        { value: 'Basic', label: 'Basic' },
        { value: 'Bearer', label: 'Bearer' }
    ];

    const testHandleSubmit = (event) => {
        event.preventDefault();
        setLoadingTest(true);
        let detail = 'Se ejecutó correctamente.'

        const apiService = new ApiLoan();
        const formData = {}

        apiService.postResource({
            'url': '/provider/scheduled/test/ping',
            'data': formData
        }).then(response => {
            if (response?.data?.message !== undefined) {
                detail = response?.data?.message
            }
            refToast.current.show({severity: 'success', summary: 'Integración Business', detail: detail});
        }).catch(error => {
            let error_message = error?.response?.data?.message;
            if (typeof(error?.response?.data?.message) === 'object'){
                error_message =  'Verifique los datos del formulario.'
            }
            refToast.current.show({severity: 'error', summary: 'Integración Business',
                detail: error_message});
        }).finally(() => {setLoadingTest(false)} );
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const apiService = new ApiLoan();

        let formData = {
            'provider_scheduled_action_id': providerScheduledAction,
            'provider_scheduled_period_id': null,
            'active': active,
            'frecuency': time === '' ? null : generateCronExpression(time, frecuencyTime)
        }

        if (integrationFields.integrationType === 'api'){
            formData['body'] = {
                'platform_id': integrationFields.api.platformId,
                'frecuency_time': frecuencyTime,
                'time': time,
                'integration_type': 'api'
            }
            formData['url'] = integrationFields.api.urlPage;
            formData['method'] = integrationFields.api.method
            formData['authorization'] = integrationFields.api.authorization;
        } else {
            formData['body'] = {
                'sheet_name': integrationFields.sheet.sheetName,
                'columns': integrationFields.sheet.columns,
                'template_type': integrationFields.sheet.templateType,
                'frecuency_time': frecuencyTime,
                'time': time,
                'integration_type': 'sheet'
            }
            formData['url'] = integrationFields.sheet.urlPage;
            formData['method'] = 'GET';
            formData['authorization'] = {
                authorization_type: null,
                value: ''
            };
        }

        let api_request = null;
        let url = 'provider_scheduled';
        if (action === 'create'){
            api_request = apiService.postResource;
        } else {
            api_request = apiService.patchResource;
            url = url + '/' + props.match.params.id
        }

        api_request({
            'url': url,
            'data': formData
        }).then(response => {
            refToast.current.show({severity: 'success', summary: 'Integración Business', detail: 'Se creó correctamente.'});
            props.history.push('/')
        }).catch(error => {
            let error_message = error?.response?.data?.message;

            if (typeof(error.response.data.message) === 'object'){
                error_message =  'Verifique los datos del formulario.'
            }
            refToast.current.show({severity: 'error', summary: 'Integración Business',
                detail: error_message});
        });
    }

    const getFieldError = (field) => {
        let form = null;
        if (errors[field]){
            form = <small style={{color:'#f44336'}} className="p-error"> {errors[field]} </small>
        }
        return form;
    }

    const selectTemplateWhatsappHandler = (e) => {
        setShowTemplateWhatsappDialog(true)
    }

    const selectSheetFields = (e) => {
        setShowSheetFieldsDialog(true)
    }

    const updateAttribute = (value, field, setField) => {
        setField(value)
        let form_errors = {...errors}
        if (form_errors[field]){
            delete form_errors[field]
        }
        setErrors(form_errors)
    }

    const updateTimeHandler = (value) => {
      const date = value;
      date.setMinutes(0); // Asegura que los minutos siempre sean 0
      updateAttribute(date, 'time', setTime);
    };

    const updateField = (value, field) => {
        if (field === 'integrationType'){
            setIntegrationFields((prevState) => {
                return {
                    ...prevState,
                    integrationType: value,
                    api: {
                        urlPage: '',
                        platformId: '',
                        method: null,
                        authorization: {
                            authorization_type: null,
                            value: ''
                        }
                    },
                    sheet: {
                        urlPage: '',
                        sheetName: '',
                        columns: [],
                        templateType: 'template_uuid'
                    },
                }
            });
        } else {
            setIntegrationFields((prevState) => {
                return {
                    ...prevState,
                    [integrationFields.integrationType]: {
                        ...prevState[integrationFields.integrationType],
                        [field]: value
                    }
                }
            })
        }
    }

    const handleAuthorizationType = (value) => {
        let _value = value === 'Basic' ? {user: '', password: ''} : '';

        setIntegrationFields((prevState) => {
            return {
                ...prevState,
                api: {
                    ...prevState.api,
                    authorization: {
                        authorization_type: value === undefined ? null : value,
                        value: _value
                    }
                }
            }
        });
    }

    const handleAuthorizationValue = (authorization_type, field, value) => {
        let _authorization = {...integrationFields.api.authorization.value}
        if (authorization_type === 'Basic'){
            if (field === 'user'){
                _authorization.user = value;
            } else {
                _authorization.password = value;
            }
        } else if (authorization_type === 'Bearer'){
            _authorization = value;
        } else {
            _authorization = null;
        }

        setIntegrationFields((prevState) => {
            return {
                ...prevState,
                api: {
                    ...prevState.api,
                    authorization: {
                        authorization_type: authorization_type,
                        value: _authorization
                    }
                }
            }
        });
    }

    const getAuthorizationBasic = () => {
        return (
            <Fragment>
                <div className="field col">
                    <span className="p-float-label">
                        <InputText id="inputtext" value={integrationFields.api.authorization.value.user}
                                           onChange={(e) => handleAuthorizationValue('Basic', 'user', e.target.value)} />
                        <label htmlFor="inputtext">Usuario</label>
                    </span>
                </div>
                <div className="field col">
                    <span className="p-float-label">
                        <InputText id="inputtext" value={integrationFields.api.authorization.value.password}
                                           onChange={(e) => handleAuthorizationValue('Basic', 'password', e.target.value)} />
                        <label htmlFor="dropdown">Contraseña</label>
                    </span>
                </div>
            </Fragment>
        )
    }

    const getAuthorizationBearer = () => {
        return (
            <div className="field col">
                <span className="p-float-label">
                    <InputText id="inputtext" value={integrationFields.api.authorization.value}
                                       onChange={(e) => handleAuthorizationValue('Bearer', null, e.target.value)} />
                    <label htmlFor="dropdown">Token</label>
                </span>
            </div>
        )
    }

    const getAuthorizationForm = () => {
        let form = null;
        if (integrationFields.api.authorization.authorization_type === 'Basic') {
            form = getAuthorizationBasic();
        } else if (integrationFields.api.authorization.authorization_type === 'Bearer'){
            form = getAuthorizationBearer();
        }
        return form;
    }

    const refToast = useRef(null);
    const labelSubmit = props.match.params.id !== undefined ? 'Guardar' : 'Crear';

    const apiFields = useMemo(() => {
        return (
            <Fragment>
                <div className="field col-5">
                    <span className="p-float-label">
                        <InputText id="inputtext" value={integrationFields.api.urlPage}
                                   onChange={(e) => updateField(e.target.value, 'urlPage')}/>
                        <label htmlFor="inputtext">Url</label>
                    </span>
                    {getFieldError('urlPage')}
                </div>
                <div className="field col-5">
                    <span className="p-float-label">
                        <InputText id="inputtext" value={integrationFields.api.platformId}
                                   required={true}
                                   onChange={(e) => updateField(e.target.value, 'platformId')}/>
                        <label htmlFor="inputtext">ID Pip</label>
                    </span>
                    {getFieldError('platformId')}
                </div>
                <div className="field col-2">
                    <span className="p-float-label">
                        <Dropdown id="dropdown" value={integrationFields.api.method} options={methodOptions}
                                  onChange={(e) => updateField(e.value, 'method')}/>
                        <label htmlFor="dropdown">Método</label>
                    </span>
                    {getFieldError('method')}
                </div>
                <div className="field col-2">
                    <span className="p-float-label">
                        <Dropdown id="dropdown_auth" value={integrationFields.api.authorization.authorization_type}
                                  options={authorizationOptions} showClear
                                  optionLabel="label" optionValue="value"
                                  onChange={(e) => handleAuthorizationType(e.value)}/>
                        <label htmlFor="dropdown_auth">Authorization</label>
                    </span>
                </div>
                {getAuthorizationForm()}


            </Fragment>
        )

    }, [integrationFields]);

    const sheetFields = useMemo(() => {
        return (
            <Fragment>
                <div className="field col-2">
                     <span className="p-float-label">
                        <Button label={'Configuración Sheet'} icon="pi pi-eye"
                                onClick={(e) => selectSheetFields(e)}>
                        </Button>
                    </span>
                </div>
            </Fragment>
        )
    }, [integrationFields]);

    const updateSheetColumns = ({columnFields, urlPage, sheetName, templateType}) => {
        setIntegrationFields((prevState) => {
           return {
                ...prevState,
                sheet: {
                    ...prevState.sheet,
                    urlPage: urlPage,
                    sheetName: sheetName,
                    columns: columnFields,
                    templateType: templateType
                }
           }
        });
    }

    const fields = integrationFields.integrationType === 'api' ? apiFields : sheetFields;

    let form = (
        <div className="grid">
            <div className="col-12 md-12">
                <div className="card p-fluid">
                    <h1><strong>Integración Business</strong></h1>
                    <Toast ref={refToast}/>
                    <div className="formgrid grid mt-4">
                        <div className="field col-2">
                            <span className="p-float-label">
                                <Calendar value={time} stepMinute={5} readOnlyInput={true} min
                                          onChange={(e) => updateTimeHandler(e.value)}
                                          timeOnly/>
                                <label>Hora Importación</label>
                            </span>
                            {getFieldError('time')}
                        </div>
                        <div className="field col-2">
                            <span className="p-float-label">
                                <Dropdown value={frecuencyTime}
                                          options={frecuencyTimeOptions}
                                          onChange={(e) => updateAttribute(e.value, 'frecuencyTime', setFrecuencyTime)}/>
                                <label>Frecuencia de envio</label>
                            </span>
                            {getFieldError('frecuencyTime')}
                        </div>
                        <div className="field col-2">
                            <span className="p-float-label">
                                <Dropdown id="dropdown" value={integrationFields.integrationType}
                                          options={integrationTypeOptions}
                                          onChange={(e) => updateField(e.value, 'integrationType')}/>
                                <label htmlFor="dropdown">Tipo de Integración</label>
                            </span>
                        </div>
                        <div className="field col-1">
                            <span className="p-float-label mt-2">
                                <Checkbox checked={active} onChange={e => setActive(e.checked)}/>
                                <label className={"ml-3"}>Habilitado</label>
                            </span>
                        </div>
                        <div className="field col-1">
                             <span className="p-float-label">
                                <Button label={'Plantillas'}
                                        icon="pi pi-list"
                                        onClick={(e) => selectTemplateWhatsappHandler(e)}>
                                </Button>
                            </span>
                        </div>
                    </div>
                    <div className="grid field mt-4 ">
                        {fields}
                    </div>

                    <div className="field p-fluid">
                        <Button label={labelSubmit} className={"mt-3"} disabled={disableSubmit}
                                onClick={(e) => handleSubmit(e)}/>
                    </div>
                </div>

                <TemplateOverlay
                    showTemplates={showTemplateWhatsappDialog}
                    setShowTemplates={setShowTemplateWhatsappDialog}
                    onTemplateSelection={() => {
                        return null
                    }}
                    onTemplateDelete={() => {
                        return null
                    }}
                    onGetTemplates={() => {
                        return null
                    }}
                    onTemplateUpdate={() => {
                        return null
                    }}
                    onTemplateError={() => {
                        return null
                    }}
                    onTemplateCreate={() => {
                        return null
                    }}
                    canCreateCustomVariables={true}
                    businessIntegration={true}
                />
            </div>

            <BusinessSheetColumns showSheetFieldsDialog={showSheetFieldsDialog}
                                  setShowSheetFieldsDialog={setShowSheetFieldsDialog}
                                  sheet={integrationFields.sheet}
                                  onSheetColumnsUpdate={(e) => updateSheetColumns(e)}/>

        </div>
    )
    return form;
}

export default BusinessForm;