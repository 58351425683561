import React, {Fragment, useContext} from 'react';
import {Calendar} from "primereact/calendar";
import './MessageNoteCalendar.css';
import { addLocale } from 'primereact/api';
import { DateTime } from 'luxon';
import { CompanyContext } from '../../../context/companyContext';

const MessageNoteCalendar = (props) => {
    const {providerTimezone} = useContext(CompanyContext);

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
        dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
        dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
        monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        monthNamesShort: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
    });

    let minDate = DateTime.now().setZone(providerTimezone);
    let minDateString = minDate.toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS");
    minDate = new Date(minDateString);
    minDate.setDate(minDate.getDate());
    minDate.setHours(minDate.getHours());
    minDate.setMinutes(minDate.getMinutes() + 30);
    minDate.setSeconds(0);
    minDate.setMilliseconds(0);

    const calendarStyle = props.style === undefined ? {'position': 'initial'} : props.style
    const datePickerStyle = props.datePickerStyle === undefined ? null : props.datePickerStyle
    let calendarClassName = 'note-reminder-calendar';
    if (datePickerStyle !== null){
        calendarClassName = calendarClassName + ' ' + datePickerStyle
    }

    return (
        <Fragment>
            <Calendar className={calendarClassName} inline={props.inline !== undefined ? props.inline : false} style={calendarStyle}
                      locale="es" minDate={minDate} placeholder={'Seleccione un día'} dateFormat={"dd/mm/yy"}
                      value={props.messageType.value?.reminder_date === null ? minDate : props.messageType.value.reminder_date}
                      onChange={(e) => props.onChange(e.value)} showTime footerTemplate={props.footer}/>
        </Fragment>
    );
}

export default MessageNoteCalendar;