import React, {useState, useEffect, useRef, Fragment, useContext} from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import {Checkbox} from 'primereact/checkbox';
import {Toast} from "primereact/toast";
import { Calendar } from 'primereact/calendar';
import { convertFromCron, convertToCronFormat, getFormattedDateLuxon } from '../../../utils/utils';
import { Dialog } from 'primereact/dialog';
import InfiniteScroll from 'react-infinite-scroller';
import { Card } from 'primereact/card';
import { ProgressBar } from 'primereact/progressbar';
import cronstrue from 'cronstrue/i18n';
import { MRTURNO } from '../../../shared/ProviderScheduledType';
import ApiLoan from '../../../service/ApiLoanService';
import ResultNotFount from '../../../UI/ResultNotFount';
import {CompanyContext} from "../../../context/companyContext";

const MrTurnoForm = (props) => {
    const [action, setAction] = useState('create');
    const [method, setMethod] = useState(null);
    const [urlPage, setUrlPage] = useState('');
    const [active, setActive] = useState(false);
    const [providerScheduledAction, setproviderScheduledAction] = useState(null);
    const [providerScheduledActionOptions, setproviderScheduledActionOptions] = useState(null);
    const [authorization, setAuthorization] = useState({
        authorization_type: null,
        value: ''
    });
    const [loadingPlay, setLoadingPlay] = useState(false);
    const [loadingTest, setLoadingTest] = useState(false);
    const [selectAction, setSelectAction] = useState(null);
    const [errorRangeTime, setErrorRangeTime] = useState(false);
    const [time, setTime] = useState('');
    const [dialog, setDialog] = useState(false);
    const scrollParentRef = useRef(null);
    const [messageScheduled, setMessageScheduled] = useState({
        scheduled_messages: [],
    });
    const [pageScheduled, setPageScheduled] = useState(1);
    const [loadingScheduled, setLoadingScheduled] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [institutionSubsidiaryId, setInstitutionSubsidiaryId] = useState(null);
    const [institutionId, setInstitutionId] = useState(null);
    const [errorSub, setErrorSub] = useState(false);
    const [errorInstitutionId, setErrorInstitutionId] = useState(false);
    const [errorTimeNotification, setErrorTimeNotification] = useState(false);
    const [sub, setSub] = useState(null);
    const [notificationDate, setNotificationDate] = useState(null);
    const [error, setError] = useState({frecuency: null, provider_scheduled_period_id: null})
    const [timeNotificacion, setTimeNotificacion] = useState('');

    const { providerTimezone } = useContext(CompanyContext)
    
    useEffect(() => {
        let apiService = new ApiLoan();
        if (props.match.params.action !== undefined && props.match.params.action.toUpperCase() === 'MR_TURNO') {
            setproviderScheduledAction(MRTURNO);
        }
        apiService.getResources({
            url: '/provider_scheduled_actions'
        }).then(response => {
            let options = [];
            let data_objects = response.data.objects;

            data_objects.map(x => {
                return options.push({
                    'value': x.id,
                    'label': x.description
                });
            });
            setproviderScheduledActionOptions(options);

        }).catch(error => {
            props.history.push('/')
        });
        return () => {
            setproviderScheduledActionOptions(null);
        };
    }, []);

    useEffect(() => {
        if(sub !== '' || sub !== null) setErrorSub(false); 
    },[sub])

    useEffect(() => {
        if(institutionId !== '' || institutionId !== null) setErrorInstitutionId(false); 
    },[institutionId])

    useEffect(() => {
        if(timeNotificacion !== '' || timeNotificacion !== null) setErrorTimeNotification(false);
    },[timeNotificacion])

    useEffect(() => {
        if ((time instanceof Date) && (timeNotificacion instanceof Date) && (time !== null || timeNotificacion !== null)) {
            let horaTime = time.getHours();
            let minutosTime = time.getMinutes() + 1;
            let horaTimeNotificacion = timeNotificacion.getHours();
            let minutosTimeNotificacion = timeNotificacion.getMinutes();
            let isErrorRangeTime = horaTime > horaTimeNotificacion ||
                                     (horaTime === horaTimeNotificacion && minutosTime > minutosTimeNotificacion);
            setErrorRangeTime(isErrorRangeTime);
        }
    },[time, timeNotificacion])

    useEffect(() => {
        if(time !== '' || time !== null) {
            setError((prevState) => {
                prevState['frecuency'] = null
                return {...prevState}
            })
        }
    }, [time])

    useEffect(() => {
        let apiService = new ApiLoan();
        let edit = props.match.params.id !== undefined;
        if (edit){
            apiService.getResource({
                url: '/provider_scheduled/',
                resource_id: props.match.params.id
            }).then(response => {
                const data = response.data;
                setMethod(data.method);
                setUrlPage(data.url);
                setproviderScheduledAction(data.provider_scheduled_action_id);
                setActive(data.active);
                if (JSON.parse(data.body)) {
                    setInstitutionId(JSON.parse(data.body)['institution_id']);
                    setSub((JSON.parse(data.body)['sub'] || JSON.parse(data.body)['sub'] !== undefined) ? JSON.parse(data.body)['sub'].split('/').length === 0 ? '' : JSON.parse(data.body)['sub'].split('/')[1] : '');
                    setInstitutionSubsidiaryId(JSON.parse(data.body)['institution_subsidiary_id']);
                    setNotificationDate((JSON.parse(data.body)['notification_date'] === '' || JSON.parse(data.body)['notification_date'] === undefined ) ? null : new Date(JSON.parse(data.body)['notification_date']));
                    setTimeNotificacion((JSON.parse(data.body)['time_notification'] === '' || JSON.parse(data.body)['time_notification'] === undefined ) ? null : new Date(JSON.parse(data.body)['time_notification']));
                }
                setTime(convertFromCron(data.frecuency));
            });
            setAction('edit');
        } else {
            apiService.getResources({
                url: '/provider_scheduled',
                filters: [
                    {"name": "provider_scheduled_action__slug", "op": "has", "val": props.match.params.action}
                ]
            }).then(response => {
                if (response.data.objects.length === 1){
                    refToast.current.show({severity: 'error', summary: 'Mensaje Programado', detail: 'Ya existe una configuración del Mensaje Programado.'});
                    setTimeout(() => {
                        props.history.push('/provider_scheduled')
                    }, 3000);
                }
            });
        }
    }, []);

    useEffect(()=>{
        setSelectAction(findLabelByValue(providerScheduledActionOptions, providerScheduledAction))
    },[providerScheduledAction, providerScheduledActionOptions])

    function findLabelByValue(list, targetValue) {
        if (list?.length === undefined) return null;
        for (let i = 0; i < list.length; i++) {
            if (list[i].value === targetValue) {
                return list[i].label;
            }
        }
        return null;
    }

    function compareHours(dateString, timeRange) {
        let date = new Date(dateString);
        let hourOfDay = date.getHours();
        let minutesOfDay = date.getMinutes();
        let match = timeRange.match(/(\d{2}):(\d{2})/);
        let startHour = parseInt(match[1]);
        let startMinute = parseInt(match[2]);
        let endHour = parseInt(match[3]);
        let endMinute = parseInt(match[4]);

        if (endHour < startHour || (endHour === startHour && endMinute < startMinute)) {
            return (hourOfDay >= startHour || hourOfDay < endHour) ||
                   (hourOfDay === endHour && minutesOfDay <= endMinute);
        } else {
            return (hourOfDay > startHour || (hourOfDay === startHour && minutesOfDay >= startMinute)) &&
                   (hourOfDay < endHour || (hourOfDay === endHour && minutesOfDay <= endMinute));
        }
    }

    const playHandleSubmit = (event) => {
        event.preventDefault();
        setLoadingPlay(true);
        let detail = 'Se ejecutó correctamente.'

        if (institutionId === null || institutionId === ''){
            setErrorInstitutionId(true);
        }

        if (sub === null || sub === ''){
            setErrorSub(true);
        }

        if ((institutionId === null || institutionId === '') || errorRangeTime || (sub === null || sub === '')) {
            setLoadingPlay(false);
            refToast.current.show({severity: 'error', summary: 'Scheduleds',
            detail: 'Verifique los datos del formulario.'});
            return
        }

        const apiService = new ApiLoan();

        const formData = {
            'method': method,
            'url': urlPage,
            'provider_scheduled_action_id': providerScheduledAction,
            'provider_scheduled_period_id': null,
            'active': active,
            'body': {'institution_id': institutionId, 'institution_subsidiary_id': institutionSubsidiaryId ? institutionSubsidiaryId : '', 'sub': 'pip/' + sub, "notification_date": notificationDate ? notificationDate : '', 'time_notification': timeNotificacion ? timeNotificacion : ''},
            'authorization': authorization,
            'frecuency': convertToCronFormat(time)
        }

        apiService.postResource({
            'url': '/provider/scheduled/test',
            'data': formData
        }).then(response => {
            if (response?.data[2]?.message !== undefined) {
                if (!Array.isArray(response?.data[2]?.message)) detail = response?.data[2]?.message
            }
            refToast.current.show({severity: 'success', summary: 'Scheduleds', detail: detail});
        }).catch(error => {
            let error_message = error?.response?.data?.message;
            if (typeof(error?.response?.data?.message) === 'object'){
                error_message =  'Verifique los datos del formulario.'
            }
            refToast.current.show({severity: 'error', summary: 'Scheduleds',
                detail: error_message});
        }).finally(() => {setLoadingPlay(false)} );
    }

    const testHandleSubmit = (event) => {
        event.preventDefault();
        setLoadingTest(true);
        let detail = 'Se ejecutó correctamente.'

        if (institutionId === null || institutionId === ''){
            setErrorInstitutionId(true);
        }

        if (sub === null || sub === ''){
            setErrorSub(true);
        }

        if ((institutionId === null || institutionId === '') || (sub === null || sub === '')) {
            setLoadingTest(false);
            refToast.current.show({severity: 'error', summary: 'Scheduleds',
            detail: 'Verifique los datos del formulario.'});
            return
        }

        const apiService = new ApiLoan();

        const formData = {'institution_id': institutionId, 'sub': 'pip/' + sub}

        apiService.postResource({
            'url': '/provider/scheduled/test/ping',
            'data': formData
        }).then(response => {
            if (response?.data?.message !== undefined) {
                detail = response?.data?.message
            }
            refToast.current.show({severity: 'success', summary: 'Scheduleds', detail: detail});
        }).catch(error => {
            let error_message = error?.response?.data?.message;
            if (typeof(error?.response?.data?.message) === 'object'){
                error_message =  'Verifique los datos del formulario.'
            }
            refToast.current.show({severity: 'error', summary: 'Scheduleds',
                detail: error_message});
        }).finally(() => {setLoadingTest(false)} );
    }
    
    const handleSubmit = (event) => {
        event.preventDefault();

        if (institutionId === null || institutionId === ''){
            setErrorInstitutionId(true);
        }

        if (sub === null || sub === ''){
            setErrorSub(true);
        }

        if (timeNotificacion === null || timeNotificacion === ''){
            setErrorTimeNotification(true);
        }

        if ((institutionId === null || institutionId === '') || errorRangeTime || (sub === null || sub === '') || timeNotificacion === null || timeNotificacion === '') {
            refToast.current.show({severity: 'error', summary: 'Scheduleds',
            detail: 'Verifique los datos del formulario.'});
            return
        }
    
        const apiService = new ApiLoan();

        const formData = {
            'method': method,
            'url': urlPage,
            'provider_scheduled_action_id': providerScheduledAction,
            'provider_scheduled_period_id': null,
            'active': active,
            'body': {'institution_id': institutionId, 'institution_subsidiary_id': institutionSubsidiaryId ? institutionSubsidiaryId : '', 'sub': 'pip/' + sub, "notification_date": notificationDate ? notificationDate : '', 'time_notification': timeNotificacion ? timeNotificacion : ''},
            'authorization': authorization,
            'frecuency': time === '' ? null : convertToCronFormat(time)
        }

        let api_request = null;
        let url = 'provider_scheduled';
        if (action === 'create'){
            api_request = apiService.postResource;
        } else {
            api_request = apiService.patchResource;
            url = url + '/' + props.match.params.id
        }


        api_request({
            'url': url,
            'data': formData
        }).then(response => {
            refToast.current.show({severity: 'success', summary: 'Scheduleds', detail: 'Se creó correctamente.'});
            props.history.push('/')
        }).catch(error => {
            let error_message = error?.response?.data?.message;
            let object_keys = Object.keys(error_message);
            setError((prevState) => {
                object_keys.map(x => {
                    prevState[x] = error.response.data.message[x][0]
                })
                return {...prevState}
            })
            if (typeof(error.response.data.message) === 'object'){
                error_message =  'Verifique los datos del formulario.'
            }
            refToast.current.show({severity: 'error', summary: 'Scheduleds',
                detail: error_message});
        });
    }

    const getMoreScheduledMessage = () => {
        getScheduledMessage(pageScheduled + 1);
    }

    const getScheduledMessage = (page) => {
        const apiService = new ApiLoan();
        setLoadingScheduled(true);
        return apiService.getResources({
            url: '/note/scheduled',
            filters: {
                message_types: ['scheduled_message'],
                template: selectAction,
            },
            results_per_page: 10,
            page: page
        }).then(response => {
            let scheduled_messages = response.data.objects;
            if (scheduled_messages.length === 0) { 
                setHasMore(false) 
            }
            setPageScheduled(response.data.page);
            setMessageScheduled(prevState => {
                return {
                    ...prevState,
                    scheduled_messages: [...prevState.scheduled_messages, ...scheduled_messages]
                };
            });
        }).finally(() => {
            setLoadingScheduled(false)
        });
    }

    const getFieldError = (errors, description) => {
        let form = null;
        if (errors){
            form = <small style={{color:'#f44336'}} className="p-error"> {description} </small>
        }
        return form;
    }

    const onHideScheduledDialog = () => {
        setMessageScheduled({
            scheduled_messages: [],
        });
        setHasMore(true);
        getScheduledMessage(1);
        setDialog(true);
    }

    const itemMessageScheduled = (data) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                <Card>
                    <span>{getFormattedDateLuxon(data.created, providerTimezone)}</span>&nbsp;
                    <span>{data.client.lastname}</span>&nbsp;
                    <span>{data.client.firstname}</span>&nbsp;
                    <span>{data.body}</span>&nbsp;
                    <span>{data.client.contacts[0].country_prefix + data.client.contacts[0].value}</span>&nbsp;
                    <span>{cronstrue.toString(data.frecuency.minute + ' ' + data.frecuency.hour + ' ' + data.frecuency.day_month + ' ' + data.frecuency.month + ' ' + data.frecuency.day_week, { locale: "es" })}</span>&nbsp;
                </Card>
            </div>
        );
    }
    const messageMessageScheduledList = messageScheduled.scheduled_messages.map((element) => {
        return itemMessageScheduled(element);
    });

    const refToast = useRef(null);
    const labelSubmit = props.match.params.id !== undefined ? 'Guardar' : 'Crear';
    let form = (
        <div className="grid">
            <div className="col-12 md-12">
                <div className="card p-fluid">
                    <h1><strong>Mensajes Programados</strong></h1>
                    <Toast ref={refToast}/>
                    <div className="formgrid grid">
                        <div className="field col">
                            <span className="p-float-label">
                                <Dropdown disabled={true} value={providerScheduledAction} options={providerScheduledActionOptions} onChange={(e) => setproviderScheduledAction(e.value)}/>
                                <label>Acción</label>
                            </span>
                        </div>
                        <div className="field col">
                            <span className="p-float-label">
                                <Calendar className={(errorRangeTime || error?.frecuency) && "p-invalid"} value={time} onChange={(e) => setTime(e.value)} timeOnly />
                                <label>Hora Importación</label>
                            </span>
                            {getFieldError(errorRangeTime || error?.frecuency, error?.frecuency ? error?.frecuency : 'Frecuencia Invalida')}
                        </div>
                        <div className="field col">
                            <span className="p-float-label">
                                <Calendar className={errorTimeNotification && "p-invalid"} value={timeNotificacion} onChange={(e) => setTimeNotificacion(e.value)} timeOnly />
                                <label>Hora Notificación</label>
                            </span>
                            {getFieldError(errorTimeNotification, 'Campo Obligatorio')}
                        </div>
                        <div className="field col">
                            <span className="p-float-label mt-2">
                                <Checkbox checked={active} onChange={e => setActive(e.checked)} />
                                <label className={"ml-3"} >Habilitado</label>
                            </span>
                        </div>
                            <div className="field col">
                                <span className="p-float-label">
                                    {/*(props.match.params.id !== undefined && selectAction) && (
                                        <button className="p-link" >
                                            <FontAwesomeIcon
                                                onClick={() => {onHideScheduledDialog()}}
                                                icon={"info"} size={"xs"}
                                                className={"btn-actions btn-actions-default btn-fontawesome"}
                                            />
                                        </button>
                                    )*/}
                                    {
                                    /*
                                    <button disabled={ loadingPlay } className="p-link" >
                                        <FontAwesomeIcon
                                            onClick={playHandleSubmit} disabled={ loadingPlay }
                                            icon={loadingPlay ? "spinner" : "play"} spin={ loadingPlay }size={"xs"}
                                            className={"btn-actions btn-actions-default btn-fontawesome"}
                                        />
                                    </button>
                                    */
                                    }
                                </span>
                            </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="field col">
                             <span className="p-float-label">
                                <InputText className={errorInstitutionId && "p-invalid"} id="inputtext" value={institutionId} onChange={(e) => setInstitutionId(e.target.value)} />
                                <label htmlFor="inputtext">Identificador Institución</label>
                            </span>
                            {getFieldError(errorInstitutionId, 'Campo Obligatorio')}
                        </div>
                        <div className="field col">
                             <span className="p-float-label">
                                <InputText id="inputtext" value={institutionSubsidiaryId} onChange={(e) => setInstitutionSubsidiaryId(e.target.value)} />
                                <label htmlFor="inputtext">Identificador Sucursal</label>
                            </span>
                        </div>
                        <div className="field col">
                             <span className="p-float-label">
                                <InputText className={errorSub && "p-invalid"} id="inputtext" value={sub} onChange={(e) => setSub(e.target.value)} />
                                <label htmlFor="inputtext">Seo Name</label>
                            </span>
                            {getFieldError(errorSub, 'Campo Obligatorio')}
                        </div>
                        <div className="field col-2">
                             <span className="p-float-label">
                                <Button label={'Test Conexión'} disabled={loadingTest}
                                        onClick={(e) => testHandleSubmit(e)} >
                                    <i className={loadingTest ? "pi pi-spin pi-spinner" : "pi pi-play"} style={{ fontSize: '1rem' }}></i>
                                </Button>
                            </span>
                        </div>
                        {
                        /*
                        <div className="field col">
                            <span className="p-float-label">
                                <Calendar value={notificationDate} dateFormat="yy-mm-dd" onChange={(e) => setNotificationDate(e.value)}/>
                                <label htmlFor="calendar">Fecha Notificación</label>
                            </span>
                        </div>
                        */
                        }
                    </div>    
                </div>
                <div className="field p-fluid">
                    <Button label={labelSubmit} className={"mt-4"}
                                onClick={(e) => handleSubmit(e)} />
                </div>
                <Dialog header={'Mensajes Programados'} visible={dialog} onHide={() => setDialog(false)}>
                    <div className='scrollable datascroller-notes' style={{height: "50vh", minWidth: "55vh"}} ref={scrollParentRef}>
                        {messageScheduled.scheduled_messages.length === 0 && !loadingScheduled && <ResultNotFount/>}
                        {loadingScheduled && (
                            <ProgressBar mode="indeterminate"/>
                        )}
                        <InfiniteScroll
                            pageStart={pageScheduled}
                            isReverse={false}
                            initialLoad={false}
                            useWindow={false}
                            loadMore={getMoreScheduledMessage}
                            hasMore={loadingScheduled ? false : hasMore}
                            getScrollParent={() => scrollParentRef.current}
                        >
                            {messageMessageScheduledList}   
                        </InfiniteScroll>
                    </div>
                </Dialog>
            </div>
        </div>
    )
    return form;
}

export default MrTurnoForm;