import React, { Fragment, useEffect, useState, useRef } from "react";
import ApiLoan from "../../service/ApiLoanService";

import {SMS} from "../../shared/channelTypes";
import {PHONE} from "../../shared/contactTypes";
import {Panel} from "primereact/panel";
import {TabPanel, TabView} from "primereact/tabview";
import { Toast } from 'primereact/toast';

import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

import DateTimeField from "../../UI/DateTimeField";

import "../../assets/css/Messages.css";

const Messages = props => {
    const [resource, setResource] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [bodyText, setBodyText] = useState('');
    const [contactSelected, setContactSelected] = useState(null);
    const [countryPrefixSelected, setCountryPrefixSelected] = useState(null);
    const [channel, setChannel] = useState(SMS);
    const [contactType, setContactType] = useState(PHONE);
    const [activeChannelIndex, setActiveChannelIndex] = useState(0);
    const [activeContactIndex, setActiveContactIndex] = useState(0);
    const [count, setCount] = useState(0);
    const [countRequest, setCountRequest] = useState(0);

    const [loadingContacts, setLoadingContacts] = useState(true);
    const [loadingResources, setLoadingResources] = useState(true);

    const apiService = new ApiLoan();

    const channels = [SMS]

    const refToast = useRef(null);

    let contactTypeByChannel = {};

    contactTypeByChannel[SMS] = PHONE;

    let chatMessagesBottomDiv;

    // useEffect(() => {
    //     let ignore = false;
    //     if (!ignore){
    //         if (countRequest === 0){
    //             getMessages(false);
    //         } else {
    //             getMessages(true);
    //         }
    //     }
    //     return () => {
    //         ignore = true;
    //     };
    //
    // },[countRequest, channel]);
    useEffect(() => {
        setLoadingResources(false);
    }, [])


    useEffect(() => {
        let ignore = false;
        apiService
            .getResources({
                url: '/contacts',
                filters: [
                    {"name": "client_id", "op": "equals", "val": props.client_id}
                ],
            })
            .then(response => {
                if (!ignore) {
                    setContacts(response.data.objects);
                    setLoadingContacts(false);
                }
            });
        return () => {
            ignore = true;
        };
    }, []);

    useEffect(() => {
        if(chatMessagesBottomDiv){
            scrollToChatMessagesBottom();
        }
    }, [activeContactIndex,loadingContacts, count]);

    const getMessages = (update) => {
        apiService.getResources({
            url: props.url,
            filters: [
                {"name": "client_id", "op": "equals", "val": props.client_id}
            ],
            order_by: [{"field": "id", "direction": "asc"}],
            results_per_page: 500
        }).then(response => {
            let response_data = response.data.objects;

            if (!update){
                setResource(response_data);
                setLoadingResources(false);
                setCountRequest(countRequest + 1);
            } else {
                if (response_data.length !== resource.length){
                    setResource(response_data);
                    setLoadingResources(false);
                    setCountRequest(countRequest + 1);
                } else {
                    setTimeout(() => {
                        setCountRequest(countRequest + 1);
                    }, 60000);
                }
            }
        });
    };

    const getContactsByChannel = (contacts) => {
        return contacts.filter((x) => x.contact_type_id === contactType);
    };

    const onTabChannelChange = (event) => {
        setActiveChannelIndex(event.index);
        setChannel(channels[event.index])
        setContactType(contactTypeByChannel[channels[event.index]])
    };

    const onTabContactChange = (event) => {
        setActiveContactIndex(event.index);
        let contacts_fields = getContactsByChannel(contacts);
        setContactSelected(contacts_fields[event.index].value);
        setCountryPrefixSelected(contacts_fields[event.index].country_prefix);

    };

    const getMessagesByContact = (contact_value) => {
        let messages = resource.filter(x => (x.recipient === contact_value) || (x.sender === contact_value));

        let messages_body = messages.map((message, index) => {
            let writer = null;
            if (message.type === 'sent'){
                writer = 'message-own';
            } else {
                writer = 'message-from';
            }

            return (
                <li className={writer} key={index}>

                    <span>{message.body}</span>
                    <div><DateTimeField>{message.created}</DateTimeField></div>
                </li>
            );

        });

        return (
            <div className="scroller-messages">
                <ul className="chat-messages">
                    {messages_body}
                </ul>
                <div ref={(element) => {
                    chatMessagesBottomDiv = element;
                }}>
                </div>
            </div>
        );
    };

    const bodyChanged = (event) => {
      setBodyText(event.target.value);
    };

    const scrollToChatMessagesBottom = () => {
        if(chatMessagesBottomDiv){
            chatMessagesBottomDiv.parentNode.scrollTop = chatMessagesBottomDiv.offsetTop;
        }
    }

    const sendMessage = (event) => {
        event.preventDefault();

        let params = {};

        params['recipient'] = contactSelected;
        params['country_prefix'] = countryPrefixSelected;
        params['body'] = bodyText;
        params['client_id'] = parseInt(props.client_id);
        params['channel_id'] = channel;

        if (contactSelected === null){
            let contacts_fields = getContactsByChannel(contacts);
            params['recipient'] = contacts_fields[0].value;
            params['country_prefix'] = contacts_fields[0].country_prefix;
        }

        let message = {...params};
        message['type'] = 'sent';
        message['created'] = new Date();
        let messages = resource;
        messages.push(message);
        setResource(messages);
        setCount(count+1);
        setBodyText('');
        apiService.postResource({
            url: '/messages/single',
            data: params
        }).catch(error => {
            messages.pop();
            setResource(messages);
            setCount(count-1);
            refToast.current.show({severity: 'error', summary: 'Mensajes', detail: 'No es posible mandar el mensaje.'});
        });
    };


    let messages = null;

    if (!loadingContacts && !loadingResources) {
        let contacts_by_channel = getContactsByChannel(contacts);


        let view_messages = contacts_by_channel.map((contact, index) => {
            return (
                <TabPanel header={contact.value} key={index}>
                    {getMessagesByContact(contact.value)}
                </TabPanel>
            );
        });

        const channelTabs = channels.map((channel, index) => {
            let headerTitle = 'SMS';

            return (<TabPanel header={headerTitle} key={index}>
                        <TabView activeIndex={activeContactIndex} onTabChange={(e) =>  onTabContactChange(e)}>
                            {view_messages}
                        </TabView>
                    </TabPanel>);
        })

        messages = (
            <Fragment>
                <Toast ref={refToast}/>
                <div className={"message"}>
                    <div className="col-12 chat">
                        <Panel header="Chat">
                            <TabView activeIndex={activeChannelIndex} onTabChange={(e) =>  onTabChannelChange(e)}>
                                {channelTabs}
                            </TabView>

                            <form onSubmit={sendMessage}>
                                <div className="new-message">
                                    <div className="message-attachment">
                                        <i className="pi pi-paperclip"/>
                                    </div>
                                    <div className="message-input">
                                        <InputText className="p-inputtext-sm" value={bodyText} onChange={(e) => bodyChanged(e)} placeholder="Enviar mensaje..." />
                                    </div>
                                    <div className="message-attachment-button">
                                        <Button icon="pi pi-reply" className="p-button-rounded message-button"/>
                                    </div>
                                </div>
                            </form>
                        </Panel>
                    </div>
                </div>
            </Fragment>
        );
    }

    return messages;
};


export default Messages;