import React, { Component } from 'react';
import InputForm from '../../../UI/InputForm';
import { Button } from 'primereact/button';
import update from 'immutability-helper';
import ApiLoan from '../../../service/ApiLoanService';
import * as roles from "../../../shared/roles";
import {withRouter} from "react-router-dom";

import {showSuccessMessage, showErrorMessage} from "../../../shared/messagesToast";
import connect from "react-redux/es/connect/connect";
import {messageErrorObject} from "../../../shared/utility";
import {showToast} from "../../../store/actions";

class Loan extends Component {

    _isMounted = false;

    state = {
        apiService: new ApiLoan(),
        uri: '/loans',
        title: 'Operación',
        loanForm: {
            amount: {
                elementType: 'text',
                elementConfig: {
                    keyfilter: 'pint'
                },
                value: '',
                traduction: 'Monto Total'
            },
            amount_borrowed: {
                elementType: 'text',
                elementConfig: {
                    keyfilter: 'pint'
                },
                value: '',
                traduction: 'Monto Prestado'
            },
            description: {
                elementType: 'textarea',
                elementConfig: {
                    placeholder: 'Descripción de la operación'
                },
                value: '',
                traduction: 'Descripción de la operación'
            },
            interest: {
                elementType: 'text',
                elementConfig: {
                    keyfilter: 'pint'
                },
                value: '',
                traduction: 'Interés'
            },
            requested_installment: {
                elementType: 'text',
                elementConfig: {
                    keyfilter: 'pint'
                },
                value: '',
                traduction: 'Cantidad de Cuotas'
            },
            client_id: {
                elementType: 'dropdown',
                elementConfig: {
                    uri: '/debtors',
                    options: null,
                    filter: true,
                    placeholder: 'Seleccione un Deudor'
                },
                value: '',
                traduction: 'Deudores'
            },
            cbu: {
                elementType: 'text',
                elementConfig: {
                    keyfilter: 'pint'
                },
                value: '',
                traduction: 'CBU'
            },
            bank: {
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'Banco'
            },
            due_date: {
                elementType: 'calendar',
                elementConfig: {
                    placeholder: 'Fecha de Vencimiento'
                },
                value: '',
                traduction: 'Fecha de Vencimiento'
            },
            remote_loan_id: {
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'ID Operación Cliente'
            }
        },
        loading: true
    };

    componentDidMount(){
        this._isMounted = true;

        let clientOptions = [];
        let clients_url = '/debtors/all';

        let loanForm = {
            ...this.state.loanForm
        };

        this.state.apiService.getResources({
            url: clients_url
        }).then(response => {
            if (this._isMounted) {
                const response_data = response.data.objects;
                for (let key in response_data) {
                    let full_name = response_data[key].firstname + ' ' + response_data[key].lastname;
                    clientOptions.push({
                        'value':  response_data[key].id,
                        'label': response_data[key].document_number + ' - ' + full_name
                    });
                }

                let clientsForm = update(loanForm, {
                    client_id: {
                        elementConfig: {
                            options: {$set: clientOptions}
                        }
                    }
                });

                loanForm = clientsForm;

                this.setState({
                    loanForm: loanForm
                });
            }
        }).catch(error => {
            showErrorMessage(this, "Operaciones", 'Error en la carga de datos de deudores');
            this.props.history.push( '/loans' );
        });

        let role = roles.switchRole();
        if (role === 'admin'){
            let provider_url = '/clients';
            let providerOptions = [];

            this.state.apiService.getResources({
                url: provider_url,
                results_per_page: 500
            }).then(response => {
                if (this._isMounted) {
                    const response_data = response.data.objects;

                    for (let key in response_data) {
                        providerOptions.push({
                            'value': response_data[key].id,
                            'label': response_data[key].name
                        });
                    }

                    let provider_id = {
                        elementType: 'dropdown',
                        elementConfig: {
                            uri: provider_url,
                            options: providerOptions,
                            placeholder: 'Seleccione un Cliente'
                        },
                        value: '',
                        traduction: 'Cliente'
                    };

                    let providerConfig = update(loanForm, {$merge: {provider_id}});
                    loanForm = providerConfig;

                    this.setState({loanForm: loanForm});
                }
            }).catch(error => {
                showErrorMessage(this, "Operaciones", 'Error en la carga de datos de clientes');
                this.props.history.push( '/loans' );
            });
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    loanHandler = (event) => {
        event.preventDefault();
        this.setState( { loading: true } );

        const formData = {};

        for (let formElementIdentifier in this.state.loanForm) {
            formData[formElementIdentifier] = this.state.loanForm[formElementIdentifier].value;
        }

        this.state.apiService.postResource({
            url: this.state.uri,
            data: formData
        }).then(response => {
            this.setState( { loading: false } );
            showSuccessMessage(this, "Operación", "Se creó satisfactoriamente");
            this.props.history.push( '/loans' );
        }).catch(error => {
            let messageError = error.response.data.message;

            let loanForm = {
                ...this.state.loanForm
            };

            let errorMessages = messageErrorObject(loanForm, messageError);

            if (errorMessages !== null){
                this.setState({
                    loanForm: errorMessages,
                    loading: false
                });
            } else {
                this.setState( { loading: false } );
                showErrorMessage(this, "Operación", messageError);
            }
        });

    };

    inputChangedHandler = (event, inputIdentifier) => {
        let inputFormChanged = this.state.loanForm;

        const updatedLoanForm = {
            ...inputFormChanged
        };

        const updatedFormElement = {
            ...updatedLoanForm[inputIdentifier]
        };

        if (updatedFormElement.elementType === 'dropdown' || updatedFormElement.elementType === 'calendar'){
            updatedFormElement.value = event.value;
        } else {
            updatedFormElement.value = event.target.value;
        }

        if (updatedFormElement.elementConfig.error !== undefined){
            updatedFormElement.elementConfig.error = null;
        }

        updatedLoanForm[inputIdentifier] = updatedFormElement;

        this.setState({loanForm: updatedLoanForm});
    };

    render(){
        const formElementsArray = [];
        let loanForm = this.state.loanForm;
        let submitForm = this.loanHandler;
        let submitFormButton = 'Guardar';

        for (let key in loanForm) {
            formElementsArray.push({
                id: key,
                config: loanForm[key]
            });
        }

        let form = (
            <form onSubmit={submitForm}>
                {formElementsArray.map(formElement => (
                    <InputForm
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        typeElement={formElement.id}
                        traduction={formElement.config.traduction}
                        title={formElement.config.title}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)}
                    />
                ))}

                <div className="col-12 md-9">
                    <Button label={submitFormButton} style={{marginBottom:'10px'}} />
                </div>
            </form>
        );


        return (
            <div className="grid p-fluid">
                <div className="col-12 p-lg-12">
                    <div className="card card-w-title">
                        <h1><strong>{this.state.title}</strong></h1>
                        <div className="col-12 form-group">
                            {form}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapDispatchToProps = {
    showToast
};

export default withRouter(connect(null, mapDispatchToProps)(Loan));
