import React, { Component, Fragment } from 'react';

import ApiLoan from '../../../service/ApiLoanService';

import StripedList from '../../../UI/StripedList';
import ContactButton from '../../../UI/ContactButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../../../assets/css/ContactList.css";

class ContactList extends Component{

    _isMounted = false;
    state = {
        contacts: []
    };

    apiService = new ApiLoan();

    renderAddressItem(address){
        return address.name + ' ' + address.city + ', ' + address.state + ', CP ' + address.postal_code
    }

    renderListItem(contact){

        let contactIcon = null;
        let button = null;
        switch(contact['contact_type'].slug){
            case 'phone':
                contactIcon = <FontAwesomeIcon icon={'phone-alt'} size={'xs'} className={'p-pr-2'}/>
                break;
            case 'email':
                contactIcon = <FontAwesomeIcon icon={'envelope'} size={'xs'} className={'p-pr-2'}/>
                break;
            case 'address':
                contactIcon = <FontAwesomeIcon icon={'home'} size={'xs'} className={'p-pr-2'}/>
                break;
            default:
                contactIcon = null;
                break;
        }

        let value = contact.value;
        if(contact.contact_type.slug === 'address'){
            value = this.renderAddressItem(JSON.parse(contact.value))
        } else if (contact.contact_type.slug === 'phone'){
            value = '+' + contact.country_prefix + contact.value;
        }

        return (<Fragment>
                    <div className={"contact-value"}>
                        {contactIcon}
                        <span>{value}</span>
                    </div>
                    <div className={'right-item-list'}>{button}</div>
                </Fragment>);
    }

    componentDidMount(){
        this._isMounted = true;
        this.apiService.getResources({
            url: '/debtors/' + this.props.client_id + '/contacts'
        }).then(response => {
            if (this._isMounted){
                this.setState({
                    contacts: response.data.objects
                });
            }

        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render(){
        let contactList = this.state.contacts.map(contact => this.renderListItem(contact));
        return (<div className="col-12" >
                    <div className="p-tabview-panels contacts">
                        <StripedList items={contactList}/>
                    </div>
                </div>);
    }

}

export default ContactList;