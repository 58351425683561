import React, {useEffect, useState, Fragment} from "react";
import { InputNumber } from 'primereact/inputnumber';


const BusinessIntegrationMaxCapacity = (props) => {
    const [loading, setLoading] = useState(true);
    const [businessIntegration, setBusinessIntegration] = useState(false);

    useEffect(() => {
        if (props.business_integration !== businessIntegration){
            if (props.value === 0){
                cleanValueHandler(0)
            }
            setBusinessIntegration(props.business_integration)
            // setGateway(props.gateway)
            setLoading(false)
        }
    }, [props.business_integration, props.value]);

    useEffect(() => {
        if (props.business_integration !== businessIntegration && !loading){
            setLoading(false);
        }
    }, [props.business_integration]);

    const inputChangeHandler = (event) => {
        props.onChange(event.target.value);
    };

    const cleanValueHandler = (value) => {
        props.onChange(value);
    };

    const getBusinessForm = gateway => {
        let value = props.value;
        let elementClass = "";

        return (
            <Fragment>
                <div className="col-12 form-group">
                    <div className="grid">
                        <div className={elementClass || "col-12 md-8"}>
                            <span className="p-float-label">
                                <InputNumber
                                    value={value}
                                    onValueChange={(e) => inputChangeHandler(e)}
                                    min={0}
                                    disabled={!props.business_integration}
                                    className={props.error && 'p-error'}/>
                                <label htmlFor="input">Máximo de mensajes via Integración Business</label>
                            </span>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };

    if (!loading) {
        if (props.business_integration === businessIntegration){
            return getBusinessForm(businessIntegration);
        } else {
            return null
        }
    } else {
        return null;
    }
};

export default BusinessIntegrationMaxCapacity;