import React, {useState, useEffect, Fragment, useRef} from 'react';

import Resources from '../Resources/Resources';
import DialogGeneric from "../../UI/DialogGeneric";
import ApiLoan from "../../service/ApiLoanService";
import { Toast } from 'primereact/toast';
import * as roles  from '../../shared/roles';

const PayOrders = (props) => {
    const [modal, setModal] = useState(false);
    const [role, setRole] = useState(null);

    useEffect(() => {
        setRole(roles.switchRole());
    },[]);

    const toast = useRef(null);

    const exportButton = (props) => {
        setModal(true);
    }

    const regenerateOrders = (e) => {
        let apiService = new ApiLoan();

        apiService.patchResource({
            url: '/pay_orders/forward'
        }).then(response => {
            showSuccess(response.data.message);
        }).catch(error => {
            showError(error.response.data.message);
        });
    }


    const showSuccess = (detail) => {
        let summary_message = props.sumaryToast !== undefined ? props.sumaryToast : 'Ordenes de Pago';
        toast.current.show({severity: 'success', summary: summary_message, detail: detail});
    }

    const showError = (detail) => {
        let summary_message = props.sumaryToast !== undefined ? props.sumaryToast : 'Ordenes de Pago';
        toast.current.show({severity: 'error', summary: summary_message, detail: detail});
    }

    let resource = {
        title: 'Órdenes de Pago',
        url: '/pay_orders',
        colsHeader: [
            { field: 'created', header: 'Creado', sortable: true, date: true},
            { field: 'amount', header: 'Monto', sortable: true, filter: true, filterMatchMode: 'contains' },
            { field: 'client.firstname', header: 'Nombre', filter: true, relation_object: 'firstname', parent_field: "client", filterMatchMode: 'contains' },
            { field: 'client.lastname', header: 'Apellido', filter: true, relation_object: 'lastname', parent_field: "client", filterMatchMode: 'contains' },
            { field: 'remote_loan_id', header: 'Operación', truncate_word: true },
            { field: 'payment_status.description', header: "Estado", sortable: false, filter: false },
            { field: 'debit_date', header: "Fecha Débito", sortable: false, filter: false },
            { field: 'action', header: 'Acciones' }
        ],
        action:{
            add: '/upload',
            addExportButton: {
                eventButton: exportButton
            },
            addGenericButton: {
                button: false,
                eventButton: regenerateOrders,
                label: 'Regenerar Órdenes',
                icon: 'pi pi-copy',
                alert: {
                    alertBody: '¿Está seguro que quiere realizar esta acción?'
                }
            }
        },
        customTableHeaderButtons: [
            {
                label: 'Cargar por csv', command: () => { window.location = '#/pay_orders/upload' }
            },
            {
                label: 'Por cuota', command: () => { window.location = '#/pay_orders/new' }
            },
            {
                label: 'Individual', command: () => { window.location = '#/pay_orders/single' }
            },
        ],
        customActions: [
            { field: 'pay_order_debtor', uri: '/debtor/'}

        ],
        urlTable: [
            '/pay_orders',
            '/pay_orders/upload',
            '^\\/debtor\\/\\d+$',
        ]
    }

    if (props.desactivateRegenerateOrders){
        resource.action.addGenericButton.button = props.desactivateRegenerateOrders;
    }
    if (props.customTableHeaderButtons){
        resource.customTableHeaderButtons = props.customTableHeaderButtons;
    }
    if (role === 'payment_order_creator'){
        let get_index_remote_loan_id = resource.colsHeader.findIndex(x => x.field === 'remote_loan_id');
        if (get_index_remote_loan_id !== -1){
            let remote_pay_order = resource.colsHeader.filter(x => x.field === 'remote_loan_id')[0];
            remote_pay_order.field = 'remote_pay_order';
            resource.colsHeader[get_index_remote_loan_id] = remote_pay_order;
        }
        if (props.customColsHeader){
            props.customColsHeader.map(field => {
                resource.colsHeader.splice(-1, 0, field)
                return resource;
            });
        }
        if (props.deletePayOrder){
            resource.customActions.pop();
            resource.customActions.push(props.deletePayOrder)
        }

    }

    let value_dialog = {
        'url': '/pay_orders/report',
        'method': 'post',
        'header': 'Exportar Órdenes de Pago',
        'submit_label': 'Descargar',
        'sumaryToast': 'Órdenes de Pago',
        'fields': [
            {
                name: 'range',
                elementType: 'calendar',
                elementConfig: {
                    selectionMode:'range',
                    readonlyInput: true,
                    dateFormat: 'dd/mm/yy',
                    maxDate: new Date(),
                    minDate: null,
                    traduction: 'Filtrar por Fecha',
                },
                value: null,
                elementClass: 'col-12 md-12 mt-2',
                limitRange: {
                    days: 90
                }
            },
            {
                name: 'report_type',
                elementType: 'hidden',
                value: 'pay_orders',
                elementClass: 'input-hidden'
            }
        ],
    }

    if (role === 'payment_order_creator'){
        let get_index_name_field = value_dialog.fields.findIndex(x => x.name === 'report_type');
        if (get_index_name_field !== -1){
            let report_type = value_dialog.fields.filter(x => x.name === 'report_type')[0];
            report_type.value = 'debit_pay_orders';
            value_dialog.fields[get_index_name_field] = report_type;
        }
    }

    let renderModal = modal ? <DialogGeneric visible={modal} setVisible={setModal} {...value_dialog}/> : null;

    return (
        <Fragment>
            <Toast ref={toast} />
            {renderModal}
            <Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader}
                       customTableHeaderButtons={resource.customTableHeaderButtons}
                       customActions={resource.customActions}
                       action={resource.action} urlTable={resource.urlTable}/>
        </Fragment>
    );

}

export default PayOrders;