import React, { Component } from 'react';

import ApiLoan from '../../../service/ApiLoanService';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class ShortUrlView extends Component{

    state = {
        short_url: null,
        copied: false
    };

    apiService = new ApiLoan();

    componentDidMount(){
        this.apiService.getResource({
            url: '/debtors/' + this.props.client_id + '/short_url'
        }).then(response => {
            this.setState({
                short_url: response.data.short_url
            });
        });
    }

    onCopy = () => {
        this.setState({copied: true});
    };

    render(){
        let shortUrl = this.state.short_url;

        return (
            <div className="col-12">
                <div className="flex p-jc-between mt-3">
                    <div><span className="">{shortUrl}</span></div>
                    <div>
                        <CopyToClipboard text={this.state.short_url} onCopy={this.onCopy}>
                            <FontAwesomeIcon icon={"share-alt"} style={{background: '#31D1AEE5'}} color={'white'}
                                             size="xs" className={"btn-actions btn-fontawesome"} />
                        </CopyToClipboard>
                    </div>

                </div>
                {this.state.copied ? <span className="mt-3" style={{"color": "red", "display": "block"}}>Copiado.</span> : null}
            </div>
        );
    }

}

export default ShortUrlView;