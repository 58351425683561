import React, {Fragment, useEffect, useRef, useState} from "react";
import {Dropdown} from "primereact/dropdown";
import ApiLoan from "../../service/ApiLoanService";
import {Button} from "primereact/button";
import { Toast } from 'primereact/toast';
import {InputText} from "primereact/inputtext";
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';

const DebitPayOrderSingle = props => {
    const [gateways, setGateways] = useState(null);
    const [gateway, setGateway] = useState(null);
    const [amount, setAmount] = useState(0);
    const [description, setDescription] = useState('');
    const [remotePayOrder, setRemotePayOrder] = useState('');
    const [documentNumber, setDocumentNumber] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [cuil, setCuil] = useState('');
    const [paymentDate, setPaymentDate] = useState(null);
    const [cbu, setCbu] = useState('');
    const [disabledButton, setDisabledButton] = useState(true);
    const [errors, setErrors] = useState(null);
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);


    useEffect(() => {
        let apiService = new ApiLoan();
        let mounted = true;
        if (mounted){
            apiService.getResources({
                url: '/coelsa/user_gateways'
            }).then(response => {
                const user_gateways_active = response.data.objects.filter((user_gateway) => {
                    return user_gateway.active;
                });
                const user_gateways = user_gateways_active.map(user_gateway => {
                    return {
                        label: user_gateway.gateway.description,
                        value: user_gateway.gateway.slug
                    }
                });

                setGateways(user_gateways);
            });
        }
        return () => mounted = false;

    }, []);

    useEffect(() => {
        let min_date = new Date();
        min_date.setHours(0);
        min_date.setMinutes(0);
        min_date.setSeconds(0);
        min_date.setMilliseconds(0);

        let max_date = new Date();
        max_date.setDate(max_date.getDate() + 10);

        setMinDate(min_date);
        setMaxDate(max_date);
    }, []);

    useEffect(() => {

       if (gateway === null){
           setDisabledButton(true);
       } else {
           setDisabledButton(false);
       }
    }, [gateway]);

    useEffect(() => {
       if (errors !== null){
           let error_type = typeof(errors);
           if (error_type === 'string'){
               showMessageToast(errors, 'error');
           } else {
               showMessageToast('Algunos campos son inválidos', 'error');
           }
       }
    }, [errors]);

    const getForm = () => {
        return (
            <div className="col-12 form-group">
                <div className="grid form-group">
                    <div className={"col-12 md-12"}>
                        <Dropdown value={gateway} options={gateways}
                                  onChange={(e) => setGateway(e.value)}
                                  placeholder="Seleccione una gateway" showClear={true}
                                  filter={true}/>
                    </div>
                    {renderForm()}

                    <div className={"col-12 md-12"}>
                        <Button label="Generar" disabled={disabledButton}
                                onClick={generatePayOrder} />
                    </div>

                </div>
            </div>
        )
    };

    const renderForm = () => {

        let form = null;
        if (gateway === null){
            return null;
        }
        let inputCbu = null;
        let invalid_fields = getInvalidFields();

        if (gateway === 'coelsa'){
            inputCbu = (
                <div className={"col-6 md-6"}>
                    <span className="p-float-label">
                        <InputText id="cbu" keyfilter="pnum" value={cbu} onChange={(e) => setCbu(e.target.value)} />
                        <label htmlFor="input">CBU</label>
                    </span>
                    {invalid_fields.cbu ? <small style={{color:'#f44336'}} className="p-error">{invalid_fields.cbu}</small> : null }
                </div>
            );
        }

        addLocale('es', {
            firstDayOfWeek: 1,
            dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
            dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
            dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
            monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
            monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
        });

        form = (
            <Fragment>
                <div className={"col-6 md-6"}>
                    <span className="p-float-label">
                        <InputText id="amount" keyfilter="pnum" value={amount} onChange={(e) => setAmount(e.target.value)} />
                        <label htmlFor="input">Monto</label>
                    </span>
                    {invalid_fields.amount ? <small style={{color:'#f44336'}} className="p-error">{invalid_fields.amount}</small> : null }
                </div>
                <div className={"col-6 md-6"}>
                    <span className="p-float-label">
                        <InputText id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
                        <label htmlFor="input">Descripción</label>
                    </span>
                    {invalid_fields.description ? <small style={{color:'#f44336'}} className="p-error">{invalid_fields.description}</small> : null }
                </div>
                <div className={"col-6 md-6"}>
                    <span className="p-float-label">
                        <InputText id="remote_pay_order" value={remotePayOrder} onChange={(e) => setRemotePayOrder(e.target.value)} />
                        <label htmlFor="input">Referencia</label>
                    </span>
                    {invalid_fields.remote_pay_order ? <small style={{color:'#f44336'}} className="p-error">{invalid_fields.remote_pay_order}</small> : null }
                </div>
                <div className={"col-6 md-6"}>
                    <span className="p-float-label">
                        <InputText id="lastname" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                        <label htmlFor="input">Apellido</label>
                    </span>
                    {invalid_fields.lastname ? <small style={{color:'#f44336'}} className="p-error">{invalid_fields.lastname}</small> : null }
                </div>
                <div className={"col-6 md-6"}>
                    <span className="p-float-label">
                        <InputText id="firstname" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                        <label htmlFor="input">Nombre</label>
                    </span>
                    {invalid_fields.firstname ? <small style={{color:'#f44336'}} className="p-error">{invalid_fields.firstname}</small> : null }
                </div>
                <div className={"col-6 md-6"}>
                    <span className="p-float-label">
                        <InputText id="document_number" keyfilter="pnum" value={documentNumber} onChange={(e) => setDocumentNumber(e.target.value)} />
                        <label htmlFor="input">Documento</label>
                    </span>
                    {invalid_fields.document_number ? <small style={{color:'#f44336'}} className="p-error">{invalid_fields.document_number}</small> : null }
                </div>
                <div className={"col-6 md-6"}>
                    <span className="p-float-label">
                        <InputText id="cuil" value={cuil} onChange={(e) => setCuil(e.target.value)} />
                        <label htmlFor="input">CUIL</label>
                    </span>
                    {invalid_fields.cuil ? <small style={{color:'#f44336'}} className="p-error">{invalid_fields.cuil}</small> : null }
                </div>

                <div className={"col-6 md-6"}>
                    <span className="p-float-label">
                        <Calendar
                            value={paymentDate}
                            minDate={minDate}
                            maxDate={maxDate}
                            onChange={(e) => setPaymentDate(e.value)}
                            locale="es"
                            placeholder='Fecha Orden'
                            dateFormat={"dd/mm/yy"}/>
                    </span>
                    {invalid_fields.payment_date ? <small style={{color:'#f44336'}} className="p-error">{invalid_fields.payment_date}</small> : null }
                </div>
                {inputCbu}
            </Fragment>
        );
        return form;

    }

    const getInvalidFields = () => {
        let fields = {
            'gateway': null,
            'amount': null,
            'description': null,
            'remote_pay_order': null,
            'document_number': null,
            'firstname': null,
            'lastname': null,
            'cuil': null,
            'payment_date': null,
            'cbu': null
        }
        if (errors){
            Object.keys(errors).map((error, index) => {
                fields[error] = errors[error][0]
                return fields;
            });
        }
        return fields;
    }

    const generatePayOrder = () => {
        setDisabledButton(true);

        let apiService = new ApiLoan();
        let formData = {
            'gateway': gateway,
            'amount': amount,
            'description': description,
            'remote_pay_order': remotePayOrder,
            'document_number': documentNumber,
            'firstname': firstname,
            'lastname': lastname,
            'cuil': cuil,
            'payment_date': paymentDate
        }
        if (gateway === 'coelsa'){
            formData['cbu'] = cbu;
        }
        apiService.postResource({
            url: '/pay_orders/debit/single',
            data: formData
        }).then(response => {
            if (response.status === 201){
                props.history.push({pathname: '/debit_pay_orders', state: {message: 'Se creó satisfactoriamente'}});
            }
        }).catch(error => {
            setErrors(error.response.data.message);
        });
    }

    const toast = useRef(null);

    const showMessageToast = (message, severity) => {
        toast.current.show({severity: severity, summary: 'Orden de Pago', detail: message});
    };

    return (
        <div className="grid p-fluid">
            <Toast ref={toast} />
            <div className="col-12 p-lg-12">
                <div className="card card-w-title">
                    <div className="grid p-fluid">
                        <div className="col-11 md-10 p-lg-9">
                            <h1>Nueva Orden de Pago</h1>
                            {getForm()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default DebitPayOrderSingle;