import React, {useEffect, useRef, useState} from "react";
import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import InputFilterDate from "./InputFilterDate";
import InputFrecuency from "./InputFrecuency";

import ApiLoan from "../../../service/ApiLoanService";

import {withRouter} from "react-router-dom";
import {Dropdown} from "primereact/dropdown";
import {Toast} from "primereact/toast";

const ReportGeneralExport = props => {
    const [loading, setLoading] = useState(true);
    const [task, setTask] = useState(false);
    const [rangeDate, setRangeDate] = useState(null);
    const [frecuency, setFrecuency] = useState(null);
    const [dayRange, setDayRange] = useState(null);
    const [reportType, setReportType] = useState(null);
    const [enabled, setEnabled] = useState(true);
    const [disabledButton, setDisabledButton] = useState(true);

    useEffect(() => {
        if (props.action === 'edit'){
            setTask(true);
            let apiService = new ApiLoan();
            apiService.getResource({
                url: props.url,
                resource_id: props.resource_id
            }).then(response => {
                setFrecuency(response.data.frecuency);
                setEnabled(response.data.enabled);
                setDayRange(response.data.day_range);
                setLoading(false);
                setReportType(response.data.report_type);
            }).catch(error => {
                props.history.push( '/' );
            });

        } else {
            setLoading(false);
        }
    },[props.action, props.resource_id, props.url]);

    useEffect(() => {
        if (task){
            setRangeDate(null);
        }
    },[task]);

    useEffect(() => {
       if (reportType !== null){
           setDisabledButton(false);
       }
       else if (enabled){
           setDisabledButton(true);
       }

    }, [reportType, disabledButton, enabled]);

    const reportGeneralHandler = (event) => {
        event.preventDefault();
        setDisabledButton(true);
        axiosAsync();
    };

    const axiosAsync = async() => {
        let apiService = new ApiLoan();
        let dataForm = {};
        if (task){
            dataForm['task'] = task;
            dataForm['frecuency'] = frecuency;
            dataForm['enabled'] = enabled;
            dataForm['day_range'] = dayRange;
        } else {
            if (rangeDate !== null){
                dataForm['min_date'] = rangeDate[0];
                dataForm['max_date'] = rangeDate[1];
            }
        }
        dataForm['report_type'] = reportType;

        if (props.action === 'edit'){
            await apiService.patchResource({
                url: '/export/report',
                resource_id: props.resource_id,
                data: dataForm
            });
        } else {
            await apiService.postResource({
                url: '/export/report',
                data: dataForm
            }).then(response => {
                let message_detail = null;
                if (!task){
                    message_detail = 'El reporte fue enviado por correo.';
                } else {
                    message_detail = 'Se creo la tarea satisfactoriamente.';
                }

                props.history.push({pathname: '/reports/export', state: {message: message_detail}});
            }).catch(error => {
                if (error.response.data?.message !== undefined) {
                    let label_key = Object.keys(error.response.data.message)[0];
                    let error_message = error.response.data.message[label_key][0];
                    if (error_message !== undefined){
                        refToast.current.show({severity: 'error', summary: 'Reporte Frecuencia', detail: error_message});
                    }
                }
            });
        }
    };

    const showInputOptionsCSV = () => {
        let options = [];

        let options_csv = {
            'all': 'Todo',
            'installments': 'Cuotas',
            'payments': 'Pagos',
            'interests': 'Intereses'
        };

        for (let key in options_csv) {
            options.push({
                'value':  key,
                'label': options_csv[key]
            });
        }

        return (
            <span className="p-float-label">
                <Dropdown value={reportType} options={options}
                          onChange={(e) => setReportType(e.value) } />
                 <label htmlFor="reportType">Seleccione tipo de reporte</label>
            </span>
        );

    };

    const showInputFilterDate = () => {
        let form = null;

        if (props.action !== 'edit'){
            form = <InputFilterDate task={task} rangeDate={rangeDate} setRangeDate={setRangeDate} />;
        }

        return form
    };

    let form = null;
    let labelButton = props.action === 'edit' ? 'Editar' : 'Crear';
    const refToast = useRef(null);

    if (!loading){
        form = (
            <div className="grid dashboard">
                <Toast ref={refToast}/>
                <div className="col-12 md-12 p-lg-12 p-fluid">
                    <form onSubmit={reportGeneralHandler}>

                        <Panel header="Reporte Export" style={{minHeight: '500px'}}>
                            <div className="col-6 md-6 p-lg-8 p-fluid">
                                {showInputOptionsCSV()}
                            </div>
                            {showInputFilterDate()}
                            <InputFrecuency task={task} setTask={setTask}
                                            frecuency={frecuency} setFrecuency={setFrecuency}
                                            dayRange={dayRange} setDayRange={setDayRange}
                                            enabled={enabled} setEnabled={setEnabled} action={props.action}
                                            disabledButton={disabledButton} setDisabledButton={setDisabledButton}/>
                            <div className="col-12 md-10">
                                <Button label={labelButton} style={{marginBottom:'10px'}}
                                        disabled={disabledButton} />
                            </div>
                        </Panel>
                    </form>

                </div>
            </div>
        );
    }

    return form;

};

export default withRouter(ReportGeneralExport);