import React, { useState, useEffect, useMemo } from 'react';
import { MRTURNO, BUSINESS } from '../../shared/ProviderScheduledType';
import MrTurnoForm from './ProviderAction/MrTurnoForm';
import BusinessForm from './ProviderAction/BusinessForm';

const ProviderScheduledForm = (props) => {
    const [providerScheduledAction, setProviderScheduledAction] = useState(null);

    useEffect(() => {
        if (props.match && props.match.params && props.match.params.action) {
            const action = props.match.params.action.toUpperCase();
            if (action === 'MR_TURNO') {
                setProviderScheduledAction(MRTURNO);
            } else if (action === 'BUSINESS') {
                setProviderScheduledAction(BUSINESS);
            }
        }
    }, [props.match]);

    const form = useMemo(() => {
        if (providerScheduledAction === MRTURNO) {
            console.log('PROPSa', props)
            return <MrTurnoForm {...props} />;
        } else if (providerScheduledAction === BUSINESS) {
            return <BusinessForm {...props} />;
        } else {
            return null;
        }
    }, [providerScheduledAction]);

    return form;
}

export default ProviderScheduledForm;