import {Component} from "react";
import ApiLoan from '../../../service/ApiLoanService';

import React from "react";
import {withRouter} from "react-router-dom";

import {showToast} from "../../../store/actions";
import {showErrorMessage} from "../../../shared/messagesToast";
import connect from "react-redux/es/connect/connect";

import {Dropdown} from "primereact/dropdown";

class DropdownFilter extends Component {

    _isMounted = false;

    state = {
        apiService: new ApiLoan(),
        resource: null,
        options: [],
        loading: true,
    };

    componentDidMount(){
        let filters = [];
        let has_filters = this.props.filter.filters !== undefined;
        if (has_filters){
            filters = this.props.filter.filters;
        }
        const page = 1;

        let dataOptions = [];

        dataOptions.push({
            'value': null,
            'label': this.props.filter.default
        });
        this.getDropdownResources(dataOptions, filters, page);

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getDropdownResources = (dataOptions, filters, page) => {
        this._isMounted = true;

        this.state.apiService.getResources({
            url: this.props.filter.url,
            filters: filters,
            page: page,
            results_per_page: 40
        }).then(response => {
            if (this._isMounted) {
                let response_data = response.data;
                if (response_data.objects !== undefined){
                    response_data = response.data.objects;
                }

                for (let key in response_data) {
                    dataOptions.push({
                        'value': response_data[key][this.props.filter.value],
                        'label': response_data[key][this.props.filter.label]
                    });
                }

                if (response.data.page === response.data.total_pages){
                    this.setState({
                        options: dataOptions,
                        loading: false
                    });
                } else {
                    this.getDropdownResources(dataOptions, filters, page + 1);
                }
            }
        }).catch(error => {
            showErrorMessage(this, "Error en Dropdown", 'Error en la carga de datos');
            this.props.history.push( '/' );
        });
    }

    onDropdownChange = (event) => {
        this.props.dt.filter(event.value, this.props.field, 'equals');
        this.setState({
            resource: event.value
        });
    };

    render(){
        let resourceFilter = null;
        if (!this.state.loading){
            let options = this.state.options;
            let value = this.state.resource !== null ? this.state.resource : this.props.value;

            resourceFilter = <Dropdown style={{'minWidth': '2.5em'}} value={value}
                                       className="column-filter"
                                           options={options} onChange={(event) => this.onDropdownChange(event)}/>;
        }

        return (
            resourceFilter
        );
    }
}

const mapDispatchToProps = {
    showToast
};

export default withRouter(connect(null, mapDispatchToProps)(DropdownFilter));