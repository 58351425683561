import React, { Fragment } from 'react'
import '../../assets/css/TemplateOverlay.css';
import TemplateManager from './TemplateManager';

const TemplateOverlay = (props) => {

    const overlay = (
        <TemplateManager
            showDialog={props.showTemplates}
            setShowDialog={props.setShowTemplates}
            onTemplateSelection={props.onTemplateSelection}
            onTemplateUpdate={props.onTemplateUpdate}
            onGetTemplates={props.onGetTemplates}
            onTemplateDelete={props.onTemplateDelete}
            onTemplateError={props.onTemplateError}
            onTemplateCreate={props.onTemplateCreate}
            canCreateCustomVariables={props.canCreateCustomVariables}
            businessIntegration={props.businessIntegration}
        />
    );

    return (
        <Fragment>
            {props.showTemplates && overlay}
        </Fragment>
    );
}

export default TemplateOverlay;