import './TransferredLabel.css'

import { Tag } from 'primereact/tag';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TransferredLabel = () => {
        return (
            <div>
                <Tag className='transferred-tag' value="Derivado recientemente" icon={
                    <FontAwesomeIcon className='transferred-tag-icon' icon="fa-regular fa-share" />
                }></Tag>
            </div>
        );
    }

export default TransferredLabel;