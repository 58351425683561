import React, { Component } from 'react';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Card} from "primereact/card";

import MoneyField from "../../../UI/MoneyField";
import DateField from '../../../UI/DateField';

import {switchRole} from '../../../shared/roles';

import ModalPayOrdersStatuses from '../../PayOrders/ModalPayOrdersStatuses';

import '../../../assets/css/ClientPayment.css';

var moment = require('moment');

class ClientPaymentTable extends Component {
    _isMounted = false;

    state = {
        loan: this.props.loan,
        loading: this.props.loading
    };

    setColor = (data, msg) => {
        let color = null;
        let message = null;
        let installment_date = moment(data['due_date']).format('YYYY-MM-DD');
        let date_now = moment(new Date()).format('YYYY-MM-DD');

        if (data['paid'] === true){
            color = 'green';
            if (msg !== undefined){
                message = msg['green'];
            }
        } else if (data['paid'] === false && installment_date < date_now ){
            color = 'red';
            if (msg !== undefined) {
                message = msg['red'];
            }
        } else {
            color = 'blue';
            if (msg !== undefined) {
                message = msg['blue'];
            }
        }

        return {
            'color': color,
            'message': message
        };

    };

    addMessage = (rowData, column) => {
        let msg = {
            'green': 'PAGADO',
            'red': 'IMPAGO',
            'blue': 'A VENCER'
        };

        let color_message = this.setColor(rowData, msg);

        return <span style={{color: color_message['color']}}>{color_message['message']}</span>;
    };

    addAmount = (rowData, column) => {
        let color = this.setColor(rowData);

        if (column !== undefined) {
            return <span style={{color: color['color']}}><MoneyField>{rowData[column.field]}</MoneyField></span>;
        }

        return <span style={{color: '#ca4434'}}>-<MoneyField not_dollar_sign={true}>{rowData.amount}</MoneyField></span>;
    };

    addInterests = (rowData, column) => {
        let color = this.setColor(rowData);

        let interests = 0;
        for (let index in rowData['interests']){
            let interest = rowData['interests'][index].amount;
            interests += parseFloat(interest);
        }

        if (column !== undefined) {
            return <span style={{color: color['color']}}><MoneyField>{interests}</MoneyField></span>;
        }

        color = interests !== 0 ? '#ca4434' : 'black';
        let operation = color === '#ca4434' ? '-' : '';

        return <span style={{color: color}}>{operation}<MoneyField not_dollar_sign={true}>{interests}</MoneyField></span>;
    };

    addPayments = (rowData, column) => {
        let {payments} = rowData;

        let totalPayments = 0;

        if(payments.length > 0){
            totalPayments = payments.map((payment) => payment.amount).reduce((accumulator, a) => {
                return accumulator + a
            })
        }

        if (column !== undefined) {
            return <span><MoneyField>{totalPayments}</MoneyField></span>;
        }

        return <span style={{color: '#57a3d8'}}><MoneyField not_dollar_sign={true}>{totalPayments}</MoneyField></span>;
    };

    addBalance = (rowData, column) => {
        let color = this.setColor(rowData);

        let {balance} = rowData;

        if (column !== undefined){
            return <span style={{color: color['color']}}><MoneyField>{balance}</MoneyField></span>;
        }

        color = balance !== 0 ? '#ca4434' : 'black';
        let operation = color === '#ca4434' ? '-' : '';
        return <span style={{color: color}}>{operation}<MoneyField not_dollar_sign={true}>{balance}</MoneyField></span>;
    };

    addColorInstallment = (paid) => {
        let color = paid === true ? 'green' : '#ca4434';

        return {color: color}
    };

    addDateFormat = (rowData, column) => {
        return <DateField>{rowData.due_date}</DateField>;
    };

    addModalPayOrdersStatusesButton = (rowData, column) => {
        let paymentStatus = this.addMessage(rowData, column);

        if(switchRole() === 'collector'){
            paymentStatus = (<ModalPayOrdersStatuses installmentId={rowData.id} linkBody={this.addMessage(rowData, column)}/>);
        }
        return paymentStatus
    };

    render(){
        let body = null;
        let tables = [];
        const {loan} = this.props
        if (!this.state.isLoading){
            const tableStyle = (window.innerWidth < 768) ? {width: '300px'} : {width: '661px'};

            let values = loan['installments'];
            if (window.innerWidth < 768) {
                tables.push(
                  <h2>Resumen de cuotas:</h2>
                );
                for (let index in values){
                    tables.push(
                        <Card key={index}>
                            <div style={{lineHeight: '7px'}}>
                                <p>
                                    <span style={{...this.addColorInstallment(values[index].paid), fontSize: '15px'}}>
                                        <b>Cuota {values[index].number} de {values.length}</b>
                                    </span>
                                    <span style={{float: 'right', color: 'red', fontSize: '17px'}}>
                                        <b>{this.addAmount(values[index])}</b>
                                    </span>
                                </p>
                                <p>
                                    <span style={{fontSize: '15px'}}>
                                        <b>Interés por atraso</b>
                                    </span>
                                    <span style={{float:'right'}}>
                                        <b>{this.addInterests(values[index])}</b>
                                    </span>
                                </p>
                                <p>
                                    <span style={{fontSize: '15px'}}>
                                        <b>Pago</b>
                                    </span>
                                    <span style={{float:'right'}}>
                                        <b>{this.addPayments(values[index])}</b>
                                    </span>
                                </p>
                                <p>
                                    <span style={{fontSize: '15px'}}>
                                        <b>Saldo</b>
                                    </span>
                                    <span style={{float:'right', fontSize: '17px'}}>
                                        <b>{this.addBalance(values[index])}</b>
                                    </span>
                                </p>
                            </div>
                        </Card>
                    );
                }

            } else {
                tables.push(
                    <DataTable value={values} loading={this.state.isLoading} scrollable={true} style={tableStyle} emptyMessage="No se encontró ningún resultado" key={999}>
                        <Column field="due_date" header="Fecha Vencimiento" style={{width: '95px'}} body={this.addDateFormat}/>
                        <Column field="number" header="Cuota" style={{width: '70px'}}/>
                        <Column field="amount" header="Monto" body={this.addAmount} style={{width: '100px'}}/>
                        <Column field="interests" header="Intereses" body={this.addInterests} style={{width: '100px'}}/>
                        <Column field="payments" header="Pagos" body={this.addPayments} style={{width: '100px'}}/>
                        <Column field="balance" header="Saldo" body={this.addBalance} style={{width: '100px'}}/>
                        <Column field="paid" header="Pagado" body={this.addModalPayOrdersStatusesButton} style={{width: '95px'}}/>
                    </DataTable>
                );
            }

            body = tables;
        }

        return body;
    }

}

export default ClientPaymentTable;