import React, {useState, useEffect, useRef} from 'react';
import ApiLoan from "../../service/ApiLoanService";
import {Toast} from "primereact/toast";
import { Calendar } from 'primereact/calendar';
import {Button} from "primereact/button";
import { addLocale } from 'primereact/api';

const CoelsaHolidaysForm = (props) => {
    const [calendarDay, setCalendarDay] = useState(null);
    const [invalidDates, setInvalidDates] = useState([]);

    const [errors, setErrors] = useState(null);

    useEffect(() => {
        const apiService = new ApiLoan();
        apiService.getResources({
            url: '/coelsa/holidays',
            results_per_page: 500
        }).then(response => {
            let invalid_dates = response.data.objects.map(x => {
                let new_date = new Date(x.calendar_day);
                new_date.setDate(new_date.getDate() + 1)
                return new_date;
            });
            setInvalidDates(invalid_dates);
        }).catch(error => {
            if (refToast !== null) {
                refToast.current.show({severity: 'error', summary: 'Bot',
                    detail: 'Error al cargar los feriados'});
                setTimeout(() => {
                    props.history.push('/coelsa/holidays')
                }, 2000);
            }

        });
    },[]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const apiService = new ApiLoan();
        let url = '/coelsa/holidays'
        const formData = {
            'calendar_day': calendarDay
        }

        apiService.postResource({
            'url': url,
            'data': formData
        }).then(response => {
            refToast.current.show({severity: 'success', summary: 'Feriado', detail: 'Se creo correctamente.'});
            setErrors(null);
            setTimeout(() => {
                props.history.push('/coelsa/holidays')
            }, 2000);
        }).catch(error => {
            setErrors(error.response.data.message);
            refToast.current.show({severity: 'error', summary: 'Feriado', detail: 'Verifique el formulario'});
        });

    }

    const refToast = useRef(null);

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
        dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
        dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
        monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        monthNamesShort: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
    });

    return (
        <div className="grid">
            <div className="col-12 md-12">
                <div className="card p-fluid">
                    <h4>Nuevo Feriado</h4>
                    <Toast ref={refToast}/>
                    <div className="formgrid grid">
                        <div className="field col">
                            <span className="p-float-label">
                                <Calendar value={calendarDay} onChange={(e) => setCalendarDay(e.value)} dateFormat="dd/mm/yy"
                                          disabledDates={invalidDates} disabledDays={[0, 6]} locale="es"/>
                                <label htmlFor="calendar">Fecha</label>
                            </span>
                            {errors?.calendar_day !== undefined ?
                                <small style={{color:'#f44336'}} className="p-error">{errors?.calendar_day[0]}</small> : null}
                        </div>
                        <div className="field col-12">
                            <Button label={'Crear'} icon={'pi pi-plus'} disabled={calendarDay === null} className="ml-auto"
                                    onClick={(e) => handleSubmit(e)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default CoelsaHolidaysForm;