import React, {useState} from "react";
import {Calendar} from "primereact/calendar";
import {Checkbox} from "primereact/checkbox";


const InputFilterDate = (props) => {

    const [filterDate, setFilterDate] = useState(false);

    const changeSwitch = (event) => {
        if (!event.value){
          props.setRangeDate(null);
        }
        setFilterDate(event.value);
    };

    const calendarChange = (event) => {
        props.setRangeDate(event.value);
    };

    const getSwitchField = () => {
        let form = null;
        if (!props.task) {
            form = (
                <div className="col-12 form-group">
                    <div className="grid">
                        <div className={"col-12 md-8"}>
                            <div className="grid">

                                <div className={"col-12 md-4"}>
                                    <span>Filtrar por Fecha</span>
                                </div>
                                <div className={"col-12 md-8"}>
                                    <Checkbox checked={filterDate}
                                              onChange={event => changeSwitch({value: event.checked})}/>
                                </div>
                            </div>
                        </div>
                        {getDateFields(filterDate)}
                    </div>
                </div>
            );
        }

        return form;
    };

    const getDateFields = (filterDate) => {
        let maxDate = new Date();

        if (filterDate){
            return (
                <div className="col-12 form-group">
                    <div className="grid">
                        <div className={"col-12 md-8"}>
                            <span className="p-float-label">
                                <Calendar value={props.rangeDate}
                                          dateFormat="dd/mm/yy"
                                          maxDate={maxDate}
                                          onChange={(e) => calendarChange(e)}
                                          selectionMode="range" readonlyInput={true} />
                                <label htmlFor="calendar">Rango de fecha</label>
                            </span>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }

    };

    return getSwitchField();
};

export default InputFilterDate;