import React, {useState, useEffect, Fragment} from "react";
import {Dropdown} from "primereact/dropdown";
import {Message} from "primereact/message";
import {Checkbox} from "primereact/checkbox";


const InputFrecuency = ({action, enabled, dayRange, frecuency, task, setDisabledButton, setFrecuency, setEnabled,
                            setTask, setDayRange}) => {
    const [loading, setLoading] = useState(true);
    const [typeFrecuency, setTypeFrecuency] = useState(null);
    const [frecuencyState, setFrecuencyState] = useState(null);
    const [dayRangeState, setDayRangeState] = useState('');
    const [enabledState, setEnabledState] = useState(true);

    useEffect(() => {
        if (action === 'edit'){
            setEnabledState(enabled);
            setDayRangeState(dayRange);

            let frecuency_edit = frecuency.split(' ');

            if (frecuency_edit[frecuency_edit.length - 1] !== '*'){ //day_week
                setTypeFrecuency('week');
                setFrecuencyState(frecuency_edit[frecuency_edit.length - 1]);
            } else if (frecuency_edit[2] !== '*') { //day_month
                setTypeFrecuency('month');
                setFrecuencyState(frecuency_edit[2]);
            }
            setLoading(false);

        } else {
            setLoading(false);
        }

    }, [action, enabled, dayRange, frecuency]);

    useEffect(() => {
        if (task){
            if (frecuencyState === null){
                setDisabledButton(true);
            }
            else {
                setDisabledButton(false);
            }
        }
    }, [task, frecuencyState]);

    useEffect(() => {

        if (!task && frecuencyState === null){
            setFrecuency(null);
        } else {

            let cron_frecuency = {
                'minute': 0,
                'hour': 2,
                'day_month': '*',
                'month': '*',
                'day_week': '*'
            };

            if (typeFrecuency === 'week'){
                cron_frecuency.day_week = frecuencyState;
            } else if (typeFrecuency === 'month') {
                cron_frecuency.day_month = frecuencyState;
            }
            cron_frecuency = Object.values(cron_frecuency).join(' ');
            setFrecuency(cron_frecuency);
            setEnabled(enabledState);
        }

    },[frecuencyState, typeFrecuency]);

    const changeTaskSwitch = (event) => {
        if (!event.value) {
            setFrecuency(null);
        }
        setTask(event.value);
    };

    const changeEnabledSwitch = (event) => {
        setEnabledState(event.value);
        setEnabled(event.value);
    };


    const getEnabledField = () => {
        let form = null;
        if (task){
            form = (
                <div className={"col-12 md-4"}>
                    <div className="grid">
                        <div className={"col-12 md-4"}>
                            <span>Tarea Habilitada</span>
                        </div>
                        <div className={"col-12 md-4"}>
                            <Checkbox checked={enabledState}
                                      onChange={event => changeEnabledSwitch({value: event.checked})}/>
                        </div>
                    </div>
                </div>
            );
        }
        return form;

    };

    const getTaskForm = () => {
        let form = null;

        if (action !== 'edit'){
            form = (
                <Fragment>
                    <div className={"col-12 md-4"}>
                        <span>Tarea programada</span>
                    </div>
                    <div className={"col-12 md-4"}>
                        <Checkbox checked={task}
                                  onChange={event => changeTaskSwitch({value: event.checked})}/>
                    </div>
                </Fragment>
            );
        }
        return form;
    };

    const getInputFrecuency = () => {
        return (
            <div className="col-12 form-group">
                <div className="grid">
                    <div className={"col-12 md-8"}>
                        <div className="grid">
                            {getTaskForm()}

                            {getEnabledField()}

                        </div>
                    </div>
                    {getFrecuencyForm()}
                </div>
            </div>
        );
    };

    const switchTypeFrecuency = () => {
        let form = null;

        switch (typeFrecuency) {
            case('week'):
                form = weekHandler();
                break;
            case('month'):
                form = monthHandler();
                break;
            default:
                break;
        }

        return form;
    };

    const weekHandler = () => {
        let options = [];
        let frecuencies = {
            '1': 'Lunes',
            '2': 'Martes',
            '3': 'Miércoles',
            '4': 'Jueves',
            '5': 'Viernes',
            '6': 'Sábado',
            '7': 'Domingo'
        };

        for (let key in frecuencies) {
            options.push({
                'value':  key,
                'label': frecuencies[key]
            });
        }

        return (
            <span className="p-float-label">
                <Dropdown value={frecuencyState} options={options}
                          onChange={(e) => setFrecuencyState(e.value) } />
                <label htmlFor="week">Seleccione día de la semana</label>
            </span>
        );
    };

    const monthHandler = () => {
        let options = [];

        for (let i = 1; i <= 31; i++){
            let value = i.toString();
            options.push({
                'value': value,
                'label': value
            });
        }

        return (
            <span className="p-float-label">
                <Dropdown value={frecuencyState} options={options}
                      onChange={(e) => setFrecuencyState(e.value) } />
                <label htmlFor="month">Seleccione día</label>
            </span>
        );
    };

    const getFrecuencyType = () => {

        let options = [];
        let frecuencies = {
            'week': 'Semana',
            'month': 'Mes'
        };

        for (let key in frecuencies) {
            options.push({
                'value':  key,
                'label': frecuencies[key]
            });
        }

        return (
            <span className="p-float-label">
                <Dropdown value={typeFrecuency} options={options}
                          onChange={(e) => changeTypeFrecuency(e.value) } />
                <label htmlFor="frecuency">Tipo de frecuencia</label>
            </span>

        );
    };

    const changeTypeFrecuency = (value) => {
        setFrecuencyState(null);
        setTypeFrecuency(value)
    };

    const getDayRange = () => {
      let optionDays = [
          { label: "Exportar Todo", value: null },
          { label: "Últimos 7 días", value: 7 },
          { label: "Últimos 30 días", value: 30 },
      ];

      return (
          <span className="field">
            <Dropdown value={dayRangeState} options={optionDays}
                    onChange={(e) => changeDayRange(e.value) }
            />
            <small>Seleccione rango de días a filtrar.</small>
          </span>
      )

    };

    const changeDayRange = (value) => {
        setDayRangeState(value);
        setDayRange(value);
    };


    const generateTextInfo = () => {
        let text = null;
        let message = null;
        if (typeFrecuency === 'week'){
            if (frecuencyState !== null){
                text = 'El reporte se realizará cada '+ calculateDayOfWeek()+ ' de cada semana.';
            }
        } else if (typeFrecuency === 'month'){
            if (frecuencyState !== null){
                text = 'El reporte se realizará el día ' + frecuencyState + ' de cada mes.';
            }
        }

        if (text !== null){
            message = <Message severity="info" text={text} />;
        }
        return message;

    };

    const calculateDayOfWeek = () => {
        let options = [];
        let days = {
            '1': 'Lunes',
            '2': 'Martes',
            '3': 'Miercoles',
            '4': 'Jueves',
            '5': 'Viernes',
            '6': 'Sabado',
            '7': 'Domingo'
        };

        for (let key in days) {
            options.push({
                'value':  key,
                'label': days[key]
            });
        }

        return options[frecuencyState - 1].label;
    };



    const getFrecuencyForm = () => {
        if (task){
            return (
                <div className="col-12 form-group">
                    <div className="grid">
                        <div className="col-12 form-group">
                            <div className="grid">
                                <div className={"col-12 md-5"}>
                                    {getFrecuencyType()}
                                </div>
                                <div className={"col-12 md-5"}>
                                    {switchTypeFrecuency()}
                                </div>
                                <div className={"col-12 md-5"}>
                                    {getDayRange()}
                                </div>
                            </div>
                            <div className="grid">
                                <div className="col-12 md-12">
                                    {generateTextInfo()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }

    };

    if (!loading){
        return getInputFrecuency();
    } else {
        return null;
    }

};

export default InputFrecuency;