import {Component} from "react";
import ApiLoan from '../../../service/ApiLoanService';
import InputForm from "../../../UI/InputForm";
import { Button } from 'primereact/button';
import React from "react";
import {withRouter} from "react-router-dom";
import * as roles from "../../../shared/roles";
import update from "immutability-helper";

import {showToast} from "../../../store/actions";
import {showSuccessMessage, showErrorMessage} from "../../../shared/messagesToast";
import connect from "react-redux/es/connect/connect";
import {messageErrorObject} from "../../../shared/utility";


class DefaultStatus extends Component {

    _isMounted = false;

    state = {
        apiService: new ApiLoan(),
        uri: '/default_statuses',
        title: 'Estados de Deuda',
        defaultStatusForm: {
            slug: {
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'Slug'
            },
            description: {
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'Descripción'
            },
            from_day: {
                elementType: 'text',
                elementConfig: {
                    keyfilter: 'pint'
                },
                value: '',
                traduction: 'Desde'
            },
            to_day: {
                elementType: 'text',
                elementConfig: {
                    keyfilter: 'pint'
                },
                value: '',
                traduction: 'Hasta'
            },
            severity: {
                elementType: 'text',
                elementConfig: {
                    keyfilter: 'pint'
                },
                value: '',
                traduction: 'Gravedad'
            }
        },
        loading: true,
        defaultStatusFormEdit: null
    };

    componentDidMount(){
        this._isMounted = true;
        if (this.props.action !== 'edit'){
            let role = roles.switchRole();
            if (role === 'admin'){
                let defaultStatusForm = {
                    ...this.state.defaultStatusForm
                };

                let provider_url = '/clients';
                let providerOptions = [];

                this.state.apiService.getResources({
                    url: provider_url,
                    results_per_page: 500
                }).then(response => {
                    if (this._isMounted) {
                        const response_data = response.data.objects;

                        for (let key in response_data) {
                            providerOptions.push({
                                'value': response_data[key].id,
                                'label': response_data[key].name
                            });
                        }

                        let provider_id = {
                            elementType: 'dropdown',
                            elementConfig: {
                                uri: provider_url,
                                options: providerOptions,
                                placeholder: 'Seleccione un Cliente'
                            },
                            value: '',
                            traduction: 'Cliente'
                        };

                        let providerConfig = update(defaultStatusForm, {$merge: {provider_id}});

                        defaultStatusForm = providerConfig;

                        this.setState({defaultStatusForm: defaultStatusForm});
                    }
                }).catch(error => {
                    showErrorMessage(this, "Estados de Deuda", 'Error en la carga de datos de clientes');
                    this.props.history.push( '/default-statuses' );
                });

            }

        } else {
            this.state.apiService.getResource({
                url: this.props.url,
                resource_id: this.props.resource_id
            }).then(response => {
                if (this._isMounted) {
                    let fetched_resource = {};

                    this.props.fields.map(defaultStatusField => {
                        for(let field in response.data){
                            if (defaultStatusField.hasOwnProperty(field)){
                                if (response.data[field] === null){
                                    response.data[field] = '';
                                }
                                defaultStatusField[field].value = response.data[field];
                                fetched_resource[field] = defaultStatusField[field];
                            }
                        }
                        return fetched_resource;
                    });
                    this.setState({
                        defaultStatusFormEdit: fetched_resource,
                        title: this.props.title,
                        loading: false
                    });
                }
            }).catch(error => {
                showErrorMessage(this, "Estados de Deuda", 'Error en la carga del estado de deuda');
                this.props.history.push( '/default-statuses' );
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    defaultStatusHandler = (event) => {
        event.preventDefault();
        this.setState( { loading: true } );

        const formData = {};

        for (let formElementIdentifier in this.state.defaultStatusForm) {
            formData[formElementIdentifier] = this.state.defaultStatusForm[formElementIdentifier].value;
        }

        this.state.apiService.postResource({
            url: this.state.uri,
            data: formData
        }).then(response => {
            this.setState( { loading: false } );
            showSuccessMessage(this, "Estado de Deuda", "Se creó satisfactoriamente");
            this.props.history.push( '/default-statuses' );
        }).catch(error => {
            let messageError = error.response.data.message;

            let defaultStatusForm = {
                ...this.state.defaultStatusForm
            };

            let errorMessages = messageErrorObject(defaultStatusForm, messageError);

            if (errorMessages !== null){
                this.setState({
                    defaultStatusForm: errorMessages,
                    loading: false
                });
            } else {
                this.setState( { loading: false } );
                showErrorMessage(this, "Estado de Deuda", messageError);
            }

        });
    };

    defaultStatusEditHandler = (event) => {
        event.preventDefault();
        this.setState( { loading: true } );

        const formDataEdit = {};

        for (let formElementIdentifier in this.state.defaultStatusFormEdit) {

            formDataEdit[formElementIdentifier] = this.state.defaultStatusFormEdit[formElementIdentifier].value;
        }

        this.state.apiService.patchResource({
            url: this.state.uri,
            resource_id: this.props.resource_id,
            data: formDataEdit
        }).then(response => {
            this.setState( { loading: false } );
            showSuccessMessage(this, "Estado de Deuda", "Se editó satisfactoriamente");
            this.props.history.push( '/default-statuses' );
        }).catch(error => {
            let messageError = error.response.data.message;

            let defaultStatusFormEdit = {
                ...this.state.defaultStatusFormEdit
            };

            let errorMessages = messageErrorObject(defaultStatusFormEdit, messageError);

            if (errorMessages !== null){
                this.setState({
                    defaultStatusFormEdit: errorMessages,
                    loading: false
                });
            } else {
                this.setState( { loading: false } );
                showErrorMessage(this, "Estado de Deuda", messageError);
            }
        });
    };

    inputChangedHandler = (event, inputIdentifier) => {
        let inputFormChanged = this.props.action !== 'edit' ? this.state.defaultStatusForm : this.state.defaultStatusFormEdit;

        const updatedDefaultStatusForm = {
            ...inputFormChanged
        };

        const updatedFormElement = {
            ...updatedDefaultStatusForm[inputIdentifier]
        };

        if (updatedFormElement.elementType === 'dropdown'){
            updatedFormElement.value = event.value;
        } else {
            updatedFormElement.value = event.target.value;
        }

        if (updatedFormElement.elementConfig.error !== undefined){
            updatedFormElement.elementConfig.error = null;
        }

        updatedDefaultStatusForm[inputIdentifier] = updatedFormElement;

        if (this.props.action !== 'edit'){
            this.setState({defaultStatusForm: updatedDefaultStatusForm});
        } else {
            this.setState({defaultStatusFormEdit: updatedDefaultStatusForm});
        }
    };

    render(){
        const formElementsArray = [];
        let defaultStatusForm = this.props.action !== 'edit' ? this.state.defaultStatusForm : this.state.defaultStatusFormEdit;
        let submitForm = this.props.action !== 'edit' ? this.defaultStatusHandler : this.defaultStatusEditHandler;
        let submitFormButton = this.props.action !== 'edit' ? 'Guardar' : 'Actualizar';

        for (let key in defaultStatusForm) {
            formElementsArray.push({
                id: key,
                config: defaultStatusForm[key]
            });
        }

        let form = (
            <form onSubmit={submitForm}>
                {formElementsArray.map(formElement => (
                    <InputForm
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        typeElement={formElement.id}
                        traduction={formElement.config.traduction}
                        title={formElement.config.title}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)}
                    />
                ))}

                <div className="col-12 md-12">
                    <Button label={submitFormButton} style={{marginBottom:'10px'}} />
                </div>

            </form>
        );

        return (
            <div className="grid p-fluid">
                <div className="col-12 md-12">
                    <div className="card card-w-title">
                        <h1><strong>{this.state.title}</strong></h1>
                        <div className="col-12 form-group">
                            {form}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    showToast
};

export default withRouter(connect(null, mapDispatchToProps)(DefaultStatus));