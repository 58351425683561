import React, {Component, Fragment} from 'react'
import {withRouter} from "react-router-dom";
import ApiLoan from '../../service/ApiLoanService';
import CustomSpinner from '../../UI/CustomSpinner';
import FormGeneric from './FormGeneric';
import {showToast} from "../../store/actions";
import {showSuccessMessage, showErrorMessage} from "../../shared/messagesToast";
import {messageErrorObject} from "../../shared/utility";
import connect from "react-redux/es/connect/connect";

class ResourceFormGeneric extends Component {

    _isMounted = false;

    state = {
        apiService: new ApiLoan(),
        loading: this.props.resource_id && true,
        action: this.props.resource_id ? 'edit' : 'create',
        resource_id: this.props.resource_id,
        url: this.props.url
    };

    componentDidMount(){
        this._isMounted = true;
        let {fields} = this.props;

        if(fields.length > 0){
            this.getExternalResources(fields)
            .then(fields => {
                if(this.state.resource_id){
                    return this.getResource(fields, this.state.resource_id)
                }
                return fields
            }).then(fields => {
                this.props.setFields(fields);
                this.setState({loading: false});
            })
            .catch(error => {
                this.props.history.push( '/' );
            })
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps) {
        let { fields } = this.props;

        if(fields.length > 0 && prevProps.fields.length !== fields.length){
            this.getExternalResources(fields)
            .then(fields => {
                if(this.state.resource_id){
                    return this.getResource(fields, this.state.resource_id)
                }
                return fields
            }).then(fields => {
                this.props.setFields(fields);
                this.setState({loading: false});
            })
            .catch(error => {
                this.props.history.push( '/' );
            })
        }
    }

    setFieldValues = (fields) => {
        let values = {};

        fields.forEach((field) => {
            if (field.value !== ''){
                let fieldValue = field.value;
                if (Array.isArray(field.value)){
                    fieldValue = field.value.map(child => {
                        return this.setFieldValues(child);
                    });
                }
                values[field.name] = fieldValue;
            } else {
                values[field.name] = null;
            }
        });
        return values;
    };

    onSubmitHandler = (event) => {
        this.setState( { loading: true } );

        let values = {};
        const {fields} = this.props;
        let method = this.state.apiService.postResource;
        let request = {
            url: this.state.url,
        };

        if(this.state.resource_id){
            request.resource_id = this.state.resource_id;
            method = this.state.apiService.patchResource;
        }

        values = this.setFieldValues(fields);

        request.data = values;

        method(request).then(response => {
            this.setState( { loading: false } );
            let message_detail = 'Se creó satisfactoriamente'
            if (this.state.resource_id){
                message_detail = 'Se editó satisfactoriamente'
            }
            showSuccessMessage(this, "Formulario", message_detail);
            if (this.props.hasHistory === undefined || this.props.hasHistory === true) {
                this.props.history.push(this.props.url);
             }
        }).catch(error => {
            let messageError = error?.response?.data?.message;
            let {fields} = this.props;
            let objectFields = [...fields];

            objectFields = objectFields.reduce((init,field) => {
                init[field.name] = field;
                return init;
              },{});

            let errorMessages = messageErrorObject(objectFields, messageError);
            if (errorMessages !== null){
                const updatedFields = Object.entries(errorMessages).map(([k,v]) => {
                    return v;
                });
                this.setState({
                    loading: false
                });
                this.props.setFields(updatedFields);
            } else {
                this.setState({
                    loading: false
                });
                showErrorMessage(this, "Formulario", messageError);
            }
        });
    };

    getExternalResources(fields){
        let externalFields = fields.filter(field => field.requestResource);

        return Promise.all(
            externalFields.map(async externalField => {

                let response_resource = await this.state.apiService.getResources(externalField.requestResource.request)

                if (response_resource.data.page !== response_resource.data.total_pages){
                    let page = response_resource.data.page + 1;
                    let total_pages = response_resource.data.total_pages;
                    while (page <= total_pages){
                        externalField.requestResource.request['page'] = page
                        let response_resources = await this.state.apiService.getResources(externalField.requestResource.request)
                        response_resource.data.objects = response_resource.data.objects.concat(response_resources.data.objects);
                        page++;
                    }
                }
                return response_resource;

        })).then(result => {
            let updatedFields = externalFields.map((externalField, index) => {
                let items = [];
                if(result[index].data.objects){
                    items = result[index].data.objects
                }else{
                    items = result[index].data
                }
                externalField.elementConfig.options = items.map( item => {
                    return {
                                value: item[externalField.requestResource.format.value],
                                label: item[externalField.requestResource.format.label]
                    }
                });
                return externalField
            });

            let newFields = fields.map(field => {
                updatedFields.forEach(updatedField => {
                    if(field.name === updatedField.name){
                        field = updatedField;
                    }
                });
                return field
            });

            return newFields;

        }).catch(error => {
            this.props.history.push( '/' );
        });
    }

    getResource(fields, resource_id){
        this.setState({loading: true})
        return this.state.apiService.getResource({
            url: this.state.url + '/',
            resource_id: this.state.resource_id
        }).then(response => {
            if (this._isMounted) {
                let {fields} = this.props;

                const {data} = response;

                fields.map(field => {
                    if(field.name in data){

                        if (field.elementType !== 'FormList'){
                            field.value = data[field.name];
                        } else {
                            field.elementConfig.elements = data[field.name].length;
                            let childs = [];

                            for(let i = 0; i < data[field.name].length; i++){
                                let childFields = [...field.elementConfig.childFields];

                                let form = childFields.map((childField) => {
                                    let newField = {...childField};
                                    newField.value = data[field.name][i][childField.name];
                                    newField.key = i;
                                    return newField;
                                });
                                childs.push(form);
                            }
                            field.value = childs;
                        }
                    }
                    return field;
                });
            }
            return fields;
        })
    }

    render(){
        let fields = [...this.props.fields];

        return (<Fragment>
                        <FormGeneric
                            fields={fields}
                            panelLabel={this.props.panelLabel}
                            setFields = {this.props.setFields}
                            clicked={this.props.clicked}
                            buttonLabel={((this.state.action === 'create') && 'Crear') || 'Guardar'}
                            onSubmit={this.onSubmitHandler}
                            dataKey={this.props.dataKey}
                        />
                        {this.state.loading && <CustomSpinner/>}
                </Fragment>);
    }
}

const mapDispatchToProps = {
    showToast
};

export default withRouter(connect(null, mapDispatchToProps)(ResourceFormGeneric));