import React, {Component, Fragment} from 'react';

import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';

import '../../../assets/css/NotificationHelper.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CopyToClipboard from 'react-copy-to-clipboard';

class NotificationHelper extends Component {

    state = {
        visible: false
    };

    render(){
        let interoperationList = null;
        let prontocobro_fields = null;

        if (this.props.interoperation){
            interoperationList = (
                <Fragment>
                    <b>{"{DebtorTotalDebt}"}:</b> Total de deuda<br/>
                    <b>{"{DebtorTotalPaid}"}:</b> Total pagado<br/>
                    <b>{"{DebtorExpiredDebt}"}:</b> Total deuda vencida<br/>
                </Fragment>
            );
        } else {
            if (process.env.REACT_APP_PAGE === 'prontocobro'){
                interoperationList = (
                    <Fragment>
                        <b>{"{OperationDetail}"}:</b> Detalle de la operación<br/>
                        <b>{"{LoanAmount}"}:</b> Monto de la operación<br/>
                        <b>{"{LoanDebt}"}:</b> Deuda Vencida de la operación<br/>
                        <b>{"{LoanInterest}"}:</b> Interés de la operación<br/>
                        <b>{"{InstallmentDueDate}"}:</b> Fecha de la cuota<br/>
                        <b>{"{InstallmentNumber}"}:</b> Nº de cuota<br/>
                        <b>{"{InstallmentAmount}"}:</b> Monto de la cuota<br/>
                    </Fragment>
                );

                prontocobro_fields = (
                    <Fragment>
                        <b>{"{ClientUrl}"}:</b> Url personalizada de la empresa<br/>
                        <b>{"{ClientShortUrl}"}:</b> Url a la página con el detalle de la deuda<br/>
                        <b>{"{ClientShortUrlAlt}"}:</b> Reemplazar Alt por el código de dominio alternativo (ej. AR)<br/>
                        <b>{"{ClientTotalAmount}"}:</b> Total deuda inicial<br/>
                        <b>{"{LoanCbu}"}:</b> Cbu del deudor<br/>
                        <b>{"{LoanBank}"}:</b> Banco del deudor<br/>
                        <b>{"{ProviderName}"}:</b> Nombre de la empresa<br/>
                        <b>{"{ProviderAddress}"}:</b>  Dirección de la empresa<br/>
                        <b>{"{ProviderEmail}"}:</b> Email de la empresa<br/>
                        <b>{"{ProviderPhone}"}:</b> Teléfono de la empresa<br/>
                        <b>{"{ProviderCbu}"}:</b> Cbu de la empresa<br/>
                        <b>{"{ProviderCuit}"}:</b> Cuit de la empresa<br/>
                    </Fragment>
                );
            }
        }

        const copy = (value) => {
            return(
                <CopyToClipboard style={{top: '10px'}} text={value}>
                        <FontAwesomeIcon icon={'fa-regular fa-copy'} color={'#FD971F'}
                                         size="xs" className={"btn-actions btn-fontawesome"} />
                </CopyToClipboard>
            )
        }
        const varList = (<Fragment>
                                <div className='grid'>
                                    <div className='col-12'>
                                    <h1 style={{ fontWeight: 'normal' }}>Variables Disponibles</h1>
                                    </div>
                                </div>
                                <div className='grid'>
                                    <div className='col-12 notification-helper'>
                                        <b>{"{ClientName}"}:</b> Nombre {copy('{ClientName}')}<br/>
                                        <b>{"{ClientLastname}"}:</b> Apellido {copy('{ClientLastname}')}<br/>
                                        <b>{"{SanitizedClientName}"}:</b> Nombre sanitizado {copy('{SanitizedClientName}')}<br/>
                                        <b>{"{SanitizedClientLastname}"}:</b> Apellido sanitizado {copy('{SanitizedClientLastname}')}<br/>
                                        <b>{"{ClientDocumentNumber}"}:</b> Nº Doc {copy('{ClientDocumentNumber}')}<br/>
                                        <b>{"{ClientCuil}"}:</b> Cuil {copy('{ClientCuil}')}<br/>
                                        <b>{"{Variable1}"}:</b> Información extra 1 {copy('{Variable1}')}<br/>
                                        <b>{"{Variable2}"}:</b> Información extra 2 {copy('{Variable2}')}<br/>
                                        <b>{"{Variable3}"}:</b> Información extra 3 {copy('{Variable3}')}<br/>
                                        {prontocobro_fields}
                                        {interoperationList}
                                    </div>
                                </div>
                            </Fragment>);

        const viewVarList = (<Fragment>
                                    <Sidebar className='p-sidebar-md' visible={this.state.visible} position="right" onHide={(e) => this.setState({visible:false})}>
                                        {varList}
                                    </Sidebar>
                                    <Button className="p-button-rounded btn-actions-default" icon="pi pi-th-large" onClick={(e) => this.setState({visible:true})}/>
                                </Fragment>);

        return (<Fragment>
                    {viewVarList}
                </Fragment>);

    }

}

export default NotificationHelper