import React, { useContext } from 'react';

import './ConversationContact.css';
import IntlTelInput from "react-intl-tel-input";
import 'react-intl-tel-input/dist/main.css';
import { CompanyContext } from '../../../context/companyContext';
import {lookup} from "country-data";

export default function ConversationContact(props) {

    const {phone_number, country_prefix} = props.value;
    const {prefixTimezone} = useContext(CompanyContext);

    const inputChangePhoneHandler = (phone_valid, phone_national, country_obj, phone_international) => {
        let phone = {
            country_prefix: null,
            phone_number: null,
            phone_valid: false
        };

        phone['phone_number'] = phone_national.replace(/\D/g,'');
        phone['country_prefix'] = country_obj.dialCode;
        phone['phone_valid'] = phone_valid;

        props.onChange(phone)
    };

    const inputChangePhoneSelectFlagHandler = (phone_national, country_obj, phone_international, phone_valid) => {
        inputChangePhoneHandler(phone_valid, phone_national, country_obj, phone_international);
    };

    const getCountryIso = (country_prefix) => {
        let prefix = '+'+country_prefix;
        let country_iso = lookup.countries({countryCallingCodes: prefix})[0];

        if (country_iso === undefined){
            country_iso = prefixTimezone;
        } else {
            country_iso = country_iso['alpha2'].toLowerCase();
        }

        return country_iso;
    };

    const country_iso = getCountryIso(country_prefix);

    return (
        <div className="conversation-init">
            <IntlTelInput
                preferredCountries={[prefixTimezone]}
                separateDialCode={true}
                formatOnInit={false}
                containerClassName={'intl-tel-input intl-tel-container-custom'}
                value={phone_number}
                inputClassName="p-float-label intl-tel-input-custom"
                defaultCountry={country_iso}
                onPhoneNumberChange={
                    (phone_valid, phone_national, country_obj, phone_international) =>
                        inputChangePhoneHandler(phone_valid, phone_national,
                            country_obj, phone_international)}
                onSelectFlag={
                    (phone_national, country_obj, phone_international, phone_valid) =>
                        inputChangePhoneSelectFlagHandler(phone_national, country_obj,
                            phone_international, phone_valid)}
                nationalMode={false}
            />
        </div>
    );
}