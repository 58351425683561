import React, {useEffect, useContext, useState, Fragment, useRef} from 'react';
import ApiLoan from "../../service/ApiLoanService";
import {Link, useHistory} from "react-router-dom";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {CompanyContext} from "../../context/companyContext";
import './ChatBotsPush.css';
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {Toolbar} from "primereact/toolbar";
import {Toast} from "primereact/toast";
import ChatBotPushForm from './ChatBotPushForm';
import { Chip } from 'primereact/chip';
import { getFormattedDateLuxon } from '../../utils/utils';

const ChatBotsPush = () => {

    const history = useHistory();
    const {chatbotEnabled, providerTimezone} = useContext(CompanyContext)
    const [resources, setResources] = useState([])
    const [methodPush, setMethodPush] = useState({
        show: false,
        value: {},
    });
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [first, setFirst] = useState(0);

    let resultsPerPage = 5;

    const refToast = useRef(null);

    useEffect(() => {
        if (!chatbotEnabled){
            history.push('/');
        }
    }, [chatbotEnabled]);

    useEffect(() => {
        handleChatBotPushs(page);
    }, [methodPush, page])

    const handleRefechChatBotPushs = () => {
        setFirst(0);
        setPage(1);
        handleChatBotPushs(1);
    }

    const handleChatBotPushs = (page) => {
        const apiService = new ApiLoan();
        setLoading(true);
        let params = {
            page: page,
            results_per_page: resultsPerPage
        }
        apiService.getResources({
            url: '/chat_bot_pushs',
            ...params
        }).then(response => {
            setResources(response.data)
        }).catch(() => { 
            return
        }).finally(() => setLoading(false));
    }

    const booleanField = (rowData, column) => {
        let label = 'SI';
        let status = 'actived';
        if (!rowData[column.field]){
            label = 'NO';
            status = 'disabled'
        }
        return <span className={`boolean-field-badge status-${status}`}>{label}</span>;
    }

    const templateField = (rowData, column) => {
        if (rowData[column.field]) return rowData[column.field]
        else return '-' 
    }

    const resultField = (rowData, column) => {
        if (rowData[column.field]) return <pre>{rowData[column.field]}</pre>;
        else return '-' 
    }

    const chipsField = (rowData, column) => {
        if (rowData[column.field].length !== 0){
            return (
            <div>
                {rowData[column.field].map((element) => { 
                    return <Chip label={element.username ? element.username : element.name}/> 
                })}
            </div>)
        }
        else return '-';
    }

    const dateField = (rowData, column) => {
        if (rowData[column.field]) return getFormattedDateLuxon(rowData[column.field], providerTimezone)
        else return '-' 
    }

    const methodPushModal = () => {
        setMethodPush((prevState) => {
            return {
                ...prevState,
                show: true
            }
        });
    }

    const rightToolbarTemplate = () => {
        return (
            <Fragment>
                <div className={'ml-2'}>
                    <Button
                        className="ml-auto"
                        label="Nuevo"
                        icon='pi pi-plus'
                        data-tip="Nuevo"
                        onClick={() => { methodPushModal()}}
                    />
                </div>
            </Fragment>
        )
    }

    const leftToolbarTemplate = () => {
        return (
            <div className={'ml-2'}>
                <Button
                    className="ml-auto"
                    label="Refrescar"
                    icon='pi pi-refresh'
                    data-tip="Actualizar"
                    onClick={() => { handleRefechChatBotPushs()}}
                />
            </div>
        )
    }

    const hideModalChatBotPush = () => {
        setMethodPush((prevState) => {
            return {
                ...prevState,
                show: false,
                value: null
            }
        })
    }

    const getModalChatbotPush = () => {
        return (
            <Fragment>
                <Dialog header="Configuración Impulsar Bot" visible={methodPush.show} style={{ minHeight: '50%', width: '50%', height: 'auto' }}
                        onHide={() => hideModalChatBotPush()}>
                        <ChatBotPushForm
                            formData={methodPush.value}
                            hideModalChatBotPush={hideModalChatBotPush}
                            refToast={refToast}
                        />
                </Dialog>
            </Fragment>
        )
    }

    const onPage = (event) => {
        setFirst(event.first)
        setPage(event.page + 1)
    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className='card'>
                <h1><strong>Impulsar Bot</strong></h1>
                <Toast ref={refToast}/>
                <div className="datatable-filter-wa-mobile">
                <div className="card">
                    <Toolbar className="mb-2 mr-2" left={() => leftToolbarTemplate()} right={() => rightToolbarTemplate()}></Toolbar>
                    <DataTable loading={loading} emptyMessage="No se encontró ningún resultado" className="p-datatable-customers" value={resources.objects} sortMode="single"
                                paginator rows={resultsPerPage} first={first} totalRecords={resources.num_results} lazy={true} onPage={onPage}>
                        <Column field='created' header="Creado" body={dateField}></Column>
                        <Column field="start" header="Desde" body={dateField}></Column>
                        <Column field="end" header="Hasta" body={dateField}></Column>
                        <Column field='template' header="Plantilla" body={templateField}></Column>
                        <Column className='col-2' field='chat_bot_push_custom_tags' header="Etiquetas" body={chipsField}></Column>
                        <Column className='col-2' field='chat_bot_push_users' header="Usuarios" body={chipsField}></Column>
                        <Column className='col-2' field='chat_bot_push_groups' header="Grupos" body={chipsField}></Column>
                        <Column field="finished" header="Terminado" body={booleanField}></Column>
                        <Column className='col-4' field="result" header="Resultado" body={resultField}></Column>
                    </DataTable>
                        {getModalChatbotPush()}
                </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default ChatBotsPush;
